import { COLLECTION_NAME } from '@batteki/common'
import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import { collection, limit, onSnapshot, query, where } from 'firebase/firestore'
import type { ChatRoomReference } from '@batteki/base/src/domain/chat-room-reference/chatRoomReference.class'
import { chatRoomReferenceConverter } from '@batteki/base/src/domain/chat-room-reference/chatRoomReference.class'
import { db } from '@batteki/base/src/plugins/firebase'

export interface ChatRoomReferenceState {
  chatRoomReferences: ChatRoomReference[]
  unreadMessages: { count: number } | null
  unsubscribe: (() => void) | null
}

/**
 * State
 */
export const state = (): ChatRoomReferenceState => ({
  chatRoomReferences: [],
  unsubscribe: null,
  unreadMessages: null,
})

/**
 * Getters
 */
export const getters = getterTree(state, {
  chatRoomReferences: (state) => state.chatRoomReferences,
  hasUnreadMessage: (state) => !!state.unreadMessages?.count ?? false,
})

/**
 * Mutation
 */
export const mutations = mutationTree(state, {
  setChatRoomrefencesMutation(state, chatRoomReferences: ChatRoomReference[]) {
    state.chatRoomReferences = chatRoomReferences
  },
  setUnsubscribeMutation(state, unsubscribe: (() => void) | null) {
    state.unsubscribe = unsubscribe
  },
  setUnreadMessageMutation(state, unreadMessages: { count: number } | null) {
    state.unreadMessages = unreadMessages
  },
})

/**
 * Action
 */
export const actions = actionTree(
  { state, getters, mutations },
  {
    /**
     * Realtime Event Listener
     * @param uid
     */
    subscribeOneUnreadChatRoomReference({ commit, state }, uid: string) {
      if (state.unsubscribe) return
      const unsubscribe = onSnapshot(
        query(
          collection(
            db,
            `${COLLECTION_NAME.users}/${uid}/${COLLECTION_NAME.chatRoomReferences}`
          ).withConverter(chatRoomReferenceConverter),
          where('unreadMessages.count', '>', 0),
          limit(1)
        ),
        (snapshot) => {
          const chatRoomReferences = snapshot.docs.map((doc) => doc.data())
          if (!snapshot.empty) {
            const chatRoomReference = chatRoomReferences[0]
            const { unreadMessages } = chatRoomReference
            commit('setUnreadMessageMutation', unreadMessages || null)
          } else {
            commit('setUnreadMessageMutation', null)
          }
        }
      )
      commit('setUnsubscribeMutation', unsubscribe)
    },
    /**
     * unmountした際にstoreにunsubscribeが残ると
     * 再度mountしたときにlistenerが更新されないためリセットする必要がある
     */
    unsubscribeListener({ state, commit }) {
      if (state.unsubscribe) {
        state.unsubscribe()
        commit('setUnsubscribeMutation', null)
      }
    },
  }
)
