import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.map.js";
import { onSnapshot } from 'firebase/firestore';

// 戻り値はジェネリクスで型を指定できる

export var collectionDataFactory = function collectionDataFactory() {
  return {
    values: computed(function () {
      return [];
    }),
    loading: ref(true),
    error: ref(null)
  };
};
export var useCollectionData = function useCollectionData(getQuery, option) {
  var _useCollection = useCollection(getQuery, option),
    snapshot = _useCollection.snapshot,
    loading = _useCollection.loading,
    error = _useCollection.error;
  var values = computed(function () {
    return snapshot.value ? snapshot.value.docs.map(function (querySnapshot) {
      return querySnapshot.data();
    }) : [];
  });
  return {
    values: values,
    loading: loading,
    error: error
  };
};
var useCollection = function useCollection(getQuery, option) {
  var snapshotState = reactive({
    snapshot: undefined,
    loading: true,
    error: null
  });
  var q = computed(function () {
    return getQuery();
  });
  var listener = function listener() {};
  var bindSnapshotHandler = function bindSnapshotHandler() {
    if (!q.value) {
      snapshotState.loading = false;
      snapshotState.snapshot = undefined;
      return;
    }
    listener();
    snapshotState.loading = true;
    var builtQuery = q.value.withConverter(option.dataConverter);
    listener = onSnapshot(builtQuery, function (snapshot) {
      snapshotState.snapshot = snapshot;
      snapshotState.loading = false;
    }, function (error) {
      snapshotState.error = error;
      snapshotState.loading = false;
      throw new Error(error.message);
    });
  };

  // マウント完了時か、クエリが変化したときにデータを読み込む
  watch(q, bindSnapshotHandler, {
    immediate: true
  });
  onUnmounted(function () {
    listener();
  });
  return _objectSpread({}, toRefs(snapshotState));
};