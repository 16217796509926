"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.messageFactory = void 0;
function messageFactory() {
    return {
        author: {
            ref: null,
        },
        createdAt: null,
        updatedAt: null,
        text: '',
        image: '',
    };
}
exports.messageFactory = messageFactory;
