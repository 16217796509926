"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonLikedTalent = void 0;
class CommonLikedTalent {
    constructor(init, id) {
        Object.assign(this, { ...init, id });
    }
    static toFirestore(data) {
        return {
            talent: data.talent,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
        };
    }
}
exports.CommonLikedTalent = CommonLikedTalent;
