import type { ITalent } from '@batteki/common'
import { deletedTalentFactory, COLLECTION_NAME } from '@batteki/common'
import type {
  CollectionReference,
  QueryConstraint,
  WriteBatch,
} from 'firebase/firestore'
import {
  doc,
  increment,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  writeBatch,
} from 'firebase/firestore'
import { apiAdapter } from '../adapter'
import type { TalentMedia } from '../talentMedia/talent-media.class'
import { talentMediaConverter } from '../talentMedia/talent-media.class'
import type { Review } from '../reviews/review.class'
import { reviewConverter } from '../reviews/review.class'
import { Talent, talentConverter } from './talent.class'
import { db, bindTalentRef } from '@batteki/base/src/plugins/firebase'

export class TalentsRepository {
  private ref: CollectionReference<ITalent>
  constructor() {
    this.ref = bindTalentRef
  }

  get(id: string): Promise<Talent | undefined> {
    // データ取得・モデルの変換処理
    return apiAdapter.requestGetAPI<Talent>(this.ref, id, talentConverter)
  }

  getWithoutDeleted(id: string): Promise<Talent> {
    // データ取得・モデルの変換処理
    // talentが削除済みの場合を考慮
    return apiAdapter.getWithoutDeleted<Talent>(
      this.ref,
      id,
      new Talent(deletedTalentFactory(), id),
      talentConverter
    )
  }

  // create new doc
  create(
    item: Talent,
    id: string | undefined = undefined
  ): Promise<Talent | undefined> {
    return apiAdapter.create<Talent>(this.ref, item, talentConverter, id)
  }

  // update existing talent profile
  update(item: Partial<ITalent>, id: string): Promise<Talent | undefined> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { lastId, ...itemWOLastId } = item
    return apiAdapter.update(this.ref, id, itemWOLastId, talentConverter)
  }

  updateNewId(id: string, item: ITalent): Promise<Talent | undefined> {
    return apiAdapter.update(this.ref, id, item, talentConverter)
  }

  async getMediaCollection(
    id: string,
    lastVisible: number | undefined = undefined,
    limits = 15
  ): Promise<{ media: TalentMedia[]; hasMore: boolean }> {
    let hasMore = false
    const colRef = apiAdapter.subCollection(this.ref, id, COLLECTION_NAME.media)
    const queryConstraints: QueryConstraint[] = [
      orderBy('orderIndex'),
      limit(limits + 1),
    ]
    if (lastVisible) {
      queryConstraints.push(startAfter(lastVisible))
    }
    const media = await apiAdapter.getByQuery<TalentMedia>(
      query(colRef, ...queryConstraints),
      talentMediaConverter
    )
    if (media.length === limits + 1) {
      hasMore = true
      media.pop()
    }
    return { hasMore, media }
  }

  /**
   * batch 一括更新
   * max 500件
   * @param talentId
   * @param media
   * @param deleteMedia
   */
  saveMedia(
    talentId: string,
    media: TalentMedia[],
    updateMedia: TalentMedia[],
    deleteMedia: TalentMedia[]
  ) {
    const batch = writeBatch(db)
    // Delete
    deleteMedia
      // 既存のデータはIDを持っているはず
      .filter((media) => media.id)
      .forEach((media) => {
        const colRef = apiAdapter.subCollection(
          this.ref,
          talentId,
          COLLECTION_NAME.media
        )
        apiAdapter.batchDelete(batch, doc(colRef, media.id))
      })
    // Create
    media.forEach((media) => {
      const colRef = apiAdapter.subCollection(
        this.ref,
        talentId,
        COLLECTION_NAME.media
      )
      media.id
        ? apiAdapter.batchCreate<TalentMedia>(
            batch,
            doc(colRef, media.id),
            media,
            talentMediaConverter
          )
        : apiAdapter.batchCreate<TalentMedia>(
            batch,
            doc(colRef),
            media,
            talentMediaConverter
          )
    })
    updateMedia.forEach((media) => {
      const colRef = apiAdapter.subCollection(
        this.ref,
        talentId,
        COLLECTION_NAME.media
      )
      apiAdapter.batchUpdate<TalentMedia>(batch, doc(colRef, media.id), media)
    })
    return batch.commit()
  }

  /**
   * likeされた数をカウントアップ
   * @param batch
   * @param uid
   */
  batchUpdateLikeCountUp(batch: WriteBatch, uid: string): WriteBatch {
    const ref = doc(this.ref, uid)
    return apiAdapter.batchUpdate(batch, ref, {
      liked: increment(1),
    })
  }

  /**
   * likeされた数をカウントダウン
   * @param batch
   * @param uid
   */
  batchUpdateLikeCountDown(batch: WriteBatch, uid: string): WriteBatch {
    const ref = doc(this.ref, uid)
    return apiAdapter.batchUpdate(batch, ref, {
      liked: increment(-1),
    })
  }

  /**
   * Review作成
   * @param uid
   * @param item
   * @param revieweeUid
   */
  createReview(uid: string, item: Review): Promise<Review | undefined> {
    const reviewsRef = apiAdapter.subCollection(
      this.ref,
      uid,
      COLLECTION_NAME.reviews
    )
    return apiAdapter.create<Review>(reviewsRef, item, reviewConverter)
  }

  /**
   * Review 取得
   * @param reviewerUid
   * @param reviewId
   */
  getReview(
    reviewerUid: string,
    reviewId: string
  ): Promise<Review | undefined> {
    const colRef = apiAdapter.subCollection(
      this.ref,
      reviewerUid,
      COLLECTION_NAME.reviews
    )
    return apiAdapter.requestGetAPI(colRef, reviewId, reviewConverter)
  }

  /**
   * すでにレビューしているReview取得
   * @param reviewerUid
   * @param jobId
   * @param revieweeUid
   */
  getAlreadyReviewedByQuery(
    reviewerUid: string,
    jobId: string,
    revieweeUid: string
  ): Promise<Review[]> {
    const colRef = apiAdapter.subCollection(
      this.ref,
      reviewerUid,
      COLLECTION_NAME.reviews
    )
    const jobRef = doc(db, `${COLLECTION_NAME.jobs}/${jobId}`)
    const revieweeRef = doc(db, `${COLLECTION_NAME.clients}/${revieweeUid}`)
    return apiAdapter.getByQuery<Review>(
      query(
        colRef,
        where('job.ref', '==', jobRef),
        where('to.ref', '==', revieweeRef)
      ),
      reviewConverter
    )
  }
}
