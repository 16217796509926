
import { defineComponent } from 'vue'
import { truncate } from '@batteki/base/src/utils/filters'
import TheNotifications from '@batteki/base/src/components/organisms/layout/TheNotifications.vue'
import type { User } from '@batteki/base/src/domain/users/user.class'
import TheSearchTalents from '~/components/organisms/layout/TheSearchTalents.vue'
import TheSearchJobs from '~/components/organisms/layout/TheSearchJobs.vue'
interface Data {
  dialog: boolean
  pcBanner: boolean
}

export default defineComponent({
  components: {
    TheSearchTalents,
    TheSearchJobs,
    TheNotifications,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data(): Data {
    return {
      dialog: false,
      pcBanner: true,
    }
  },
  computed: {
    user(): User | null {
      return this.$accessor.auth.userInfo
    },
    searchType(): 'talent' | 'job' | null {
      if (this.isClientJobs) {
        return 'job'
      } else if (this.isTalentTalents) {
        return 'talent'
      } else if (this.isClient) {
        return 'talent'
      } else if (this.isTalent) {
        return 'job'
      } else {
        return null
      }
    },
    isClient(): boolean {
      return this.$accessor.auth.isClient
    },
    isTalent(): boolean {
      return this.$accessor.auth.isTalent
    },
    isTalentTalents(): boolean {
      return this.$route.name === 'talent-talents'
    },
    isClientJobs(): boolean {
      return this.$route.name === 'client-jobs'
    },
  },
  methods: { truncate },
})
