"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.algoliaJobFactory = void 0;
function algoliaJobFactory() {
    return {
        objectID: '',
        name: '',
        thumbnail: '',
        overview: '',
        location: '',
        schedules: '',
        capacity: {
            female: 0,
            male: 0,
        },
        media: '',
        category: '',
        age: {
            from: null,
            to: null,
        },
        views: 0,
        entries: 0,
        offers: 0,
        endAt: null,
        createdAt: null,
        isPublished: false,
        fee: 0,
        publishedAt: null,
        dispId: '',
    };
}
exports.algoliaJobFactory = algoliaJobFactory;
