
import { defineComponent } from 'vue'
import anime from 'animejs'
export default defineComponent({
  mounted() {
    anime({
      targets: '#logo .lines path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 500,
      delay(_el, i) {
        return i * 250
      },
      direction: 'alternate',
      loop: true,
    })
    anime({
      targets: '#logo .eye-left',
      translateX: [
        { value: 10, duration: 400, delay: 0 },
        { value: -10, duration: 400, delay: 400 },
        { value: 0, duration: 400, delay: 400 },
      ],
      translateY: [
        { value: 10, duration: 400, delay: 2000 },
        { value: 0, duration: 400, delay: 400 },
      ],
      easing: 'easeOutElastic(1, .8)',
      loop: true,
    })
  },
})
