"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prefectureList = exports.Region = exports.Prefectures = void 0;
var Prefectures;
(function (Prefectures) {
    Prefectures[Prefectures["\u672A\u9078\u629E"] = 0] = "\u672A\u9078\u629E";
    Prefectures[Prefectures["\u5317\u6D77\u9053"] = 1] = "\u5317\u6D77\u9053";
    Prefectures[Prefectures["\u9752\u68EE\u770C"] = 2] = "\u9752\u68EE\u770C";
    Prefectures[Prefectures["\u5CA9\u624B\u770C"] = 3] = "\u5CA9\u624B\u770C";
    Prefectures[Prefectures["\u5BAE\u57CE\u770C"] = 4] = "\u5BAE\u57CE\u770C";
    Prefectures[Prefectures["\u79CB\u7530\u770C"] = 5] = "\u79CB\u7530\u770C";
    Prefectures[Prefectures["\u5C71\u5F62\u770C"] = 6] = "\u5C71\u5F62\u770C";
    Prefectures[Prefectures["\u798F\u5CF6\u770C"] = 7] = "\u798F\u5CF6\u770C";
    Prefectures[Prefectures["\u8328\u57CE\u770C"] = 8] = "\u8328\u57CE\u770C";
    Prefectures[Prefectures["\u6803\u6728\u770C"] = 9] = "\u6803\u6728\u770C";
    Prefectures[Prefectures["\u7FA4\u99AC\u770C"] = 10] = "\u7FA4\u99AC\u770C";
    Prefectures[Prefectures["\u57FC\u7389\u770C"] = 11] = "\u57FC\u7389\u770C";
    Prefectures[Prefectures["\u5343\u8449\u770C"] = 12] = "\u5343\u8449\u770C";
    Prefectures[Prefectures["\u6771\u4EAC\u90FD"] = 13] = "\u6771\u4EAC\u90FD";
    Prefectures[Prefectures["\u795E\u5948\u5DDD\u770C"] = 14] = "\u795E\u5948\u5DDD\u770C";
    Prefectures[Prefectures["\u65B0\u6F5F\u770C"] = 15] = "\u65B0\u6F5F\u770C";
    Prefectures[Prefectures["\u5BCC\u5C71\u770C"] = 16] = "\u5BCC\u5C71\u770C";
    Prefectures[Prefectures["\u77F3\u5DDD\u770C"] = 17] = "\u77F3\u5DDD\u770C";
    Prefectures[Prefectures["\u798F\u4E95\u770C"] = 18] = "\u798F\u4E95\u770C";
    Prefectures[Prefectures["\u5C71\u68A8\u770C"] = 19] = "\u5C71\u68A8\u770C";
    Prefectures[Prefectures["\u9577\u91CE\u770C"] = 20] = "\u9577\u91CE\u770C";
    Prefectures[Prefectures["\u5C90\u961C\u770C"] = 21] = "\u5C90\u961C\u770C";
    Prefectures[Prefectures["\u9759\u5CA1\u770C"] = 22] = "\u9759\u5CA1\u770C";
    Prefectures[Prefectures["\u611B\u77E5\u770C"] = 23] = "\u611B\u77E5\u770C";
    Prefectures[Prefectures["\u4E09\u91CD\u770C"] = 24] = "\u4E09\u91CD\u770C";
    Prefectures[Prefectures["\u6ECB\u8CC0\u770C"] = 25] = "\u6ECB\u8CC0\u770C";
    Prefectures[Prefectures["\u4EAC\u90FD\u5E9C"] = 26] = "\u4EAC\u90FD\u5E9C";
    Prefectures[Prefectures["\u5927\u962A\u5E9C"] = 27] = "\u5927\u962A\u5E9C";
    Prefectures[Prefectures["\u5175\u5EAB\u770C"] = 28] = "\u5175\u5EAB\u770C";
    Prefectures[Prefectures["\u5948\u826F\u770C"] = 29] = "\u5948\u826F\u770C";
    Prefectures[Prefectures["\u548C\u6B4C\u5C71\u770C"] = 30] = "\u548C\u6B4C\u5C71\u770C";
    Prefectures[Prefectures["\u9CE5\u53D6\u770C"] = 31] = "\u9CE5\u53D6\u770C";
    Prefectures[Prefectures["\u5CF6\u6839\u770C"] = 32] = "\u5CF6\u6839\u770C";
    Prefectures[Prefectures["\u5CA1\u5C71\u770C"] = 33] = "\u5CA1\u5C71\u770C";
    Prefectures[Prefectures["\u5E83\u5CF6\u770C"] = 34] = "\u5E83\u5CF6\u770C";
    Prefectures[Prefectures["\u5C71\u53E3\u770C"] = 35] = "\u5C71\u53E3\u770C";
    Prefectures[Prefectures["\u5FB3\u5CF6\u770C"] = 36] = "\u5FB3\u5CF6\u770C";
    Prefectures[Prefectures["\u9999\u5DDD\u770C"] = 37] = "\u9999\u5DDD\u770C";
    Prefectures[Prefectures["\u611B\u5A9B\u770C"] = 38] = "\u611B\u5A9B\u770C";
    Prefectures[Prefectures["\u9AD8\u77E5\u770C"] = 39] = "\u9AD8\u77E5\u770C";
    Prefectures[Prefectures["\u798F\u5CA1\u770C"] = 40] = "\u798F\u5CA1\u770C";
    Prefectures[Prefectures["\u4F50\u8CC0\u770C"] = 41] = "\u4F50\u8CC0\u770C";
    Prefectures[Prefectures["\u9577\u5D0E\u770C"] = 42] = "\u9577\u5D0E\u770C";
    Prefectures[Prefectures["\u718A\u672C\u770C"] = 43] = "\u718A\u672C\u770C";
    Prefectures[Prefectures["\u5927\u5206\u770C"] = 44] = "\u5927\u5206\u770C";
    Prefectures[Prefectures["\u5BAE\u5D0E\u770C"] = 45] = "\u5BAE\u5D0E\u770C";
    Prefectures[Prefectures["\u9E7F\u5150\u5CF6\u770C"] = 46] = "\u9E7F\u5150\u5CF6\u770C";
    Prefectures[Prefectures["\u6C96\u7E04\u770C"] = 47] = "\u6C96\u7E04\u770C";
})(Prefectures = exports.Prefectures || (exports.Prefectures = {}));
var Region;
(function (Region) {
    Region[Region["\u672A\u9078\u629E"] = 0] = "\u672A\u9078\u629E";
    Region[Region["\u5317\u6D77\u9053\u30FB\u6771\u5317"] = 1] = "\u5317\u6D77\u9053\u30FB\u6771\u5317";
    Region[Region["\u95A2\u6771"] = 2] = "\u95A2\u6771";
    Region[Region["\u4E2D\u90E8"] = 3] = "\u4E2D\u90E8";
    Region[Region["\u95A2\u897F"] = 4] = "\u95A2\u897F";
    Region[Region["\u4E2D\u56FD\u30FB\u56DB\u56FD"] = 5] = "\u4E2D\u56FD\u30FB\u56DB\u56FD";
    Region[Region["\u4E5D\u5DDE\u30FB\u6C96\u7E04"] = 6] = "\u4E5D\u5DDE\u30FB\u6C96\u7E04";
})(Region = exports.Region || (exports.Region = {}));
exports.prefectureList = {
    北海道: {
        code: Prefectures.北海道,
        region: Region['北海道・東北'],
    },
    青森県: {
        code: Prefectures.青森県,
        region: Region['北海道・東北'],
    },
    岩手県: {
        code: Prefectures.岩手県,
        region: Region['北海道・東北'],
    },
    宮城県: {
        code: Prefectures.宮城県,
        region: Region['北海道・東北'],
    },
    秋田県: {
        code: Prefectures.秋田県,
        region: Region['北海道・東北'],
    },
    山形県: {
        code: Prefectures.山形県,
        region: Region['北海道・東北'],
    },
    福島県: {
        code: Prefectures.福島県,
        region: Region['北海道・東北'],
    },
    茨城県: {
        code: Prefectures.茨城県,
        region: Region.関東,
    },
    栃木県: {
        code: Prefectures.栃木県,
        region: Region.関東,
    },
    群馬県: {
        code: Prefectures.群馬県,
        region: Region.関東,
    },
    埼玉県: {
        code: Prefectures.埼玉県,
        region: Region.関東,
    },
    千葉県: {
        code: Prefectures.千葉県,
        region: Region.関東,
    },
    東京都: {
        code: Prefectures.東京都,
        region: Region.関東,
    },
    神奈川県: {
        code: Prefectures.神奈川県,
        region: Region.関東,
    },
    新潟県: {
        code: Prefectures.新潟県,
        region: Region.中部,
    },
    富山県: {
        code: Prefectures.富山県,
        region: Region.中部,
    },
    石川県: {
        code: Prefectures.石川県,
        region: Region.中部,
    },
    福井県: {
        code: Prefectures.福井県,
        region: Region.中部,
    },
    山梨県: {
        code: Prefectures.山梨県,
        region: Region.中部,
    },
    長野県: {
        code: Prefectures.長野県,
        region: Region.中部,
    },
    岐阜県: {
        code: Prefectures.岐阜県,
        region: Region.中部,
    },
    静岡県: {
        code: Prefectures.静岡県,
        region: Region.中部,
    },
    愛知県: {
        code: Prefectures.愛知県,
        region: Region.中部,
    },
    三重県: {
        code: Prefectures.三重県,
        region: Region.中部,
    },
    滋賀県: {
        code: Prefectures.滋賀県,
        region: Region.関西,
    },
    京都府: {
        code: Prefectures.京都府,
        region: Region.関西,
    },
    大阪府: {
        code: Prefectures.大阪府,
        region: Region.関西,
    },
    兵庫県: {
        code: Prefectures.兵庫県,
        region: Region.関西,
    },
    奈良県: {
        code: Prefectures.奈良県,
        region: Region.関西,
    },
    和歌山県: {
        code: Prefectures.和歌山県,
        region: Region.関西,
    },
    鳥取県: {
        code: Prefectures.鳥取県,
        region: Region['中国・四国'],
    },
    島根県: {
        code: Prefectures.島根県,
        region: Region['中国・四国'],
    },
    岡山県: {
        code: Prefectures.岡山県,
        region: Region['中国・四国'],
    },
    広島県: {
        code: Prefectures.広島県,
        region: Region['中国・四国'],
    },
    山口県: {
        code: Prefectures.山口県,
        region: Region['中国・四国'],
    },
    徳島県: {
        code: Prefectures.徳島県,
        region: Region['中国・四国'],
    },
    香川県: {
        code: Prefectures.香川県,
        region: Region['中国・四国'],
    },
    愛媛県: {
        code: Prefectures.愛媛県,
        region: Region['中国・四国'],
    },
    高知県: {
        code: Prefectures.高知県,
        region: Region['中国・四国'],
    },
    福岡県: {
        code: Prefectures.福岡県,
        region: Region['九州・沖縄'],
    },
    佐賀県: {
        code: Prefectures.佐賀県,
        region: Region['九州・沖縄'],
    },
    長崎県: {
        code: Prefectures.長崎県,
        region: Region['九州・沖縄'],
    },
    熊本県: {
        code: Prefectures.熊本県,
        region: Region['九州・沖縄'],
    },
    大分県: {
        code: Prefectures.大分県,
        region: Region['九州・沖縄'],
    },
    宮崎県: {
        code: Prefectures.宮崎県,
        region: Region['九州・沖縄'],
    },
    鹿児島県: {
        code: Prefectures.鹿児島県,
        region: Region['九州・沖縄'],
    },
    沖縄県: {
        code: Prefectures.沖縄県,
        region: Region['九州・沖縄'],
    },
};
