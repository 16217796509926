import type { IClient } from '@batteki/common'
import { COLLECTION_NAME } from '@batteki/common'
import type { CollectionReference, WriteBatch } from 'firebase/firestore'
import { collection, doc, query, where } from 'firebase/firestore'
import { apiAdapter } from '../adapter'
import type { Review } from '../reviews/review.class'
import { reviewConverter } from '../reviews/review.class'
import { useDocumentData } from '../common/useDocument'
import { Client, clientConverter } from './client.class'
import { db } from '@batteki/base/src/plugins/firebase'
export class ClientRepo {
  private colRef: CollectionReference
  constructor() {
    this.colRef = collection(db, COLLECTION_NAME.clients)
  }

  create(item: Client, id: string): Promise<Client | undefined> {
    return apiAdapter.create<Client>(this.colRef, item, clientConverter, id)
  }

  get(id: string): Promise<Client | undefined> {
    return apiAdapter.requestGetAPI<Client>(this.colRef, id, clientConverter)
  }

  getWithoutDeleted(id: string): Promise<Client> {
    // データ取得・モデルの変換処理
    // talentが削除済みの場合を考慮
    return apiAdapter.getWithoutDeleted<Client>(
      this.colRef,
      id,
      Client.initDeleted(id),
      clientConverter
    )
  }

  update(item: Client, id: string): Promise<Client | undefined> {
    return apiAdapter.update<IClient, Client>(
      this.colRef,
      id,
      item,
      clientConverter
    )
  }

  batchUpdate(batch: WriteBatch, uid: string, review: Partial<IClient>) {
    return apiAdapter.batchUpdate<IClient>(batch, doc(this.colRef, uid), review)
  }

  /**
   * Review 作成
   * @param uid
   * @param item
   * @param revieweeUid
   */
  createReview(uid: string, item: Review): Promise<Review | undefined> {
    const ref = apiAdapter.subCollection(
      this.colRef,
      uid,
      COLLECTION_NAME.reviews
    )
    return apiAdapter.create<Review>(ref, item, reviewConverter)
  }

  /**
   * Review取得
   * @param reviewerUid
   * @param revieweeUid
   */
  getReview(reviewerUid: string, revieweeUid: string) {
    const ref = apiAdapter.subCollection(
      this.colRef,
      reviewerUid,
      COLLECTION_NAME.reviews
    )
    return apiAdapter.requestGetAPI(ref, revieweeUid, reviewConverter)
  }

  /**
   * すでにレビューしているReview取得
   * @param reviewerUid
   * @param jobId
   * @param revieweeUid
   */
  getAlreadyReviewedByQuery(
    reviewerUid: string,
    jobId: string,
    revieweeUid: string
  ): Promise<Review[]> {
    const colRef = apiAdapter.subCollection(
      this.colRef,
      reviewerUid,
      COLLECTION_NAME.reviews
    )
    const jobRef = doc(db, `${COLLECTION_NAME.jobs}/${jobId}`)
    const revieweeRef = doc(db, `${COLLECTION_NAME.talents}/${revieweeUid}`)

    return apiAdapter.getByQuery<Review>(
      query(
        colRef,
        where('job.ref', '==', jobRef),
        where('to.ref', '==', revieweeRef)
      ),
      reviewConverter
    )
  }

  useData(inputId: string | null) {
    const dataState = useDocumentData(
      () => (inputId ? doc(this.colRef, inputId) : null),
      {
        dataConverter: clientConverter,
      }
    )

    return {
      loading: dataState.loading,
      value: dataState.value,
    }
  }
}
