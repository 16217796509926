export default defineComponent({
  components: {},
  setup: function setup() {
    var _useNuxtApp = useNuxtApp(),
      nuxt2Context = _useNuxtApp.nuxt2Context;
    var title = computed(function () {
      return nuxt2Context.$headerTitle.title;
    });
    watch(function () {
      return title.value;
    }, function (title) {
      useHead({
        title: title
      });
    }, {
      immediate: true
    });
    return {
      title: title
    };
  }
});