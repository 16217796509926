"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ROBOT_PAYMENT_RULES = exports.PaymentMethodDisp = void 0;
exports.PaymentMethodDisp = {
    //  未選択: null, // 固定のため廃止
    // クレジットカード: 1, // 廃止
    銀行振込: 2, // バンクチェック
};
/**
 * 対象/ロボットペイメント項目名/チェック：
 * client.company.name/請求先名/max100,required
 * client.department/請求先部署名/max100,required
 * client.name/宛名１/max60,required
 * client.company.address.postalCode/郵便番号/postalCode,required
 * client.company.address.prefecture/都道府県/prefecture,required
 * client.company.address.city+street/市区町村番地/ctyStreetSize,required
 * client.company.address.buildingName/建物名/max60
 * client.company.phone/電話番号/phone
 * user.email/メールアドレス/max100,email,required
 */
exports.ROBOT_PAYMENT_RULES = {
    required: (v) => !!v || v === 0 || '必須項目です。',
    max100: (v) => (v || '').length <= 100 || ` 100文字以内で入力ください`,
    max60: (v) => (v || '').length <= 60 || ` 60文字以内で入力ください`,
    postalCode: (v) => !v ||
        /^[0-9]{7}$/.test(v) ||
        ' 郵便番号はハイフンなしで半角数字7桁で入力ください',
    prefecture: (v) => (v || '').length <= 4 || ` 4文字以内で入力ください`,
    cityStreetSize: (city, street) => (city + '' + street).length <= 56 ||
        ` 市区町村と番地を合わせて56文字以内で入力ください`,
    phone: (v) => !v ||
        /^[+]?[(]?[0-9]{3,4}[)]?[-\s]?[0-9]{3,4}[-\s]?[0-9]{4,6}$/im.test(v) ||
        ' 電話番号を入力ください',
    email: (v) => /.+@.+\..+/.test(v) || ' 正しいメールアドレスを入力してください。',
};
