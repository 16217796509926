import { fetchAndActivate } from 'firebase/remote-config'
import { remoteConfig } from './firebase'

/**
 * Remote configの初期化
 */
export default async () => {
  // Serverからパラメーター取得がタイムアウトするまで
  // Default: 1 min
  remoteConfig.settings.fetchTimeoutMillis = 60 * 1000

  // サーバーから取得するインターバル：
  // その期間はキャッシュから取得するので更新されない
  // Default: 24 hours
  remoteConfig.settings.minimumFetchIntervalMillis = 1 * 60 * 1000 // 1分
  remoteConfig.defaultConfig = {
    maintenance: false,
    maintenanceStartAt: 0,
    maintenanceEndAt: 0,
  }
  await fetchAndActivate(remoteConfig)
}
