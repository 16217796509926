"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonJobChangelog = void 0;
class CommonJobChangelog {
    constructor(init, id) {
        Object.assign(this, { ...init, id });
    }
    setId(id) {
        this.id = id;
    }
    static toFirestore(data) {
        return {
            views: data.views,
            images: data.images,
            name: data.name,
            overview: data.overview,
            age: data.age,
            category: data.category,
            media: data.media,
            capacity: data.capacity,
            location: data.location,
            schedules: data.schedules,
            competition: data.competition,
            condition: data.condition,
            endAt: data.endAt,
            notes: data.notes,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            isPublished: data.isPublished,
            notifiedAt: data.notifiedAt,
            liked: data.liked,
            client: data.client,
            deletedAt: data.deletedAt,
            fee: data.fee,
            scheduleToClose: data.scheduleToClose,
            scheduledDate: data.scheduledDate,
            publishedAt: data.publishedAt,
            ermergencyPhone: data.ermergencyPhone,
            dispId: data.dispId,
        };
    }
}
exports.CommonJobChangelog = CommonJobChangelog;
