import type { Middleware, Context } from '@nuxt/types'
import { setTitle } from '@batteki/base/src/utils'

const middleware: Middleware = (ctx: Context): void => {
  console.debug('setTitle(ctx.route)', setTitle(ctx.route))
  ctx.$headerTitle.title = setTitle(ctx.route)
  ctx.$headerTitle.link = undefined
}

export default middleware
