import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import type { AlgoliaJobs, AlgoliaJobWithLikedWithState } from '@batteki/common'
import { Gender, likedJobFactory } from '@batteki/common'

import type {
  SearchResponse,
  SearchForFacetValuesResponse,
} from '@algolia/client-search'
import { Timestamp } from 'firebase/firestore'
import { UserRepo } from '@batteki/base/src/domain/users/repository'
import type { Ages } from '@batteki/base/src/domain/search/talents'
import { generateFilters } from '@batteki/base/src/utils/algolia'
import type { SelectItem } from '@batteki/base/src/domain/search/index'
import type { JobsCondition } from '@batteki/base/src/domain/search/jobs'
import { initJobsCondition } from '@batteki/base/src/domain/search/jobs'
import { initResult } from '@batteki/base/src/domain/algolia/algolia'
import dayjs from '@batteki/base/src/domain/util/dayjs'
import { JobRepo } from '@batteki/base/src/domain/repository'
import type { User } from '@batteki/base/src/domain/users/user.class'
import type { JobSort } from '@batteki/base/src/plugins/algolia'
import { algoliasearchJobs } from '@batteki/base/src/plugins/algolia'

export interface SearchJobState {
  jobs: JobsCondition
  searchResult: SearchResponse<AlgoliaJobWithLikedWithState>
  totalHits: number
  sort: JobSort | null
}
export const state = (): SearchJobState => ({
  jobs: initJobsCondition(),
  searchResult: initResult(),
  totalHits: 0,
  sort: 'DESC_PUBLISH',
})

export const getters = getterTree(state, {
  jobs: (state) => state.jobs,
  media: (state) => state.jobs.media,
  searchResult: (state): SearchResponse => state.searchResult,
  yearSelection: () => {
    return [...Array(101).keys()].map((i) => i++)
  },
  mediaFilter(state): string {
    return generateFilters(state.jobs.media, 'media')
  },
  categoryFilter(state): string {
    return generateFilters(state.jobs.categories, 'category')
  },
  genderFilter(state): string {
    const items = state.jobs.gender
    if (!items.length) return ''
    let filters: string[] = []
    for (const item of items) {
      if (item.value && item.text === Gender[1]) {
        filters = [...filters, 'capacity.male > 0']
      } else if (item.value && item.text === Gender[2]) {
        filters = [...filters, 'capacity.female > 0']
      }
    }
    return filters.length > 0 ? `(${filters.join(' OR ')})` : ''
  },
  ageFilter(state): string {
    const ages = state.jobs.ages
    const filters: string[] = []
    if (ages.from) {
      filters.push(`age.from <= ${ages.from}`)
    }
    if (ages.to) {
      filters.push(`age.to >= ${ages.to}`)
    }
    return filters.length > 0 ? `(${filters.join(' AND ')})` : ''
  },

  excludeExpireFilter(state): string {
    const flag = state.jobs.excludeExpired
    const now = dayjs().unix() // Second format
    if (flag) {
      return `endAt._seconds > ${now}`
    } else {
      return ''
    }
  },

  hasMore(state): boolean {
    const { nbPages, page } = state.searchResult
    return page + 1 < nbPages
  },
  filters(
    _state,
    {
      mediaFilter,
      categoryFilter,
      genderFilter,
      ageFilter,
      excludeExpireFilter,
    }
  ) {
    const filters = [
      mediaFilter,
      categoryFilter,
      genderFilter,
      ageFilter,
      excludeExpireFilter,
    ]
      .filter((val) => val)
      .join(' AND ')
    return filters
  },
  totalHits: (state) => state.totalHits,
  sort: (state) => state.sort,
})

export const mutations = mutationTree(state, {
  updateCategoriesMutation(state, item: SelectItem) {
    state.jobs.categories = state.jobs.categories.map((category) => {
      if (category.text === item.text) {
        category.value = true
        return category
      } else {
        category.value = false
        return category
      }
    })
  },

  updateGenderMutation(state, gender: SelectItem) {
    state.jobs.gender = state.jobs.gender.map((selectItem) => {
      if (selectItem.text === gender.text) {
        selectItem.value = gender.value
        return selectItem
      } else {
        return selectItem
      }
    })
  },

  updateAgeMutation(state, item: { key: keyof Ages; val: number }) {
    state.jobs.ages[item.key] = item.val
  },

  updateExcludeExpiredMutation(state, val: boolean) {
    state.jobs.excludeExpired = val
  },

  setMediaMutation(state, items: SelectItem[]) {
    state.jobs.media = items
  },
  setCategoriesMutation(state, items: SelectItem[]) {
    state.jobs.categories = items
  },
  updateMediaMutation(state, item: SelectItem) {
    state.jobs.media = state.jobs.media.map((_media) => {
      if (_media.text === item.text) {
        _media.value = item.value
        return _media
      } else {
        return _media
      }
    })
  },
  updateCategoryMutation(state, item: SelectItem) {
    state.jobs.categories = state.jobs.categories.map((_category) => {
      if (_category.text === item.text) {
        _category.value = item.value
        return _category
      } else {
        return _category
      }
    })
  },
  clearCategoryMutation(state) {
    state.jobs.categories.map((item) => (item.value = false))
  },
  updateKeywordsMutation(state, val: string) {
    state.jobs.keywords = val
  },
  setSearchResultMutation(
    state,
    val: SearchResponse<AlgoliaJobWithLikedWithState>
  ) {
    state.searchResult = val
  },
  updateSerchResultMutation(
    state,
    val: SearchResponse<AlgoliaJobWithLikedWithState>
  ) {
    const prevHits = state.searchResult.hits
    state.searchResult = val
    state.searchResult.hits = [...prevHits, ...val.hits]
  },
  clearMutation(state) {
    state.jobs = initJobsCondition()
  },
  setTotalHitsMutation(state, totalHits: number) {
    state.totalHits = totalHits
  },
  updateLikedMutation(state, talentId: string) {
    const { hits } =
      state.searchResult as SearchResponse<AlgoliaJobWithLikedWithState>
    const newHits = hits.map((hit) => {
      if (hit.objectID !== talentId) return hit
      if (hit.likedJob?.id) {
        return {
          ...hit,
          likedJob: undefined,
        }
      } else {
        return {
          ...hit,
          likedJob: {
            ...likedJobFactory(),
            id: talentId,
          },
        }
      }
    })
    state.searchResult = { ...state.searchResult, hits: newHits }
  },
  setSortMutation(state, val: JobSort | null) {
    state.sort = val
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    updateGender({ commit }, gender: SelectItem) {
      commit('updateGenderMutation', gender)
    },
    updateMedia({ commit }, item: SelectItem) {
      commit('updateMediaMutation', item)
    },

    updateKeywords({ commit }, val: string) {
      commit('updateKeywordsMutation', val)
    },

    updateCategories({ commit }, item: SelectItem) {
      commit('updateCategoriesMutation', item)
    },

    updateAge({ commit }, item: { key: keyof Ages; val: number }) {
      commit('updateAgeMutation', item)
    },

    clearCategory({ commit }) {
      commit('clearCategoryMutation')
    },

    setSort({ commit }, val: JobSort | null) {
      commit('setSortMutation', val)
    },

    updateCategory({ commit }, item: SelectItem) {
      commit('updateCategoryMutation', item)
    },

    updateExcludeExpired({ commit }, val: boolean) {
      commit('updateExcludeExpiredMutation', val)
    },

    // Get Facets
    async getCategoryFacets({ state, commit }) {
      // aligoliaアクセス数軽減の為storeに存在している場合はリクエストしない（TACLOUD-338 アクセス数に対しalgoliaの検索数が多い）
      if (state.jobs.categories.length) return
      const facets: SearchForFacetValuesResponse = await algoliasearchJobs(
        state.sort
      ).searchForFacetValues('category', '')
      const categories: SelectItem[] = facets.facetHits.map((facet) => {
        // 既存の選択状態がある場合は選択状態を保持
        const oldFacet = state.jobs.categories.find(
          (type) => type.text === facet.value
        )
        return oldFacet || { text: facet.value, value: false }
      })
      commit('setCategoriesMutation', categories)
    },

    async getMediaFacets({ state, commit }) {
      // aligoliaアクセス数軽減の為storeに存在している場合はリクエストしない（TACLOUD-338 アクセス数に対しalgoliaの検索数が多い）
      if (state.jobs.media.length) return
      const facets: SearchForFacetValuesResponse = await algoliasearchJobs(
        state.sort
      ).searchForFacetValues('media', '')
      const media: SelectItem[] = facets.facetHits.map((facet) => {
        // 既存の選択状態がある場合は選択状態を保持
        const oldFacet = state.jobs.media.find(
          (type) => type.text === facet.value
        )
        return oldFacet || { text: facet.value, value: false }
      })
      commit('setMediaMutation', media)
    },
    /**
     * 案件検索
     */
    async search({ commit, state, getters, rootGetters }, count = 0) {
      try {
        const userRepo = new UserRepo()
        const jobRepo = new JobRepo()
        const userInfo = rootGetters['auth/userInfo'] as User
        const result = await algoliasearchJobs(state.sort).search<AlgoliaJobs>(
          state.jobs.keywords,
          {
            filters: getters.filters,
            page: count ? state.searchResult.page + count : 0,
          }
        )
        const promises = result.hits.map(async (hit) => {
          const [likedJob, entry, offer] = await Promise.all([
            userRepo.findOneLIkedJob(userInfo.id, hit.objectID),
            userInfo.role === 'talent'
              ? jobRepo.getEntry(hit.objectID, userInfo.id)
              : null,
            userInfo.role === 'talent'
              ? jobRepo.getOffer(hit.objectID, userInfo.id)
              : null,
          ])

          if (likedJob) {
            return {
              ...hit,
              endAt: hit.endAt
                ? Timestamp.fromMillis(
                    Number((hit as any).endAt._seconds * 1000)
                  )
                : null,
              publishedAt: hit.publishedAt
                ? Timestamp.fromMillis(
                    Number((hit as any).publishedAt._seconds * 1000)
                  )
                : null,
              likedJob: {
                id: likedJob.id,
                createdAt: likedJob.createdAt,
                updatedAt: likedJob.updatedAt,
              },
              entryStatus: entry ? entry.status : null,
              offerStatus: offer ? offer.status : null,
            }
          } else {
            return {
              ...hit,
              endAt: hit.endAt
                ? Timestamp.fromMillis(
                    Number((hit as any).endAt._seconds * 1000)
                  )
                : null,
              publishedAt: hit.publishedAt
                ? Timestamp.fromMillis(
                    Number((hit as any).publishedAt._seconds * 1000)
                  )
                : null,
              likedJob: likedJobFactory(),
              entryStatus: entry ? entry.status : null,
              offerStatus: offer ? offer.status : null,
            }
          }
        })
        const algoliaJobsWithLikes = await Promise.all(promises)
        const mergedResult = {
          ...result,
          hits: algoliaJobsWithLikes,
        } as SearchResponse<AlgoliaJobWithLikedWithState>
        count === 0
          ? commit('setSearchResultMutation', mergedResult)
          : commit('updateSerchResultMutation', mergedResult)
      } catch (e) {
        console.error(e)
      }
    },
    /**
     * カテゴリから検索
     * @param param0
     * @param category
     */
    async searchByCategory(
      { commit, state, getters, rootGetters },
      category: string
    ) {
      try {
        const userRepo = new UserRepo()
        const jobRepo = new JobRepo()
        const userInfo = rootGetters['auth/userInfo'] as User
        const excludeExpireFilter = getters.excludeExpireFilter
        const catFilter = category === 'ALL' ? '' : `category: "${category}"`
        const filters = [catFilter, excludeExpireFilter]
          .filter((val) => val)
          .join(' AND ')
        console.log({ filters })
        console.log('start')
        const result = await algoliasearchJobs(state.sort).search<AlgoliaJobs>(
          '',
          {
            filters,
          }
        )
        const promises = result.hits.map(async (hit) => {
          const [likedJob, entry, offer] = await Promise.all([
            userRepo.findOneLIkedJob(userInfo.id, hit.objectID),
            userInfo.role === 'talent'
              ? jobRepo.getEntry(hit.objectID, userInfo.id)
              : null,
            userInfo.role === 'talent'
              ? jobRepo.getOffer(hit.objectID, userInfo.id)
              : null,
          ])
          if (likedJob) {
            return {
              ...hit,
              endAt: hit.endAt
                ? Timestamp.fromMillis(
                    Number((hit as any).endAt._seconds * 1000)
                  )
                : null,
              publishedAt: hit.publishedAt
                ? Timestamp.fromMillis(
                    Number((hit as any).publishedAt._seconds * 1000)
                  )
                : null,
              likedJob: {
                id: likedJob.id,
                createdAt: likedJob.createdAt,
                updatedAt: likedJob.updatedAt,
              },
              entryStatus: entry ? entry.status : null,
              offerStatus: offer ? offer.status : null,
            }
          } else {
            return {
              ...hit,
              endAt: hit.endAt
                ? Timestamp.fromMillis(
                    Number((hit as any).endAt._seconds * 1000)
                  )
                : null,
              publishedAt: hit.publishedAt
                ? Timestamp.fromMillis(
                    Number((hit as any).publishedAt._seconds * 1000)
                  )
                : null,
              likedJob: likedJobFactory(),
              entryStatus: entry ? entry.status : null,
              offerStatus: offer ? offer.status : null,
            }
          }
        })
        const algoliaJobsWithLikes = await Promise.all(promises)
        const mergedResult = {
          ...result,
          hits: algoliaJobsWithLikes,
        } as SearchResponse<AlgoliaJobWithLikedWithState>
        commit('setSearchResultMutation', mergedResult)
      } catch (error) {
        console.log(error)
      }
    },
    async totalJobs({ commit, state }) {
      try {
        const result = await algoliasearchJobs(state.sort).search<AlgoliaJobs>(
          ''
        )
        commit('setTotalHitsMutation', result.nbHits)
      } catch (error) {
        console.log(error)
      }
    },
    async clear({ commit }) {
      commit('clearMutation')
      await Promise.all([
        this.$accessor.search.jobs.getMediaFacets(),
        this.$accessor.search.jobs.getCategoryFacets(),
      ])
    },
    /**
     * キャストIDからlikeを切り替え
     * @param param0
     * @param talentId
     */
    toggleLiked({ commit }, talentId: string) {
      commit('updateLikedMutation', talentId)
    },
  }
)
