"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonMessage = void 0;
const __1 = require("../..");
class CommonMessage {
    constructor(init, id) {
        Object.assign(this, { ...this.mapToMessage(init), id });
    }
    /**
     *  Map to IMessage
     * @param {Partial<IMessage>} data
     */
    mapToMessage(data) {
        var _a, _b, _c, _d, _e;
        return {
            author: (_a = data.author) !== null && _a !== void 0 ? _a : { ref: null },
            text: (_b = data.text) !== null && _b !== void 0 ? _b : '',
            image: (_c = data.image) !== null && _c !== void 0 ? _c : '',
            createdAt: (_d = data.createdAt) !== null && _d !== void 0 ? _d : null,
            updatedAt: (_e = data.updatedAt) !== null && _e !== void 0 ? _e : null,
        };
    }
    static toFirestore(data) {
        return {
            author: {
                ref: data.author.ref,
            },
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            text: data.text,
            image: data.image,
        };
    }
    static initDefault() {
        return new CommonMessage((0, __1.messageFactory)(), '');
    }
}
exports.CommonMessage = CommonMessage;
