"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonUserCommon = void 0;
const __1 = require("../..");
const models_1 = require("../../models");
function isClient(arg) {
    return Object.prototype.hasOwnProperty.call(arg, 'company');
}
class CommonUserCommon {
    constructor(init, id, role) {
        this.name = init.name;
        this.thumbnail = init.thumbnail;
        this.role = role;
        if (isClient(init)) {
            this.company = init.company;
        }
        this.id = id;
    }
    static initDefault() {
        return new CommonUserCommon((0, models_1.talentFactory)(), '', 'talent');
    }
    static toFirestore(data) {
        return {
            name: data.name,
            thumbnail: data.thumbnail,
        };
    }
    static fromFirestore(data, id, path) {
        const role = path === __1.COLLECTION_NAME.clients
            ? 'client'
            : path === __1.COLLECTION_NAME.talents
                ? 'talent'
                : null;
        return new CommonUserCommon(data, id, role);
    }
}
exports.CommonUserCommon = CommonUserCommon;
