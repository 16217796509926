import { COLLECTION_NAME } from '@batteki/common'
import type { CollectionReference } from 'firebase/firestore'
import { collection, orderBy, query } from 'firebase/firestore'
import { apiAdapter } from '../adapter'
import type { MediaMaster } from './media-master.class'
import { mediaMasterConverter } from './media-master.class'
import { db } from '@batteki/base/src/plugins/firebase'

export class MediaRepo {
  private ref: CollectionReference
  constructor() {
    this.ref = collection(db, COLLECTION_NAME.mediaMaster)
  }

  getAll(): Promise<MediaMaster[]> {
    return apiAdapter.getByQuery<MediaMaster>(
      query(this.ref, orderBy('orderIndex', 'asc')),
      mediaMasterConverter
    )
  }
}
