import Vue from 'vue'
import type { Context } from '@nuxt/types'
import {
  ageFromMs,
  ageFromTimestamp,
  countdownFromTimestamp,
  formatDate,
  formatJSTDate,
  formatNumber,
  formatPrice,
  fromNow,
  getGender,
  getPrefecture,
  shortForThousand,
  truncate,
} from '../utils/filters'

export default (_ctx: Context) => {
  Vue.filter('ageFromTimestamp', ageFromTimestamp)
  Vue.filter('ageFromMs', ageFromMs)
  Vue.filter('countdownFromTimestamp', countdownFromTimestamp)
  /**
   * 生年月日はJST固定
   */
  Vue.filter('formatJSTDate', formatJSTDate)
  Vue.filter('prefecture', getPrefecture)
  Vue.filter('gender', getGender)
  Vue.filter('formatDate', formatDate)
  Vue.filter('fromNow', fromNow)
  Vue.filter('shortForThousand', shortForThousand)
  Vue.filter('formatNumber', formatNumber)

  Vue.filter('truncate', truncate)

  Vue.filter('formatPrice', formatPrice)
}
