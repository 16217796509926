import type {
  SearchResponse,
  SearchForFacetValuesResponse,
} from '@algolia/client-search'
export const initResult = (): SearchResponse<any> => ({
  nbPages: 0,
  nbHits: 0,
  hitsPerPage: 0,
  processingTimeMS: 0,
  query: '',
  params: '',
  exhaustiveFacetsCount: false,
  exhaustiveNbHits: false,
  page: 0,
  hits: [],
})

export const initFacets = (): SearchForFacetValuesResponse => ({
  facetHits: [
    {
      value: '',
      count: 0,
      highlighted: '',
    },
  ],
  exhaustiveFacetsCount: false,
  processingTimeMS: 0,
})
