"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.talentMediaFactory = exports.MediaType = exports.MediaSize = void 0;
var MediaSize;
(function (MediaSize) {
    MediaSize["S"] = "S";
    MediaSize["M"] = "M";
})(MediaSize = exports.MediaSize || (exports.MediaSize = {}));
var MediaType;
(function (MediaType) {
    MediaType["YOUTUBE"] = "YOUTUBE";
    MediaType["MOVIE"] = "MOVIE";
    MediaType["IMAGE"] = "IMAGE";
})(MediaType = exports.MediaType || (exports.MediaType = {}));
const talentMediaFactory = () => ({
    transcodedAt: null,
    createdAt: null,
    size: null,
    orderIndex: 0,
    type: null,
    updatedAt: null,
    url: '',
    description: '',
});
exports.talentMediaFactory = talentMediaFactory;
