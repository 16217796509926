
import { defineComponent } from 'vue'
import { Attributes, Region } from '@batteki/common'
import type { SelectItem } from '@batteki/base/src/domain/search'
import type { Ages } from '@batteki/base/src/domain/search/talents'
import SearchFormItemList from '~/components/molecules/SearchFormItemList.vue'

export default defineComponent({
  components: {
    SearchFormItemList,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'input'],
  data: () => ({}),
  computed: {
    dialog: {
      get(): boolean {
        return this.value
      },
      set(value: boolean) {
        this.$emit('input', value)
      },
    },
    attributeSelect(): (string | Attributes)[] {
      return this.$accessor.search.talents.attributeSelection
    },
    attribute(): string {
      return Attributes[this.$accessor.search.talents.attribute || 0]
    },
    residenceSelect(): (string | Region)[] {
      return this.$accessor.search.talents.residenceSelection
    },
    residence(): string {
      return Region[this.$accessor.search.talents.residence]
    },
    gender(): SelectItem[] {
      return this.$accessor.search.talents.gender
    },
    industry(): SelectItem[] {
      return this.$accessor.search.talents.industry
    },
    careers(): number {
      return this.$accessor.search.talents.careers
    },
    yearSelect(): number[] {
      return this.$accessor.search.talents.yearSelection
    },
    age(): Ages {
      return { ...this.$accessor.search.talents.talents.ages }
    },
    commitments(): SelectItem[] {
      return this.$accessor.search.talents.talents.commitments
    },
    keywords(): string {
      return this.$accessor.search.talents.talents.keywords
    },
  },
  watch: {},
  async mounted() {
    await this.$accessor.search.talents.getFacets()
  },
  methods: {
    clear() {
      this.$accessor.search.talents.clear({})
    },
    submit(): void {
      this.search()
      if (this.$route.name === 'talent-talents') {
        this.$router.push('/talent/talents')
      } else {
        this.$router.push('/client')
      }
      this.$emit('close')
    },
    async search() {
      try {
        this.$loadingState.isActive = true
        await this.$accessor.search.talents.search({})
      } finally {
        this.$loadingState.isActive = false
      }
    },
    onUpdateGender(key: string, $event: boolean): void {
      const item: SelectItem = { text: key, value: !!$event }
      console.debug('onUpdateGender', item)
      this.$accessor.search.talents.updateGender(item)
    },
    onUpdateAge(key: keyof Ages, val: number): void {
      this.$accessor.search.talents.updateAge({ key, val })
    },
    onUpdateIndustry(key: string, $event: boolean): void {
      const item: SelectItem = { text: key, value: !!$event }
      this.$accessor.search.talents.updateIndustry(item)
    },
    onUpdateAttribute($event: string): void {
      this.$accessor.search.talents.updateAttribute(
        Attributes[$event as keyof typeof Attributes]
      )
    },
    onUpdateResidence($event: string): void {
      this.$accessor.search.talents.updateResidence(
        Region[$event as keyof typeof Region]
      )
    },
    onUpdateCareers($event: number): void {
      this.$accessor.search.talents.updateCareers($event)
    },
    onUpdateKeywords($event: string): void {
      this.$accessor.search.talents.updateKeywords($event)
    },
    onUpdateCommitments(key: string, $event: boolean): void {
      const item = { text: key, value: $event }
      this.$accessor.search.talents.updateCommitments(item)
    },
  },
})
