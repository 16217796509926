import type { FirebaseOptions } from 'firebase/app'
import { getApp, getApps, initializeApp } from 'firebase/app'
import { getRemoteConfig } from 'firebase/remote-config'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import type { CollectionReference } from 'firebase/firestore'
import {
  doc,
  collection,
  connectFirestoreEmulator,
  getFirestore,
} from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getStorage } from 'firebase/storage'
import type {
  IClient,
  IEntry,
  IJob,
  IJobChangelog,
  IOffer,
  ITalent,
} from '@batteki/common'
import { COLLECTION_NAME } from '@batteki/common'

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
}

const firebaseApp = !getApps().length ? initializeApp(firebaseConfig) : getApp()

export const db = getFirestore(firebaseApp)
export const auth = getAuth(firebaseApp)
export const functions = getFunctions(firebaseApp, 'asia-northeast1')
export const storage = getStorage(firebaseApp)

export default firebaseApp

// local開発時のエミュレータ対応
if (location.host === 'localhost:3000') {
  fetch('http://localhost:8080')
    .catch(() => undefined)
    .then((localhost) => {
      if (localhost && localhost.status === 200) {
        console.log('localhost detected!')
        // auth.useEmulator('http://localhost:9099/');

        connectFirestoreEmulator(db, 'localhost', 8080)
        // storage.useEmulator('localhost', 9199);

        connectFunctionsEmulator(functions, 'localhost', 5001)
      }
    })
}

export const analytics = getAnalytics()

export const remoteConfig = getRemoteConfig()

export const bindTalentRef = collection(
  db,
  COLLECTION_NAME.talents
) as CollectionReference<ITalent>

export const bindJobRef = collection(
  db,
  COLLECTION_NAME.jobs
) as CollectionReference<IJob>

export function bindJobSubRef(id: string) {
  return doc(bindJobRef, id)
}

export function bindEntryRef(jobId: string) {
  return collection(
    bindJobSubRef(jobId),
    COLLECTION_NAME.entries
  ) as CollectionReference<IEntry>
}

export function bindOfferRef(jobId: string) {
  return collection(
    bindJobSubRef(jobId),
    COLLECTION_NAME.offers
  ) as CollectionReference<IOffer>
}

export function bindJobChangelogRef(jobId: string) {
  return collection(
    bindJobSubRef(jobId),
    COLLECTION_NAME.jobChangelogs
  ) as CollectionReference<IJobChangelog>
}

export const bindClientRef = collection(
  db,
  COLLECTION_NAME.clients
) as CollectionReference<IClient>
