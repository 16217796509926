import type {
  ITalentMedia,
  MediaSize,
  MediaType,
  CommonTimestamp,
} from '@batteki/common'
import { talentMediaFactory } from '@batteki/common'

import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
export class TalentMedia implements ITalentMedia {
  public transcodedAt!: CommonTimestamp | null
  public createdAt!: CommonTimestamp | null
  public orderIndex!: number
  public size!: MediaSize | null
  public type!: MediaType | null
  public updatedAt!: CommonTimestamp | null
  public url!: string
  public description!: string
  public id!: string

  constructor(init: ITalentMedia, id: string) {
    Object.assign(this, { id, ...init })
  }

  static initDefault() {
    return new TalentMedia(talentMediaFactory(), '')
  }
}

export const talentMediaConverter: FirestoreDataConverter<TalentMedia> = {
  toFirestore(data: TalentMedia): ITalentMedia {
    return {
      transcodedAt: data.transcodedAt,
      createdAt: data.createdAt,
      orderIndex: data.orderIndex,
      size: data.size,
      type: data.type,
      updatedAt: data.updatedAt,
      url: data.url,
      description: data.description,
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): TalentMedia {
    const data = snapshot.data() as ITalentMedia
    return new TalentMedia({ ...talentMediaFactory(), ...data }, snapshot.id)
  },
}
