"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonJobWithClientAndLiked = exports.CommonJobWithClient = exports.CommonJob = void 0;
const models_1 = require("../../models");
class CommonJob {
    constructor(init, id) {
        Object.assign(this, { ...init, id });
    }
    static initDefault() {
        return new CommonJob((0, models_1.jobFactory)(), '');
    }
    static deletedDefault() {
        return new CommonJob({ ...(0, models_1.jobFactory)(), name: '案件が見つかりません' }, '');
    }
    static toFirestore(data) {
        return {
            views: data.views,
            images: data.images,
            name: data.name,
            overview: data.overview,
            age: data.age,
            category: data.category,
            media: data.media,
            capacity: data.capacity,
            location: data.location,
            schedules: data.schedules,
            competition: data.competition,
            condition: data.condition,
            endAt: data.endAt,
            notes: data.notes,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            isPublished: data.isPublished,
            notifiedAt: data.notifiedAt,
            liked: data.liked,
            client: data.client,
            deletedAt: data.deletedAt,
            fee: data.fee,
            scheduleToClose: data.scheduleToClose,
            scheduledDate: data.scheduledDate,
            publishedAt: data.publishedAt,
            ermergencyPhone: data.ermergencyPhone,
            dispId: data.dispId,
            refedForDispIdAt: data.refedForDispIdAt,
            isOfferReady: data.isOfferReady,
            isInquiryReady: data.isInquiryReady,
            isEntryReady: data.isEntryReady,
            isEnded: data.isEnded,
            activeOffers: data.activeOffers,
            activeEntries: data.activeEntries,
        };
    }
    checkUpdateState() {
        const payload = {};
        // 問い合わせ可能
        const isInquiryReady = Boolean(!!this.images.length && // サムネイル
            !!this.name && // 案件名
            !!this.overview && // 案件内容
            (!!this.fee || this.fee === 0) // 報酬
        );
        if (this.isInquiryReady !== isInquiryReady) {
            this.isInquiryReady = isInquiryReady;
            payload.isInquiryReady = isInquiryReady;
        }
        // オファー可能
        const isOfferReady = Boolean(!!this.images.length && // サムネイル
            !!this.name && // 案件名
            !!this.overview && // 案件内容
            (!!this.fee || this.fee === 0) && // 報酬
            !!this.category && // カテゴリ
            !!this.media && // 媒体
            !!this.scheduledDate && // 実施予定日
            !!this.scheduleToClose && // 終了予定日
            !!this.location && // 実施場所
            !!this.ermergencyPhone // 緊急連絡先
        );
        if (this.isOfferReady !== isOfferReady) {
            this.isOfferReady = isOfferReady;
            payload.isOfferReady = isOfferReady;
        }
        // エントリー可能
        const isEntryReady = Boolean(!!this.images.length && // サムネイル
            !!this.name && // 案件名
            !!this.overview && // 案件内容
            (!!this.fee || this.fee === 0) && // 報酬
            !!this.category && // カテゴリ
            !!this.media && // 媒体
            !!this.scheduledDate && // 実施予定日
            !!this.scheduleToClose && // 終了予定日
            !!this.schedules && // 実施までのスケジュール
            (!!this.capacity.male || this.capacity.male === 0) && // 募集人数（男性）
            (!!this.capacity.female || this.capacity.female === 0) && // 募集人数（女性）
            (!!this.age.from || this.age.from === 0) && // 募集年齢
            (!!this.age.to || this.age.to === 0) && // 募集年齢
            !!this.condition && // 募集条件詳細
            !!this.endAt && // 募集締め切り
            !!this.ermergencyPhone // 緊急連絡先
        );
        if (this.isEntryReady !== isEntryReady) {
            this.isEntryReady = isEntryReady;
            payload.isEntryReady = isEntryReady;
        }
        return payload;
    }
}
exports.CommonJob = CommonJob;
class CommonJobWithClient extends CommonJob {
    constructor(init, client) {
        super(init, init.id);
        this.clientClass = client;
    }
}
exports.CommonJobWithClient = CommonJobWithClient;
class CommonJobWithClientAndLiked extends CommonJobWithClient {
    constructor(init, client, likedJob) {
        super(init, client);
        this.likedJob = likedJob;
    }
}
exports.CommonJobWithClientAndLiked = CommonJobWithClientAndLiked;
