import type { Middleware, Context } from '@nuxt/types'
import { getBoolean } from 'firebase/remote-config'
import {
  isTalentOnly,
  isClientOnly,
  publicRoute,
  isUnauthorizedOnly,
  emailUnverifiedRoute,
  unCertificatedClientRoute,
  isActiveTalentOnly,
  isMaintenanceOnly,
} from '../constants/pages'
import { chkIsNeedSNSAgree } from '@batteki/base/src/utils'
import { remoteConfig } from '@batteki/base/src/plugins/firebase'

const middleware: Middleware = (ctx: Context): void => {
  checkIfMaintenance(ctx)
  const isLogin = ctx.store.$accessor.auth.isLogin

  if (publicRoute(ctx.route)) return

  if (isLogin) {
    const { emailVerified } = ctx.app.$accessor.auth.auth ?? {
      emailVerified: false,
    }
    const { role, suspended } = ctx.app.$accessor.auth.claim ?? {
      role: undefined,
      suspended: false,
    }

    // アカウント非公開中の場合
    if (suspended) {
      suspendedRouting(ctx)
    }

    // メール未認証
    if (!emailVerified) {
      emailUnverifiedRouting(ctx)
    }

    // ユーザーがログインしているのにログインか登録ページにアクセスしようとする時
    else if (isUnauthorizedOnly(ctx.route)) {
      if (
        ctx.route.query.continuePath &&
        typeof ctx.route.query.continuePath === 'string'
      ) {
        ctx.redirect(ctx.route.query.continuePath)
      } else {
        ctx.redirect('/')
      }
    }
    // キャストの場合
    else if (role === 'talent') {
      talentRouting(ctx)
    }
    // クライアントの場合
    else if (role === 'client') {
      clientRouting(ctx)
    }
    // 初回ルーティング
    else {
      initialRouting(ctx)
    }
  }
  // ユーザーがログインしてないのにログインと登録ページ以外のページにアクセスしようとする時
  else if (!isUnauthorizedOnly(ctx.route) && !publicRoute(ctx.route)) {
    ctx.redirect('/login')
  }
}
export default middleware

/**
 *キャスト用のルーティング
 * @param param0
 */
export function talentRouting({ route, redirect, app, error }: Context) {
  const { talentProfile } = app.$accessor.auth
  if (!talentProfile && route.path !== '/talent/mypage/profile/edit_01/') {
    return redirect('/talent/mypage/profile/edit_01/')
  }
  if (isClientOnly(route) || route.path === '/') return redirect('/talent')

  const isSuspended = talentProfile && talentProfile.isSuspended

  // SNS登録があり、SNS利用同意書未同意の場合
  if (talentProfile && chkIsNeedSNSAgree(talentProfile)) {
    if (isSuspended) {
      if (route.name !== 'talent-mypage' || route.hash !== '#snstos') {
        return redirect({ path: '/talent/mypage/#snstos' })
      }
    } else if (route.name !== 'talent-home' || route.hash !== '#snstos') {
      return redirect({ path: '/talent/#snstos' })
    }
  }

  // キャスト非公開中判定
  if (isSuspended) {
    // HOMEをマイページに変更
    if (route.path === '/talent') {
      return redirect('/talent/mypage/')
    }

    if (!isActiveTalentOnly(route)) {
      return error({
        statusCode: 401,
        message: 'お使いのアカウントはこちらのページは表示できません。',
      })
    }
  }
}

/**
 * クライアント用のルーティング
 * @param param0
 */
export function clientRouting({ route, redirect, app, error }: Context) {
  const { clientProfile } = app.$accessor.auth

  if (!clientProfile && route.path !== '/client/mypage/company/edit') {
    return redirect('/client/mypage/company/edit')
  }
  if (!app.$accessor.auth.isClientCertificated && app.$accessor.auth.isClient) {
    console.log('client is not certificated')
    if (!unCertificatedClientRoute(route)) {
      return error({
        statusCode: 401,
        message:
          'このページは認証が完了次第表示されます。事務局より認証されるまでしばらくお待ち下さい。',
      })
    }
  }
  if (isTalentOnly(route) || route.path === '/') {
    return redirect('/client')
  }
}

/**
 * 初回ルーティング
 * @param param0
 */
export function initialRouting({ route, redirect }: Context) {
  console.log('User has not chosen type')
  if (route.path === '/talent/mypage/profile/edit_01/') {
    return
  }
  if (route.path === '/client/settings/terms/') {
    return
  }
  if (route.path === '/client/mypage/company/edit/') {
    return
  }
  if (route.path !== '/initial' && route.path !== '/initial/') {
    return redirect('/initial')
  }
}

/**
 * 休止中のアカウントの処理（アカウント休止中※キャスト非公開中とは別）
 * @param param0
 */
export function suspendedRouting({ app, redirect, $error }: Context) {
  // サインアウトして、ログインページへ遷移
  app.$accessor.auth.signOut()
  redirect('/')
  $error.message = 'このアカウントは現在休止中です。'
}

/**
 * メール未認証ルーティング
 * @param {Context} param
 */
export function emailUnverifiedRouting({ route, redirect }: Context) {
  if (!emailUnverifiedRoute(route)) {
    return redirect('/registration/email')
  } else {
    // do nothing
  }
}

/**
 * メンテナンスモードを確認
 * @param {Context} param
 */
export function checkIfMaintenance({ redirect, route }: Context) {
  const isMaintenance = getBoolean(remoteConfig, 'maintenance')
  if (isMaintenance && !isMaintenanceOnly(route)) {
    redirect({ name: 'maintenance' })
  } else if (!isMaintenance && isMaintenanceOnly(route)) {
    redirect({ name: 'index' })
  } else {
    // do nothing
  }
}
