"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNSTypeIcons = exports.SNS_TYPES = exports.SNSTypes = void 0;
exports.SNSTypes = {
    instagram: 'Instagram',
    twitter: 'X（旧Twitter）',
    youtube: 'YouTube',
    tiktok: 'TikTok',
};
exports.SNS_TYPES = Object.keys(exports.SNSTypes).reduce((result, current) => {
    result[current] = current;
    return result;
}, {});
exports.SNSTypeIcons = {
    instagram: 'mdi-instagram',
    twitter: 'twitter',
    youtube: 'mdi-youtube',
    tiktok: 'tiktok',
};
