import { getAccessorType } from 'typed-vuex'
import * as likedTalents from '@batteki/base/src/store/likedTalents'
import * as auth from '@batteki/base/src/store/auth'
import * as searchTalents from '@batteki/base/src/store/search/talents'
import * as searchJobs from '@batteki/base/src/store/search/jobs'
import * as talentsDetail from '@batteki/base/src/store/talents/detail'
import * as jobsDetail from '@batteki/base/src/store/jobs/detail'
import * as tabs from '@batteki/base/src/store/tabs'
import * as chatRoomReference from '@batteki/base/src/store/chatRoomReference'
import * as clientActionReqEntries from '@batteki/base/src/store/clients/actionReqEntries'
import * as clientActionReqOffers from '@batteki/base/src/store/clients/actionReqOffers'
import * as talentActionReqOffers from '@batteki/base/src/store/talents/actionReqOffers'
import * as banners from '@/store/banners'

export const strict = false
// This compiles to nothing and only serves to return the correct type of the accessor
export const accessorType = getAccessorType({
  modules: {
    // The key (submodule) needs to match the Nuxt namespace (e.g. ~/store/submodule.ts)
    auth: {
      ...auth,
    },
    chatRoomReference,
    talents: {
      modules: {
        detail: { ...talentsDetail },
        actionReqOffers: { ...talentActionReqOffers },
      },
    },
    likedTalents,
    jobs: {
      modules: {
        detail: {
          ...jobsDetail,
        },
      },
    },
    search: {
      modules: {
        talents: {
          ...searchTalents,
        },
        jobs: {
          ...searchJobs,
        },
      },
    },
    tabs,
    clients: {
      modules: {
        actionReqEntries: { ...clientActionReqEntries },
        actionReqOffers: { ...clientActionReqOffers },
      },
    },
    banners,
  },
})
