"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OFFER_ENTRY_COMMON_STATUS = exports.jobFactory = void 0;
function jobFactory() {
    return {
        views: 0,
        entries: 0,
        offers: 0,
        activeOffers: 0,
        activeEntries: 0,
        images: [],
        name: '',
        media: '',
        capacity: {
            female: 0,
            male: 0,
        },
        overview: '',
        age: {
            from: null,
            to: null,
        },
        category: '',
        location: '',
        schedules: '',
        competition: '',
        condition: '',
        endAt: null,
        notes: '',
        createdAt: null,
        updatedAt: null,
        isPublished: false,
        notifiedAt: null,
        liked: 0,
        client: {
            ref: null,
        },
        deletedAt: null,
        fee: 0,
        scheduleToClose: null,
        scheduledDate: null,
        publishedAt: null,
        ermergencyPhone: '',
        dispId: '',
        refedForDispIdAt: null,
        isOfferReady: false,
        isInquiryReady: false,
        isEntryReady: false,
        isEnded: false,
    };
}
exports.jobFactory = jobFactory;
exports.OFFER_ENTRY_COMMON_STATUS = {
    IN_PROGRESS: 'IN_PROGRESS',
    IS_ADMIN_APPROVED: 'IS_ADMIN_APPROVED',
    IS_ADMIN_DISAPPROVED: 'IS_ADMIN_DISAPPROVED',
    IS_REFUSED: 'IS_REFUSED',
    IS_EXPIRED: 'IS_EXPIRED',
    IS_APPROVED: 'IS_APPROVED',
    IS_CLOSED: 'IS_CLOSED',
    IS_ADMIN_CLOSED: 'IS_ADMIN_CLOSED',
};
