import "core-js/modules/es.function.name.js";
import TheNotifications from '@batteki/base/src/components/organisms/layout/TheNotifications.vue';
import TheSearchTalents from '~/components/organisms/layout/TheSearchTalents.vue';
import TheSearchJobs from '~/components/organisms/layout/TheSearchJobs.vue';
export default defineComponent({
  components: {
    TheSearchTalents: TheSearchTalents,
    TheSearchJobs: TheSearchJobs,
    TheNotifications: TheNotifications
  },
  data: function data() {
    return {
      dialog: false
    };
  },
  computed: {
    user: function user() {
      return this.$accessor.auth.userInfo;
    },
    searchType: function searchType() {
      if (this.isClientJobs) {
        return 'job';
      } else if (this.isTalentTalents) {
        return 'talent';
      } else if (this.isClient) {
        return 'talent';
      } else if (this.isTalent) {
        return 'job';
      } else {
        return null;
      }
    },
    isClient: function isClient() {
      return this.$accessor.auth.isClient;
    },
    isTalent: function isTalent() {
      return this.$accessor.auth.isTalent;
    },
    isTalentTalents: function isTalentTalents() {
      return this.$route.name === 'talent-talents';
    },
    isClientJobs: function isClientJobs() {
      return this.$route.name === 'client-jobs';
    }
  },
  mounted: function mounted() {},
  methods: {}
});