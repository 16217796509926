"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.entryFactory = exports.EntryTalentMessages = exports.EntryClientMessages = exports.EntryJobLabels = exports.EntryListLabels = exports.ClientEntryListLabels = exports.ForceEndableEntries = exports.DeactivatedEntries = exports.ClientReadableEntries = exports.ActiveEntries = exports.WholeEntries = exports.ENTRY_STATUS = exports.EntryStatuses = void 0;
const billStatus_1 = require("../constants/billStatus");
exports.EntryStatuses = {
    IN_PROGRESS: 'リクエスト承認待ち',
    IS_ADMIN_APPROVED: 'エントリー中',
    IS_ADMIN_DISAPPROVED: '運営事務局否認',
    IS_DECIDING: '選考中',
    IS_REFUSED: '不成立',
    IS_EXPIRED: '期限切れ（不成立）',
    IS_APPROVED: '案件成立中',
    IS_CLOSED: '案件終了',
    IS_ADMIN_CLOSED: '案件終了（強制）', // 管理側による案件終了
};
exports.ENTRY_STATUS = Object.keys(exports.EntryStatuses).reduce((result, current) => {
    result[current] = current;
    return result;
}, {});
exports.WholeEntries = [
    exports.ENTRY_STATUS.IN_PROGRESS,
    exports.ENTRY_STATUS.IS_ADMIN_APPROVED,
    exports.ENTRY_STATUS.IS_ADMIN_DISAPPROVED,
    exports.ENTRY_STATUS.IS_DECIDING,
    exports.ENTRY_STATUS.IS_REFUSED,
    exports.ENTRY_STATUS.IS_EXPIRED,
    exports.ENTRY_STATUS.IS_APPROVED,
    exports.ENTRY_STATUS.IS_CLOSED,
    exports.ENTRY_STATUS.IS_ADMIN_CLOSED,
];
exports.ActiveEntries = [
    exports.ENTRY_STATUS.IN_PROGRESS,
    exports.ENTRY_STATUS.IS_ADMIN_APPROVED,
    exports.ENTRY_STATUS.IS_DECIDING,
    exports.ENTRY_STATUS.IS_APPROVED,
];
exports.ClientReadableEntries = [
    exports.ENTRY_STATUS.IS_ADMIN_APPROVED,
    exports.ENTRY_STATUS.IS_DECIDING,
    exports.ENTRY_STATUS.IS_REFUSED,
    exports.ENTRY_STATUS.IS_EXPIRED,
    exports.ENTRY_STATUS.IS_APPROVED,
    exports.ENTRY_STATUS.IS_CLOSED,
    exports.ENTRY_STATUS.IS_ADMIN_CLOSED,
];
exports.DeactivatedEntries = exports.WholeEntries.filter((el) => !exports.ActiveEntries.includes(el));
exports.ForceEndableEntries = exports.ActiveEntries.filter((el) => ![exports.ENTRY_STATUS.IS_APPROVED].includes(el));
/**
 * ステータス遷移
 * IN_PROGRESS → IS_ADMIN_APPROVED   → IS_DECIDING → IS_APPROVED → IS_CLOSED
 *             ↓                     ↓             ↓             ↓
 *             IS_ADMIN_DISAPPROVED  IS_REFUSED    IS_REFUSED    IS_ADMIN_CLOSED
 */
// リスト表示ラベル
exports.ClientEntryListLabels = {
    IN_PROGRESS: '',
    IS_ADMIN_APPROVED: '未選考',
    IS_ADMIN_DISAPPROVED: '不成立',
    IS_DECIDING: '選考中',
    IS_REFUSED: '不成立',
    IS_EXPIRED: '不成立',
    IS_APPROVED: '成立',
    IS_CLOSED: '終了',
    IS_ADMIN_CLOSED: '終了',
};
// 表示ラベル
exports.EntryListLabels = {
    IN_PROGRESS: '',
    IS_ADMIN_APPROVED: '',
    IS_ADMIN_DISAPPROVED: '',
    IS_DECIDING: '',
    IS_REFUSED: '不成立',
    IS_EXPIRED: '不成立',
    IS_APPROVED: '確定済み',
    IS_CLOSED: '確定済み',
    IS_ADMIN_CLOSED: '確定済み',
};
// キャスト向け案件表示時ラベル
exports.EntryJobLabels = {
    IN_PROGRESS: 'エントリー済み',
    IS_ADMIN_APPROVED: 'エントリー済み',
    IS_ADMIN_DISAPPROVED: 'キャンセルされました',
    IS_DECIDING: 'エントリー済み',
    IS_REFUSED: 'キャンセルされました',
    IS_EXPIRED: 'キャンセルされました',
    IS_APPROVED: '確定済み',
    IS_CLOSED: 'エントリー済み',
    IS_ADMIN_CLOSED: 'エントリー済み',
};
// クライアント向け詳細メッセージ
exports.EntryClientMessages = {
    IN_PROGRESS: '',
    IS_ADMIN_APPROVED: 'キャストからエントリーがありました。回答ください。',
    IS_ADMIN_DISAPPROVED: '',
    IS_DECIDING: '選考中です。エントリーに回答ください。',
    IS_REFUSED: 'このエントリーは不成立です。',
    IS_EXPIRED: '回答期限が過ぎたためキャンセルされました。',
    IS_APPROVED: 'このエントリーは成立しました。',
    IS_CLOSED: 'この取引は終了しました。',
    IS_ADMIN_CLOSED: 'この取引は終了しました。',
};
// キャスト向け詳細メッセージ
exports.EntryTalentMessages = {
    IN_PROGRESS: '運営事務局が承認中です。',
    IS_ADMIN_APPROVED: 'クライアントからの回答待ちです。',
    IS_ADMIN_DISAPPROVED: 'このエントリーは事務所判断により許可されませんでした。',
    IS_DECIDING: 'クライアントからの回答待ちです。',
    IS_REFUSED: 'このエントリーは成立しませんでした。',
    IS_EXPIRED: '回答期限が過ぎたためキャンセルされました。',
    IS_APPROVED: 'エントリーが成立しました。',
    IS_CLOSED: 'この取引は終了しました。',
    IS_ADMIN_CLOSED: 'この取引は終了しました。',
};
function entryFactory() {
    return {
        status: exports.ENTRY_STATUS.IN_PROGRESS,
        job: {
            ref: null,
            client: {
                ref: null,
            },
        },
        talent: {
            ref: null,
        },
        chatRoom: {
            ref: null,
        },
        reason: '',
        approvedAt: null,
        pr: '',
        refusedAt: null,
        createdAt: null,
        updatedAt: null,
        expiredAt: null,
        remindedApproveAt: null,
        fee: 0,
        feeChengeLog: { ref: null },
        scheduleToClose: null,
        closedAt: null,
        billStatus: billStatus_1.BILL_STATUS.UNCLAIMED,
        billApplyedAt: null,
        billIgnoredAt: null,
        isBill: false,
        billId: '',
        adminApprovedAt: null,
        adminDisapprovedAt: null,
        startDecidingAt: null,
        schedule: '',
        adminCancelAt: null,
        adminCancelReason: '',
        adminCancelBy: null,
    };
}
exports.entryFactory = entryFactory;
