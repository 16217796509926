
import { defineComponent } from 'vue'
import BaseLoading from '@batteki/base/src/components/molecules/BaseLoading.vue'
export default defineComponent({
  components: {
    BaseLoading,
  },
  computed: {
    isLoading() {
      return this.$loadingState.isActive
    },
  },
  mounted() {
    this.$loadingState.isActive = false
  },
})
