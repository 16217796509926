export default defineComponent({
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  setup: function setup(props) {
    var title = ref('');
    var otherError = 'エラーが発生しました';
    console.log(props.error);
    switch (props.error.statusCode) {
      case 401:
        title.value = '401 Unauthorized';
        break;
      case 403:
        title.value = '403 Forbidden';
        break;
      case 404:
        title.value = '404 Page is not found';
        break;
      default:
        title.value = otherError;
    }
    watch(function () {
      return title.value;
    }, function (title) {
      useHead({
        title: title
      });
    }, {
      immediate: true
    });
    return {
      title: title,
      otherError: otherError
    };
  }
});