"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Attributes = void 0;
var Attributes;
(function (Attributes) {
    Attributes[Attributes["\u672A\u9078\u629E"] = 0] = "\u672A\u9078\u629E";
    Attributes[Attributes["\u30D9\u30D3\u30FC"] = 1] = "\u30D9\u30D3\u30FC";
    Attributes[Attributes["\u5E7C\u5150"] = 2] = "\u5E7C\u5150";
    Attributes[Attributes["\u5C0F\u5B66\u6821\u4F4E\u5B66\u5E74"] = 3] = "\u5C0F\u5B66\u6821\u4F4E\u5B66\u5E74";
    Attributes[Attributes["\u5C0F\u5B66\u6821\u9AD8\u5B66\u5E74"] = 4] = "\u5C0F\u5B66\u6821\u9AD8\u5B66\u5E74";
    Attributes[Attributes["\u4E2D\u5B66\u751F"] = 5] = "\u4E2D\u5B66\u751F";
    Attributes[Attributes["10\u4EE3"] = 6] = "10\u4EE3";
    Attributes[Attributes["20\u4EE3"] = 7] = "20\u4EE3";
    Attributes[Attributes["30\u4EE3"] = 8] = "30\u4EE3";
    Attributes[Attributes["40\u4EE3"] = 9] = "40\u4EE3";
    Attributes[Attributes["50\u4EE3"] = 10] = "50\u4EE3";
    Attributes[Attributes["60\u4EE3"] = 11] = "60\u4EE3";
    Attributes[Attributes["70\u4EE3"] = 12] = "70\u4EE3";
    Attributes[Attributes["80\u4EE3\u4EE5\u4E0A"] = 13] = "80\u4EE3\u4EE5\u4E0A";
})(Attributes = exports.Attributes || (exports.Attributes = {}));
