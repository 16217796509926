"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jobChangeHistoryFactory = exports.JobChangeHistoryTypes = void 0;
exports.JobChangeHistoryTypes = {
    CREATE: '新規作成',
    UPDATE: '更新',
    REQ_PUBLISH: '公開申請',
    DELETE: '削除',
    UNPUBLISH: '非公開',
    REQ_UPDATE: '更新申請',
    APPROVE: '承認',
    DISAPPROVE: '否認',
};
function jobChangeHistoryFactory() {
    return {
        type: 'CREATE',
        isByAdmin: false,
        reason: '',
        createdAt: null,
        updatedAt: null,
    };
}
exports.jobChangeHistoryFactory = jobChangeHistoryFactory;
