import type { valueof } from '@batteki/common'
import { Attributes } from '@batteki/common'
import dayjs from '@batteki/base/src/domain/util/dayjs'
import { BABY_FILTER } from '@batteki/base/src/domain/search/talents'

/**
 * attributeからFilterを生成
 * @param attribute
 * @return {string}
 */
export function filterHandler(
  attribute: valueof<typeof Attributes> | null
): string {
  const now = dayjs()
  const today = dayjs(now.format('YYYY-MM-DD'))
  switch (attribute) {
    case null:
      console.info('attributeはnullです')
      return ''
    case Attributes.ベビー: {
      const from = today.subtract(BABY_FILTER, 'year')
      const to = today
      return generateAttributeFilter(to, from)
    } // （0歳から3歳未満）

    case Attributes.幼児: {
      const fromYear = today.subtract(3, 'month').subtract(6, 'year').year()
      const from = dayjs(`${fromYear}-04-01`)
      const to = today.subtract(BABY_FILTER, 'year')
      return generateAttributeFilter(to, from)
    } // （3.6歳から幼稚園まで）{}

    case Attributes.小学校低学年: {
      const fromYear = now.subtract(3, 'month').subtract(9, 'year').year()
      const from = dayjs(`${fromYear}-04-01`)
      const toYear = now.subtract(3, 'month').subtract(6, 'year').year()
      const to = dayjs(`${toYear}-04-01`)
      return generateAttributeFilter(to, from)
    }

    case Attributes.小学校高学年: {
      const fromYear = now.subtract(3, 'month').subtract(12, 'year').year()
      const from = dayjs(`${fromYear}-04-01`)
      const toYear = now.subtract(3, 'month').subtract(9, 'year').year()
      const to = dayjs(`${toYear}-04-01`)
      return generateAttributeFilter(to, from)
    }

    case Attributes.中学生: {
      const fromYear = now.subtract(3, 'month').subtract(15, 'year').year()
      const from = dayjs(`${fromYear}-04-01`)
      const toYear = now.subtract(3, 'month').subtract(12, 'year').year()
      const to = dayjs(`${toYear}-04-01`)
      return generateAttributeFilter(to, from)
    }

    case Attributes['10代']: {
      const from = dayjs(now.subtract(20, 'year').format('YYYY-MM-DD'))
      const toYear = now.subtract(3, 'month').subtract(15, 'year').year()
      const to = dayjs(`${toYear}-04-01`)
      return generateAttributeFilter(to, from)
    }

    case Attributes['20代']: {
      return ageFilter(now, 20, 30)
    }

    case Attributes['30代']: {
      return ageFilter(now, 30, 40)
    }

    case Attributes['40代']: {
      return ageFilter(now, 40, 50)
    }

    case Attributes['50代']: {
      return ageFilter(now, 50, 60)
    }

    case Attributes['60代']: {
      return ageFilter(now, 60, 70)
    }

    case Attributes['70代']: {
      return ageFilter(now, 70, 80)
    }

    case Attributes['80代以上']: {
      return ageFilter(now, 80)
    }

    default: {
      return ''
    }
  }
}

/**
 * Algoliaのquery用のfilterを返す
 * @param {dayjs.Dayjs} from
 * @param {dayjs.Dayjs} to
 */
const generateAttributeFilter = (
  to: dayjs.Dayjs,
  from: dayjs.Dayjs | null = null
): string => {
  if (!from) {
    return `birthday <= ${to.valueOf()}`
  } else {
    console.info(
      'from:',
      from.format('YYYY-MM-DD:HH:mm'),
      'to',
      to.format('YYYY-MM-DD:HH:mm')
    )
    return `birthday > ${from.valueOf()} AND birthday <= ${to.valueOf()}`
  }
}

/**
 * 年齢からFilter生成
 * @param {dayjs.Dayjs} now
 * @param {number} fromAge
 * @param {number} toAge
 */
const ageFilter = (
  now: dayjs.Dayjs,
  fromAge: number,
  toAge: number | null = null
) => {
  const toYear = now.subtract(fromAge, 'year')
  const to = dayjs(`${toYear.format('YYYY-MM-DD')}`)
  // toAgeがなければfromAge以降をすべて表示
  if (!toAge) return generateAttributeFilter(to)
  const from = dayjs(now.subtract(toAge, 'year').format('YYYY-MM-DD'))
  return generateAttributeFilter(to, from)
}
