"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MIME_TYPE = void 0;
exports.MIME_TYPE = {
    'video/mpeg': 'mpg',
    'video/mp4': 'mp4',
    'video/webm': 'webm',
    'video/ogg': 'ogv',
    'video/quicktime': 'mov',
    'video/x-msvideo': 'avi',
};
