"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLLECTION_NAME = void 0;
exports.COLLECTION_NAME = {
    chatRooms: 'chatRooms',
    reviews: 'reviews',
    messages: 'messages',
    clients: 'clients',
    industryMaster: 'industryMaster',
    jobCategoryMaster: 'jobCategoryMaster',
    jobs: 'jobs',
    mediaMaster: 'mediaMaster',
    news: 'news',
    students: 'students',
    talents: 'talents',
    users: 'users',
    chatRoomReferences: 'chatRoomReferences',
    likedTalents: 'likedTalents',
    likedJobs: 'likedJobs',
    notifications: 'notifications',
    offers: 'offers',
    entries: 'entries',
    feeChangelogs: 'feeChangelogs',
    jobChangelogs: 'jobChangelogs',
    media: 'media',
    dairySummaries: 'dairySummaries',
    jobChangeHistories: 'jobChangeHistories',
    destinations: 'destinations',
};
