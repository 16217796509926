import type { CommonTimestamp, IUser, Role } from '@batteki/common'
import { userFactory, chkBillEmail } from '@batteki/common'

import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
export class User implements IUser {
  public id!: string
  public createdAt!: CommonTimestamp | null
  public email!: string
  public updatedAt!: CommonTimestamp | null
  public deletedAt!: CommonTimestamp | null
  public suspended!: boolean
  public role!: Role | null
  public isAdmin!: boolean
  public isClientCertificated!: boolean
  public lastNewsOpenedAt!: CommonTimestamp | null
  public destinationEmail!: string

  constructor(init: IUser, id: string) {
    Object.assign(this, { id, ...init })
  }

  static initDefault() {
    return new User(userFactory(), '')
  }

  public chkBillEmail(): boolean {
    return chkBillEmail(this.destinationEmail)
  }
}

export const userConverter: FirestoreDataConverter<User> = {
  toFirestore(data: User): IUser {
    return {
      createdAt: data.createdAt,
      email: data.email,
      updatedAt: data.updatedAt,
      deletedAt: data.deletedAt,
      suspended: data.suspended,
      role: data.role,
      isAdmin: data.isAdmin,
      isClientCertificated: data.isClientCertificated,
      lastNewsOpenedAt: data.lastNewsOpenedAt,
      destinationEmail: data.destinationEmail,
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): User {
    const data = snapshot.data() as IUser
    return new User(data, snapshot.id)
  },
}
