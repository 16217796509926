import type { IChatRoomReference } from '@batteki/common'
import {
  CommonChatRoomWithJobAndPartner,
  CommonChatRoomReference,
  chatRoomReferenceFactory,
} from '@batteki/common'

import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
import { UserCommonRepository } from '../users/userCommonRepository'
import { JobRepo } from '../repository'

export class ChatRoomWithJobAndPartner extends CommonChatRoomWithJobAndPartner {}
export class ChatRoomReference extends CommonChatRoomReference {
  public setId(id: string): void {
    this.id = id
  }

  public async convertToChatRoomWithJobAndPartner(
    uid: string
  ): Promise<ChatRoomWithJobAndPartner | undefined> {
    if (!this.job.ref) return
    const partnerRef = this.findAnotherProfile(uid)
    if (!partnerRef) return
    const userCommonRepository = new UserCommonRepository()
    const partner = await userCommonRepository.get(partnerRef)
    const jobRepo = new JobRepo()
    const job = await jobRepo.getByRef(this.job.ref)
    if (!job || !partner) return
    return new ChatRoomWithJobAndPartner(this, job, partner)
  }

  // private findTargetProfile(uid: string) {
  //   const find = this.members.find((member) => member.id === uid)
  //   return find
  // }

  private findAnotherProfile(uid: string) {
    const find = this.members.find((member) => member.id !== uid)
    return find
  }
}
export const chatRoomReferenceConverter: FirestoreDataConverter<ChatRoomReference> =
  {
    toFirestore(data: ChatRoomReference): IChatRoomReference {
      return ChatRoomReference.toFirestore(data)
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): ChatRoomReference {
      const data = snapshot.data() as IChatRoomReference
      return new ChatRoomReference(
        { ...chatRoomReferenceFactory(), ...data },
        snapshot.id
      )
    },
  }
