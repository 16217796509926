// import {TalentsRepository} from '../talents/repository';
// import {JobRepo} from '../jobs/repository';

import { writeBatch } from 'firebase/firestore'
import type { LikedJob } from '../likedJob/liked-job.class'
import type { LikedTalent } from '../likedTalent/liked-talent.class'
import { UserRepo } from './repository'
import { db } from '@batteki/base/src/plugins/firebase'
class UserService {
  /**
   * 戻り値はtoggle後のlikedの状態
   */
  public async toggleTalentLike(
    clientid: string,
    item: LikedTalent,
    talentId: string
  ): Promise<boolean> {
    const userRepo = new UserRepo()
    const findOne = await userRepo.findOneLIkedTalent(clientid, talentId)
    if (!findOne) {
      await this.addTalentLike(clientid, item, talentId)
    } else {
      await this.removeTalentLike(clientid, talentId)
    }
    return !findOne
  }

  /**
   * キャストをお気に入りから削除
   * @param clientid
   * @param talentId
   */
  public async addTalentLike(
    clientid: string,
    item: LikedTalent,
    talentId: string
  ) {
    const userRepo = new UserRepo()
    const batch = writeBatch(db)
    userRepo.batchSetLikedTalent(batch, clientid, item, talentId)
    // キャストdocumentのlikedをインクリメント
    await batch.commit()
  }

  /**
   * キャストをお気に入りから削除
   * @param firestore
   * @param clientid
   * @param talentId
   */
  public async removeTalentLike(clientid: string, talentId: string) {
    const userRepo = new UserRepo()
    const batch = writeBatch(db)
    userRepo.batchDeleteLikedTalent(batch, clientid, talentId)
    // キャストdocumentのlikedをディクリメント
    await batch.commit()
  }

  /**
   * 案件likeしていたらremove していなかったらadd
   * @param firestore
   * @param talentId
   * @param item
   * @param jobId
   */
  public async toggleJobLike(talentId: string, item: LikedJob, jobId: string) {
    const userRepo = new UserRepo()
    const findOne = await userRepo.findOneLIkedJob(talentId, jobId)
    if (!findOne) {
      await this.addJobLike(talentId, item, jobId)
    } else {
      await this.removeJobLike(talentId, jobId)
    }
  }

  public async addJobLike(talentId: string, item: LikedJob, jobId: string) {
    const userRepo = new UserRepo()
    const batch = writeBatch(db)
    userRepo.batchSetLikedJob(batch, talentId, item, jobId)
    await batch.commit()
  }

  public async removeJobLike(talentId: string, jobId: string) {
    const userRepo = new UserRepo()
    const batch = writeBatch(db)
    userRepo.batchDeleteLikedJob(batch, talentId, jobId)
    await batch.commit()
  }
}
export const userService = new UserService()
