import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import type {
  CommonDocumentReference,
  CommonTimestamp,
  ITalent,
  ITalentListItem,
} from '@batteki/common'
import type { LikedTalent } from '@batteki/base/src/domain/likedTalent/liked-talent.class'
import type { Talent } from '@batteki/base/src/domain/talents/talent.class'
import { UserRepo } from '@batteki/base/src/domain/users/repository'

export class TalentListItem implements ITalentListItem {
  public talent: {
    ref: CommonDocumentReference<ITalent> | null
    name: string
    thumbnail: string
    birthday: CommonTimestamp | null
    deletedAt: CommonTimestamp | null
  }

  public favorite: boolean

  public createdAt: CommonTimestamp
  public updatedAt: CommonTimestamp
  public id!: string

  constructor(init: LikedTalent, talent: Talent, favorite: boolean) {
    this.createdAt = init.createdAt
    this.updatedAt = init.updatedAt
    this.talent = {
      ref: init.talent.ref,
      name: talent.name,
      birthday: talent.birthday,
      thumbnail: talent.thumbnail,
      deletedAt: talent.deletedAt,
    }
    this.favorite = favorite
  }
}

export interface AuthState {
  likedTalents: TalentListItem[]
  hasMore: boolean
}

export const state = (): AuthState => ({
  likedTalents: [],
  hasMore: false,
})

export const getters = getterTree(state, {
  likedTalents: (state) => state.likedTalents,
})

export const mutations = mutationTree(state, {
  addLikedTalentsMutation(state, likedTalents: TalentListItem[]) {
    state.likedTalents = [...state.likedTalents, ...likedTalents]
  },
  setLikedTalentsMutation(state, likedTalents: TalentListItem[]) {
    state.likedTalents = likedTalents
  },
  setHasMoreMutation(state, hasMore: boolean) {
    state.hasMore = hasMore
  },
  removeLikeTalentMutation(state, uid: string) {
    const idx = state.likedTalents.findIndex((v) => v.id === uid)
    state.likedTalents.splice(idx, 1)
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    async fetchLikedTalents({ commit }, uid: string) {
      const repo = new UserRepo()
      const { talents: likedTalents, hasMore } = await repo.getLikedTalents(uid)
      commit('setLikedTalentsMutation', likedTalents)
      commit('setHasMoreMutation', hasMore)
    },
    removeTalentLike({ commit }, uid: string) {
      commit('removeLikeTalentMutation', uid)
    },
    async fetchMoreLikedTalents({ commit, state }, uid: string) {
      const repo = new UserRepo()
      const last = state.likedTalents[state.likedTalents.length - 1]
      if (last) {
        const { talents: likedTalents, hasMore } = await repo.getLikedTalents(
          uid,
          last.createdAt!
        )
        commit('addLikedTalentsMutation', likedTalents)
        commit('setHasMoreMutation', hasMore)
      } else {
        const { talents: likedTalents, hasMore } = await repo.getLikedTalents(
          uid
        )
        commit('setLikedTalentsMutation', likedTalents)
        commit('setHasMoreMutation', hasMore)
      }
    },
  }
)
