var axiosPlugins = function axiosPlugins(ctx, inject) {
  var $axios = ctx.$axios;
  $axios.onRequest(function (config) {
    console.log('Making request to ' + config.url);
  });
  $axios.onError(function (error) {
    console.log(error);
  });

  // Create Google Map Api instance
  var googleMapApi = $axios.create();
  googleMapApi.setBaseURL('https://maps.googleapis.com/maps/api/geocode');
  inject('googleMapApi', googleMapApi);
  ctx.$googleMapApi = googleMapApi;
};
export default axiosPlugins;