import type {
  CommonTimestamp,
  IReviewWithFromProfile,
  IReviewWithToProfile,
} from '@batteki/common'
import { COLLECTION_NAME } from '@batteki/common'
import type {
  DocumentReference,
  Query,
  QueryConstraint,
} from 'firebase/firestore'
import {
  collection,
  collectionGroup,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore'
import { apiAdapter } from '../adapter'
import { userCommonConverter } from '../users/userCommon.class'
import type { Job } from '../jobs/job.class'
import { jobConverter } from '../jobs/job.class'
import { useCollectionData } from '../common/useCollection'
import type { Review } from './review.class'
import { reviewConverter } from './review.class'
import { db } from '@batteki/base/src/plugins/firebase'
export class ReviewsRepo {
  private query: Query
  constructor() {
    this.query = collectionGroup(db, COLLECTION_NAME.reviews)
  }

  /**
   * Reviewsを取得
   * @param revieweeUid
   */
  async getReviewsByScore(
    revieweeRef: DocumentReference,
    { score }: { score: number }
  ): Promise<IReviewWithFromProfile[]> {
    const reviews = await apiAdapter.getByQuery<Review>(
      query(
        this.query,
        where('to.ref', '==', revieweeRef),
        where('status', 'in', ['IS_PUBLISHED']),
        where('score', '==', score)
      ),
      reviewConverter
    )

    const promises = reviews.map(async (review) => {
      const [from, job] = await Promise.all([
        apiAdapter.getByRef(
          review.from.ref as DocumentReference,
          userCommonConverter
        ),
        apiAdapter.getByRef<Job>(review.job.ref, jobConverter),
      ])
      if (!job) return null

      return review.convertToReviewWithFromProfile(from, job)
    })
    const results = await Promise.all(promises)
    return results.filter((el): el is IReviewWithFromProfile => !!el)
  }

  /**
   * Reviewsを取得
   * @param revieweeUid
   */
  async getReviewsAndHasMoreByScore(
    revieweeRef: DocumentReference,
    { score }: { score: number },
    lastVisible: CommonTimestamp | undefined = undefined,
    limits = 12
  ): Promise<{ reviews: IReviewWithFromProfile[]; hasMore: boolean }> {
    let hasMore = false

    const queryConstraints: QueryConstraint[] = [
      where('to.ref', '==', revieweeRef),
      where('status', 'in', ['IS_PUBLISHED']),
      where('score', '==', score),
      orderBy('createdAt', 'desc'),
      limit(limits + 1),
    ]
    if (lastVisible) {
      queryConstraints.push(startAfter(lastVisible))
    }
    const reviews = await apiAdapter.getByQuery<Review>(
      query(this.query, ...queryConstraints),
      reviewConverter
    )

    if (reviews.length === limits + 1) {
      hasMore = true
      reviews.pop()
    }

    const promises = reviews.map(async (review) => {
      const [from, job] = await Promise.all([
        apiAdapter.getByRef(
          review.from.ref as DocumentReference,
          userCommonConverter
        ),
        apiAdapter.getByRef<Job>(review.job.ref, jobConverter),
      ])
      if (!job) return null

      return review.convertToReviewWithFromProfile(from, job)
    })
    const results = await Promise.all(promises)

    return {
      reviews: results.filter((el): el is IReviewWithFromProfile => !!el),
      hasMore,
    }
  }

  /**
   * Reviewsを取得
   * @param revieweeUid
   */
  async getReviewsAndHasMore(
    revieweeRef: DocumentReference,
    lastVisible: CommonTimestamp | undefined = undefined,
    limits = 12
  ): Promise<{ reviews: IReviewWithFromProfile[]; hasMore: boolean }> {
    let hasMore = false

    const queryConstraints: QueryConstraint[] = [
      where('to.ref', '==', revieweeRef),
      where('status', 'in', ['IS_PUBLISHED']),
      orderBy('updatedAt', 'desc'),
      limit(limits + 1),
    ]
    if (lastVisible) {
      queryConstraints.push(startAfter(lastVisible))
    }
    const reviews = await apiAdapter.getByQuery<Review>(
      query(this.query, ...queryConstraints),
      reviewConverter
    )

    if (reviews.length === limits + 1) {
      hasMore = true
      reviews.pop()
    }

    const promises = reviews.map(async (review) => {
      const [from, job] = await Promise.all([
        apiAdapter.getByRef(
          review.from.ref as DocumentReference,
          userCommonConverter
        ),
        apiAdapter.getByRef<Job>(review.job.ref, jobConverter),
      ])
      if (!job) return null

      return review.convertToReviewWithFromProfile(from, job)
    })
    const results = await Promise.all(promises)

    return {
      reviews: results.filter((el): el is IReviewWithFromProfile => !!el),
      hasMore,
    }
  }

  /**
   * Reviewsを取得
   * @param reviewerRef
   */
  async getReviewTasks(
    reviewerRef: DocumentReference,
    lastVisible: IReviewWithToProfile | undefined = undefined,
    limits = 12
  ): Promise<{ reviews: IReviewWithToProfile[]; hasMore: boolean }> {
    let hasMore = false

    const ref = collection(reviewerRef, COLLECTION_NAME.reviews)

    const queryConstraints: QueryConstraint[] = [
      where('status', 'in', ['IS_WAITING']),
      orderBy('createdAt', 'desc'),
      limit(limits + 1),
    ]

    if (lastVisible) {
      queryConstraints.push(startAfter(lastVisible.createdAt))
    }

    const reviews = await apiAdapter.getByQuery<Review>(
      query(ref, ...queryConstraints),
      reviewConverter
    )

    if (reviews.length === limits + 1) {
      hasMore = true
      reviews.pop()
    }

    const promises = reviews.map(async (review) => {
      const to = await apiAdapter.getByRef(
        review.to.ref! as DocumentReference,
        userCommonConverter
      )
      const job = await apiAdapter.getByRef<Job>(review.job.ref, jobConverter)
      return review.convertToReviewWithToProfile(to, job)
    })

    const reviewsWithProfile = await Promise.all(promises)

    return {
      reviews: reviewsWithProfile,
      hasMore,
    }
  }

  useClientReviewTasks(reviewerRef: DocumentReference) {
    const ref = collection(reviewerRef, COLLECTION_NAME.reviews)
    const queryConstraints: QueryConstraint[] = [
      where('status', 'in', ['IS_WAITING']),
      orderBy('createdAt', 'desc'),
    ]

    const listState = useCollectionData(() => query(ref, ...queryConstraints), {
      dataConverter: reviewConverter,
    })

    console.log(`listState.values`, listState.values)

    return {
      items: listState.values,
      loading: listState.loading,
    }
  }
}
