import type { IMediaMaster, CommonTimestamp } from '@batteki/common'
import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
export class MediaMaster implements IMediaMaster {
  public name!: string
  public orderIndex!: number
  public createdAt!: CommonTimestamp
  public updatedAt!: CommonTimestamp
  public id!: string

  constructor(init: IMediaMaster, id: string) {
    Object.assign(this, { id, ...init })
  }
}

export const mediaMasterConverter: FirestoreDataConverter<MediaMaster> = {
  toFirestore(data: MediaMaster): IMediaMaster {
    return {
      name: data.name,
      orderIndex: data.orderIndex,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): MediaMaster {
    const data = snapshot.data() as IMediaMaster
    return new MediaMaster(data, snapshot.id)
  },
}
