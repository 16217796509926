import type { DocumentData } from '@google-cloud/firestore'
import type { IClient, ITalent } from '@batteki/common'
import { CommonUserCommon } from '@batteki/common'
import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'

export class UserCommon extends CommonUserCommon {}

export const userCommonConverter: FirestoreDataConverter<UserCommon> = {
  toFirestore(data: UserCommon): DocumentData {
    return CommonUserCommon.toFirestore(data)
  },

  fromFirestore(snapshot: QueryDocumentSnapshot): UserCommon {
    const data = snapshot.data() as IClient | ITalent
    const role =
      snapshot.ref.parent.path === 'clients'
        ? 'client'
        : snapshot.ref.parent.path === 'talents'
        ? 'talent'
        : null
    return new UserCommon(data, snapshot.id, role)
  },
}
