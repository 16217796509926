
import { defineComponent } from 'vue'
export default defineComponent({
  data: () => ({}),
  computed: {
    errorMessage() {
      return this.$error.message
    },
    snackbar: {
      get() {
        return !!this.$error.message
      },
      set() {
        this.$error.message = ''
      },
    },
  },
  methods: {
    resetErrorMessage() {
      this.$error.message = ''
    },
  },
})
