import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import type { AlgoliaTalent, AlgoliaTalentWithLiked } from '@batteki/common'
import { Attributes, Gender, Region, prefectureList } from '@batteki/common'
import dayjs from 'dayjs'
import type { Hit, SearchResponse } from '@algolia/client-search'
import { IndustryMasterRepo } from '../../domain/industry-master/repository'
import { filterHandler } from '@batteki/base/src/utils/attrFilterHelper'
import { UserRepo } from '@batteki/base/src/domain/users/repository'
import type {
  Ages,
  TalentsCondition,
} from '@batteki/base/src/domain/search/talents'
import {
  initTalentsCondition,
  BABY_FILTER,
} from '@batteki/base/src/domain/search/talents'
import type { SelectItem } from '@batteki/base/src/domain/search/index'
import { initResult } from '@batteki/base/src/domain/algolia/algolia'
import type { TalentSort } from '@batteki/base/src/plugins/algolia'
import { algoliasearchTalents } from '@batteki/base/src/plugins/algolia'
// import {SearchTalentCondition, initSearchTalentCondition} from '@batteki/base/src/domain/algolia/talents';

interface LSearchResponse<T> extends Omit<SearchResponse<T>, 'hits'> {
  hits: T[]
}

export interface SearchState {
  talents: TalentsCondition
  isDefaultAge: boolean
  searchResult: LSearchResponse<AlgoliaTalentWithLiked>
  selected: string
  sort: TalentSort | null
}
export const state = (): SearchState => ({
  talents: initTalentsCondition(),
  isDefaultAge: true,
  searchResult: initResult(),
  selected: '',
  sort: null,
})

export const getters = getterTree(state, {
  talents: (state) => state.talents,
  isDefaultAgeState: (state) => state.isDefaultAge,
  gender: (state) => state.talents.gender,
  industry: (state) => state.talents.industry,
  attribute: (state) => state.talents.attribute,
  residence: (state) => state.talents.residence,
  careers: (state) => state.talents.careers,
  attributeSelection: () => {
    const values = Object.values(Attributes).filter(
      (val) => typeof val !== 'number'
    )
    return values
  },
  residenceSelection: () => {
    const values = Object.values(Region).filter(
      (val) => typeof val !== 'number'
    )
    return values
  },
  yearSelection: () => {
    return [...Array(101).keys()].map((i) => i++)
  },
  searchResult: (state) => state.searchResult,
  hasMore(state): boolean {
    return state.searchResult.nbPages > state.searchResult.page + 1
  },

  /**
   * ALL Filter
   */
  filters(
    _state,
    {
      genderFilter,
      prefecturesFilter,
      attributesFilter,
      industryFilter,
      ageFilters,
    }
  ): string {
    const filters = [
      genderFilter,
      prefecturesFilter,
      attributesFilter,
      industryFilter,
      ageFilters,
    ]
      .filter((val) => val)
      .join(' AND ')
    return filters
  },

  /**
   * Age Filter
   * @param {SearchState} state
   * @return {string}
   */
  ageFilters(state: SearchState): string {
    const { from, to } = state.talents.ages
    let fromMs: number | null = null
    let toMs: number | null = null
    if (from !== null && to === null) {
      fromMs = dayjs().subtract(from!, 'year').valueOf()
      return `birthday <= ${fromMs}`
    } else if (from === null && to !== null) {
      toMs = dayjs().subtract(to!, 'year').valueOf()
      return `birthday >= ${toMs}`
    } else if (from === null && to === null) {
      return ''
    } else {
      fromMs = dayjs().subtract(from!, 'year').valueOf()
      toMs = dayjs()
        .subtract(to! + 1, 'year')
        .valueOf()
      return `birthday > ${toMs} AND  birthday <= ${fromMs}`
    }
  },

  /**
   * Gender Filter
   * @param {SearchState} state
   * @return {string}
   */
  genderFilter(state: SearchState): string {
    const filter = state.talents.gender.reduce((acc, item) => {
      if (item.value) {
        if (acc) {
          acc = acc + ` OR gender:${Gender[item.text as keyof typeof Gender]}`
        } else {
          acc = `gender:${Gender[item.text as keyof typeof Gender]}`
        }
      }
      return `${acc}`
    }, '')
    return filter ? `(${filter})` : ''
  },

  /**
   * AttributeからAlgoliaのフィルターを生成
   * @param {SearchState} state
   * @return {string}
   */
  attributesFilter(state: SearchState): string {
    const filter = filterHandler(state.talents.attribute ?? null)
    return filter
  },

  /**
   *  居住地 Filter
   * @param {SearchState} state
   * @return {string}
   */
  prefecturesFilter(state: SearchState): string {
    if (state.talents.residence) {
      console.log(Object.values(prefectureList), state.talents.residence)
      const prefectures = Object.values(prefectureList)
      const prefectureFilter: any = prefectures
        .filter((val: any) => val.region === state.talents.residence)
        .reduce((acc: any, current: any) => {
          if (!acc && current) {
            acc = `prefecture:${current.code}`
          } else {
            acc = acc + ` OR prefecture:${current.code}`
          }
          return acc
        }, '')
      return `(${prefectureFilter})`
    } else {
      return ''
    }
  },

  /**
   *  業種 Filter
   * @param {SearchState} state
   * @return {string}
   */
  industryFilter(state: SearchState): string {
    const arr = state.talents.industry
    if (arr.length > 0) {
      let filters: string[] = []
      for (const item of arr) {
        if (item.value) {
          filters = [...filters, `industry: ${item.text}`]
        }
      }
      return filters.length > 0 ? `(${filters.join(' OR ')})` : ''
    } else {
      return ''
    }
  },
  sort: (state) => state.sort,
})

/**
 * Mutation
 */
export const mutations = mutationTree(state, {
  updateGenderMutation(state, gender: SelectItem) {
    state.talents.gender = state.talents.gender.map((selectItem) => {
      if (selectItem.text === gender.text) {
        selectItem.value = gender.value
        return selectItem
      } else {
        return selectItem
      }
    })
  },
  updateIndustryMutation(state, item: SelectItem) {
    state.talents.industry = state.talents.industry.map((_industry) => {
      if (_industry.text === item.text) {
        _industry.value = item.value
        return _industry
      } else {
        return _industry
      }
    })
  },
  setIndustryMutation(state, items: SelectItem[]) {
    state.talents.industry = items
  },
  updateAttributeMutation(state, val: number) {
    state.talents = { ...state.talents, attribute: val }
  },
  updateResidenceMutation(state, val: number) {
    state.talents.residence = val
  },
  updateCareersMutation(state, val: number) {
    state.talents.careers = val
  },
  updateAgeMutation(state, item: { key: keyof Ages; val: number | null }) {
    console.debug('updateAge', { item }, state.talents.ages, item.val)
    const ages = { ...state.talents.ages }
    ages[item.key] = item.val
    state.talents.ages = ages
    state.isDefaultAge = false
  },
  updateKeywordsMutation(state, val: string) {
    state.talents.keywords = val
  },
  updateCommitmentsMutation(state, item: SelectItem) {
    state.talents.commitments = state.talents.commitments.map((commitment) => {
      if (commitment.text === item.text) {
        commitment.value = item.value
        return commitment
      } else {
        return commitment
      }
    })
  },
  updateLikedMutation(state, talentId: string) {
    const { hits } = state.searchResult
    const newHits = hits.map<Hit<AlgoliaTalentWithLiked>>((hit) => {
      if (hit.objectID === talentId) {
        return {
          ...hit,
          isLiked: !hit.isLiked,
        }
      } else {
        return hit
      }
    })
    console.debug({ newHits }, state.searchResult)
    state.searchResult = { ...state.searchResult, hits: newHits }
  },

  updateSearchResultMutation(
    state,
    val: LSearchResponse<AlgoliaTalentWithLiked>
  ) {
    const { hits } = state.searchResult
    val.hits = [...hits, ...val.hits]
    state.searchResult = val
  },
  setSearchResultMutation(state, val: LSearchResponse<AlgoliaTalentWithLiked>) {
    state.searchResult = val
  },
  clearMutation(state, condition: TalentsCondition) {
    state.talents = condition
  },
  saveSelectedTalentIdMutation(state, id: string) {
    state.selected = id
  },
  setSortMutation(state, val: TalentSort | null) {
    state.sort = val
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    updateGender({ commit }, gender: SelectItem) {
      commit('updateGenderMutation', gender)
    },
    updateIndustry({ commit }, item: SelectItem) {
      commit('updateIndustryMutation', item)
    },
    updateAttribute({ commit }, val: number) {
      commit('updateAttributeMutation', val)
    },
    updateResidence({ commit }, val: number) {
      commit('updateResidenceMutation', val)
    },
    updateCareers({ commit }, val: number) {
      commit('updateCareersMutation', val)
    },
    updateAge({ commit }, item: { key: keyof Ages; val: number | null }) {
      commit('updateAgeMutation', item)
    },
    updateKeywords({ commit }, val: string) {
      commit('updateKeywordsMutation', val)
    },
    updateCommitments({ commit }, item: SelectItem) {
      commit('updateCommitmentsMutation', item)
    },

    async getIndustryFacets({ state, commit }) {
      // algoliaアクセス数軽減の為storeに存在している場合はリクエストしない（TACLOUD-338 アクセス数に対しalgoliaの検索数が多い）
      if (state.talents.industry.length) return
      const industryMasterRepo = new IndustryMasterRepo()
      const industryMasters = await industryMasterRepo.getAll()
      const industries = industryMasters
        .sort((a, b) => {
          return a.orderIndex - b.orderIndex
        })
        .map(
          (hit): SelectItem => ({
            value: false,
            text: hit.name,
          })
        )
      commit('setIndustryMutation', industries)
    },

    async getFacets() {
      await Promise.all([this.$accessor.search.talents.getIndustryFacets()])
    },

    async search(
      { commit, state, getters, rootGetters },
      {
        page,
        hitsPerPage,
        isAdd,
      }: {
        isAdd?: boolean // 追加読込かどうか（trueの場合前の結果に読み込んだ結果が追加される）
        page?: number // ページネーションの場合はpageを指定
        hitsPerPage?: number
      }
    ): Promise<void> {
      try {
        const userRepo = new UserRepo()
        const userInfo = rootGetters['auth/userInfo']
        console.log(getters.filters)
        console.log(state.sort)
        const result = await algoliasearchTalents(
          state.sort
        ).search<AlgoliaTalent>(state.talents.keywords, {
          filters: getters.filters,
          page,
          hitsPerPage,
        })
        console.log({ result })
        console.log(userInfo)
        const promises = result.hits.map(async (hit) => {
          const likedTalent = await userRepo.findOneLIkedTalent(
            userInfo.id,
            hit.objectID
          )
          if (likedTalent) {
            return {
              ...hit,
              isLiked: true,
            }
          } else {
            return {
              ...hit,
              isLiked: false,
            }
          }
        })
        const algoliaTalentsWithLikes = await Promise.all(promises)
        result.hits = algoliaTalentsWithLikes
        const mergedResult: LSearchResponse<AlgoliaTalentWithLiked> = {
          ...result,
          hits: algoliaTalentsWithLikes,
        }

        if (isAdd) {
          commit('updateSearchResultMutation', mergedResult)
        } else {
          commit('setSearchResultMutation', mergedResult)
        }
      } catch (e) {}
    },

    toggleLiked({ commit }, talentId: string) {
      commit('updateLikedMutation', talentId)
    },
    /**
     * 年代から検索
     * @param param0
     * @param payload
     */
    async searchByAttribute(
      { commit, state, getters, rootGetters },
      payload: {
        attr: 'ALL' | keyof typeof Attributes
        isAdd?: boolean // 追加読込かどうか（trueの場合前の結果に読み込んだ結果が追加される）
        page?: number // ページネーションの場合はpageを指定
        hitsPerPage?: number
      }
    ): Promise<void> {
      try {
        const userRepo = new UserRepo()
        const userInfo = rootGetters['auth/userInfo']
        // Attribute 更新
        const newAttr = Attributes[payload.attr as keyof typeof Attributes]
        commit('updateAttributeMutation', newAttr)

        // Tab切り替え時にベビー表示フラグの調整
        if (
          newAttr === 1 &&
          state.talents.ages.from &&
          state.talents.ages.from >= BABY_FILTER
        ) {
          commit('updateAgeMutation', { key: 'from', val: null })
        }

        const result = await algoliasearchTalents(
          state.sort
        ).search<AlgoliaTalent>('', {
          filters: getters.filters,
          page: payload.page,
          hitsPerPage: payload.hitsPerPage,
        })

        const promises = result.hits.map(async (hit) => {
          const likedTalent = await userRepo.findOneLIkedTalent(
            userInfo.id,
            hit.objectID
          )
          if (likedTalent) {
            return {
              ...hit,
              isLiked: true,
            }
          } else {
            return {
              ...hit,
              isLiked: false,
            }
          }
        })
        const algoliaTalentsWithLikes = await Promise.all(promises)
        // likeとマージする
        result.hits = algoliaTalentsWithLikes
        const mergedResult: LSearchResponse<AlgoliaTalentWithLiked> = {
          ...result,
          hits: algoliaTalentsWithLikes,
        }

        if (payload.isAdd) {
          commit('updateSearchResultMutation', mergedResult)
        } else {
          commit('setSearchResultMutation', mergedResult)
        }
      } catch (e) {}
    },

    async clear(
      { commit },
      condition: Partial<TalentsCondition>
    ): Promise<void> {
      commit('clearMutation', { ...initTalentsCondition(), ...condition })
      await this.$accessor.search.talents.getFacets()
    },

    selectTalent({ commit }, id: string) {
      commit('saveSelectedTalentIdMutation', id)
    },

    setSort({ commit }, sort: TalentSort | null) {
      commit('setSortMutation', sort)
    },
  }
)
