import "core-js/modules/es.string.link.js";
import { truncate } from '@batteki/base/src/utils/filters';
export default defineComponent({
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    link: {
      type: null,
      default: null
    }
  },
  data: function data() {
    return {
      dialog: false
    };
  },
  computed: {
    isClient: function isClient() {
      return this.$accessor.auth.isClient;
    },
    isTalent: function isTalent() {
      return this.$accessor.auth.isTalent;
    },
    isDisableLink: function isDisableLink() {
      return this.link == null;
    }
  },
  methods: {
    truncate: truncate
  }
});