import type {
  OfferStatus,
  EntryStatus,
  ProcessReplica,
  CommonTimestamp,
} from '@batteki/common'
import {
  PaymentMethodDisp,
  ClientOfferListLabels,
  ClientEntryListLabels,
  ActiveInquiry,
  ProcessTypes,
  Gender,
  Prefectures,
  PROCESS_TYPE,
} from '@batteki/common'
import dayjs from '../domain/util/dayjs'
import type { EntryWithProcessType } from '../domain/entries/entry.class'
import type { OfferWithProcessType } from '../domain/offers/offer.class'
import { assertIsDefined } from '.'

export function formatPrice(val: number | null): string {
  if (val != null) {
    return '¥ ' + val.toLocaleString()
  } else {
    return ''
  }
}

export function formatDate(
  val: CommonTimestamp | null,
  format = 'YYYY年MM月DD日'
): string | '' {
  if (val) {
    return dayjs(val.toDate()).format(format)
  } else {
    return ''
  }
}

export function shortForThousand(val: number) {
  // ３桁まで、4桁以降 ○k  e.g. 1154 => 1.2K
  if (val > 999) {
    const sum = Math.round((val / 1000) * 10) / 10
    return `${sum}K`
  } else {
    return val
  }
}

export function fromNow(val: CommonTimestamp | null) {
  if (val) {
    const now = dayjs()
    const date = dayjs(val.toDate())
    return date.from(now)
  } else {
    return val ?? ''
  }
}

export function ageFromTimestamp(val: CommonTimestamp | null): number | '' {
  if (val) {
    const birthday = val.toDate()
    const now = dayjs()
    const age = now.diff(birthday, 'year')
    return age || ''
  } else {
    return ''
  }
}

export function ageFromMs(val: number | null): string {
  const suffix = '歳'
  const suffixMonth = 'ヶ月'
  if (val) {
    const birthday = dayjs(val)
    const now = dayjs()
    const age = now.diff(birthday, 'year')
    if (age < 1) {
      const months = now.diff(birthday, 'month')
      return `${age}${suffix}${months}${suffixMonth}`
    } else if (age < 3) {
      let months = now.diff(birthday, 'month')
      months = months - age * 12
      return `${age}${suffix}${months}${suffixMonth}`
    } else {
      return `${age}${suffix}` || ''
    }
  } else {
    return ''
  }
}

export function countdownFromTimestamp(
  val: CommonTimestamp | null
): string | '' {
  if (val) {
    const limit = dayjs(val.toDate())
    const now = dayjs()
    const month = now.diff(limit, 'd') * -1
    const hour = (now.diff(limit, 'hour') % 24) * -1
    const minute = (now.diff(limit, 'minute') % 60) * -1
    return `${month}日+${hour}時間${minute}分`
  } else {
    return ''
  }
}

export function formatJSTDate(
  val: CommonTimestamp | null,
  format = 'YYYY年MM月DD日'
): string | '' {
  if (val) {
    return dayjs(val.toMillis()).tz('Asia/Tokyo').format(format)
  } else {
    return ''
  }
}

export function getPrefecture(val: string | Prefectures) {
  return Prefectures[val as keyof typeof Prefectures]
}

export function getGender(val: string | Gender) {
  return Gender[val as keyof typeof Gender]
}

export function formatNumber(
  val: number | null,
  locales = 'ja-JP',
  options?: Intl.NumberFormatOptions | undefined
): string {
  assertIsDefined(val)
  if (typeof val === 'number') {
    return new Intl.NumberFormat([locales], options).format(val)
  } else {
    return ''
  }
}

export function truncate(val: string | null, num = 10): string | null {
  if (val && val.length > num) {
    return val.substring(0, num) + '...'
  } else {
    return val
  }
}

export function getEntryOfferStatus(
  val: EntryWithProcessType | OfferWithProcessType | ProcessReplica
) {
  if (val.type === PROCESS_TYPE.entry)
    return ClientEntryListLabels[val.status as EntryStatus]
  if (val.type === PROCESS_TYPE.offer)
    return ClientOfferListLabels[val.status as OfferStatus]
  return ''
}

export function getEntryOfferLabel(
  val: EntryWithProcessType | OfferWithProcessType | ProcessReplica
) {
  if (val.type === PROCESS_TYPE.entry) return ProcessTypes.entry
  if (val.type === PROCESS_TYPE.offer) {
    if (ActiveInquiry.includes(val.status as OfferStatus)) {
      return '問い合わせ'
    } else {
      return ProcessTypes.offer
    }
  }

  return ''
}

export function getOfferStatus(val: OfferStatus) {
  return ClientOfferListLabels[val]
}

export function getEntryStatus(val: EntryStatus) {
  return ClientEntryListLabels[val]
}

export function formatPaymentMethod(val: number | null): string {
  if (
    val != null &&
    Object.entries(PaymentMethodDisp).filter(([_key, value]) => value === val)
      .length
  ) {
    return Object.entries(PaymentMethodDisp).filter(
      ([_key, value]) => value === val
    )[0][0]
  } else {
    return '未設定'
  }
}
