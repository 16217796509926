import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import AsyncLoading from '@batteki/base/src/components/organisms/layout/AsyncLoading.vue';
import ErrorMessage from '@batteki/base/src/components/molecules/BaseErrorMessage.vue';
import BaseMessage from '@batteki/base/src/components/molecules/BaseMessage.vue';
export default defineComponent({
  components: {
    AsyncLoading: AsyncLoading,
    ErrorMessage: ErrorMessage,
    BaseMessage: BaseMessage,
    PCLinkBanner: function PCLinkBanner() {
      return import('~/components/organisms/layout/PCLinkBanner.vue');
    }
  }
});