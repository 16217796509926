import { Gender, Attributes } from '@batteki/common'
import type { SelectItem } from './index'

export const BABY_FILTER = 3
const AGE_FILTER_DEFAULT = {
  from: BABY_FILTER, // デフォルトでベビーを非表示
  to: null,
}

export type Ages = {
  from: number | null
  to: number | null
}
export interface TalentsCondition {
  gender: SelectItem[]
  ages: Ages
  attribute: number | null
  residence: number
  careers: number
  industry: SelectItem[]
  commitments: SelectItem[]
  keywords: string
}

export const initTalentsCondition = (): TalentsCondition => ({
  gender: initGender(),
  ages: AGE_FILTER_DEFAULT,
  attribute: Attributes.未選択,
  residence: 0,
  careers: 0,
  industry: [],
  commitments: initCommitments(),
  keywords: '',
})

export const initGender = (): SelectItem[] => {
  return [
    { text: Gender[1], value: false },
    { text: Gender[2], value: false },
  ]
}

export const initCommitments = (): SelectItem[] => {
  return [
    { text: '直近３日稼働可能', value: false },
    { text: 'お気に入り数上位', value: false },
  ]
}
