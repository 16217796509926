import type {
  IOffer,
  InquiryType,
  ProcessReplica,
  CommonJob,
  CommonTimestamp,
  IJob,
  IClient,
  ITalent,
} from '@batteki/common'
import {
  CommonOfferWithJob,
  CommonOfferWithJobAndTalent,
  OFFER_STATUS,
  offerFactory,
  CommonOffer,
  COLLECTION_NAME,
} from '@batteki/common'
import type {
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
import { collection, doc } from 'firebase/firestore'
import type { Job } from '../jobs/job.class'
import { Talent } from '../talents/talent.class'
import { bindOfferRef, db } from '@batteki/base/src/plugins/firebase'
import type {
  JobRef,
  ChatRoomRef,
  FeeChangelogRef,
  TalentRef,
} from '@batteki/base/src/types/firestore'

export class Offer extends CommonOffer {
  // @ts-expect-error babelのallowDeclareFieldsが動作しないため
  public job: JobRef

  // @ts-expect-error babelのallowDeclareFieldsが動作しないため
  public talent: TalentRef

  // @ts-expect-error babelのallowDeclareFieldsが動作しないため
  public chatRoom: ChatRoomRef

  // @ts-expect-error babelのallowDeclareFieldsが動作しないため
  public feeChengeLog: FeeChangelogRef
  public static buildOffer(
    offer: Partial<Offer>,
    inquiryType: InquiryType,
    job: Job,
    uid: string,
    talentId: string
  ) {
    const jobRef = doc(
      db,
      `${COLLECTION_NAME.jobs}/${job.id}`
    ) as DocumentReference<IJob>
    const clientRef = doc(
      db,
      `${COLLECTION_NAME.clients}/${uid}`
    ) as DocumentReference<IClient>
    const talentRef = doc(
      db,
      `${COLLECTION_NAME.talents}/${talentId}`
    ) as DocumentReference<ITalent>
    const billId = doc(collection(db, '_')).id

    const newOffer = CommonOffer.commonBuildOffer(
      offer,
      inquiryType,
      job,
      billId,
      jobRef,
      clientRef,
      talentRef
    )
    return new Offer(newOffer, newOffer.id)
  }

  static override initDefault() {
    return new Offer(
      {
        ...offerFactory(),
        billId: doc(collection(db, '_')).id,
      },
      ''
    )
  }

  public setId(): void {
    this.id = doc(
      collection(
        db,
        `${COLLECTION_NAME.jobs}/${this.job.ref!.id}/${COLLECTION_NAME.offers}`
      ),
      this.talent.ref!.id
    ).id
  }
}
export const offerConverter: FirestoreDataConverter<Offer> = {
  toFirestore(data: Offer): IOffer {
    return Offer.toFirestore(data)
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Offer {
    const data = snapshot.data()
    return new Offer({ ...offerFactory(), ...data }, snapshot.id)
  },
}

export class OfferWithProcessType extends Offer {
  type: 'offer'
  constructor(init: Offer) {
    super(init, init.id)
    this.type = 'offer'
  }
}

export class OfferWithTalentAndProcessType extends OfferWithProcessType {
  talentData: Talent
  constructor(init: OfferWithProcessType, talent?: Talent | undefined) {
    super(init)
    this.talentData = talent ?? Talent.initDefault()
  }

  getProcessReplica(): ProcessReplica {
    return {
      type: this.type,
      ref: doc(bindOfferRef(this.job.ref.id), this.id),
      status: this.status,
    }
  }
}

export class OfferWithJobAndTalent extends CommonOfferWithJobAndTalent {}

export class OfferWithJobAndTalentWithExitAt extends OfferWithJobAndTalent {
  exitAt: CommonTimestamp | null

  static getExitAt(item: IOffer) {
    if (item.status === OFFER_STATUS.IS_CANCELED) {
      return item.canceledAt
    }
    if (item.status === OFFER_STATUS.IS_REFUSED) {
      return item.refusedAt
    }
    if (item.status === OFFER_STATUS.IS_ADMIN_DISAPPROVED) {
      return item.adminDisapprovedAt
    }
    if (item.status === OFFER_STATUS.IS_EXPIRED) {
      return item.expiredAt
    }
    if (item.status === OFFER_STATUS.IS_INQUIRY_REFUSED) {
      return item.inquiryRefusedAt
    }
    if (item.status === OFFER_STATUS.IS_ADMIN_CLOSED) {
      return item.closedAt
    }
    if (item.status === OFFER_STATUS.IS_CLOSED) {
      return item.closedAt
    }
    return null
  }

  constructor(init: Offer, job: CommonJob, talent: Talent) {
    super(init, job, talent)
    this.exitAt = OfferWithJobAndTalentWithExitAt.getExitAt(init)
  }
}

export class OfferWithJob extends CommonOfferWithJob {}
