"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BelongingTos = void 0;
var BelongingTos;
(function (BelongingTos) {
    BelongingTos[BelongingTos["\u30C6\u30A2\u30C8\u30EB\u30A2\u30AB\u30C7\u30DF\u30FC"] = 1] = "\u30C6\u30A2\u30C8\u30EB\u30A2\u30AB\u30C7\u30DF\u30FC";
    BelongingTos[BelongingTos["\u30C6\u30A2\u30C8\u30EB\u30A2\u30AB\u30C7\u30DF\u30FC\u30D7\u30ED\u30C0\u30AF\u30B7\u30E7\u30F3"] = 2] = "\u30C6\u30A2\u30C8\u30EB\u30A2\u30AB\u30C7\u30DF\u30FC\u30D7\u30ED\u30C0\u30AF\u30B7\u30E7\u30F3";
    BelongingTos[BelongingTos["\u30A2\u30C3\u30C8\u30D7\u30ED\u30C0\u30AF\u30B7\u30E7\u30F3"] = 3] = "\u30A2\u30C3\u30C8\u30D7\u30ED\u30C0\u30AF\u30B7\u30E7\u30F3";
    BelongingTos[BelongingTos["\u30C6\u30A2\u30C8\u30EBRUI\u30D7\u30ED\u30C0\u30AF\u30B7\u30E7\u30F3"] = 4] = "\u30C6\u30A2\u30C8\u30EBRUI\u30D7\u30ED\u30C0\u30AF\u30B7\u30E7\u30F3";
    BelongingTos[BelongingTos["\u30C6\u30A2\u30C8\u30EB\u30A8\u30F3\u30BF\u30FC\u30C6\u30A4\u30F3\u30E1\u30F3\u30C8"] = 5] = "\u30C6\u30A2\u30C8\u30EB\u30A8\u30F3\u30BF\u30FC\u30C6\u30A4\u30F3\u30E1\u30F3\u30C8";
})(BelongingTos = exports.BelongingTos || (exports.BelongingTos = {}));
