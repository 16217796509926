"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BillStatusColors = exports.BILL_STATUS = exports.BillStatuses = void 0;
exports.BillStatuses = {
    UNCLAIMED: '未請求',
    CLAIMED: '請求済み',
    IGNORED: '対象外',
};
exports.BILL_STATUS = Object.keys(exports.BillStatuses).reduce((result, current) => {
    result[current] = current;
    return result;
}, {});
exports.BillStatusColors = {
    UNCLAIMED: 'warning',
    CLAIMED: 'success',
    IGNORED: 'grey lighten-2',
};
