"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chkBillEmail = exports.addCustomClaims = exports.userFactory = exports.customClaimFactory = void 0;
const __1 = require("..");
function customClaimFactory() {
    return {
        suspended: false,
        isClientCertificated: false,
        role: null,
        isAdmin: false,
    };
}
exports.customClaimFactory = customClaimFactory;
function userFactory() {
    return {
        email: '',
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isAdmin: false,
        suspended: false,
        isClientCertificated: false,
        role: null,
        lastNewsOpenedAt: null,
        destinationEmail: '',
    };
}
exports.userFactory = userFactory;
function addCustomClaims(user, customClaim) {
    return {
        ...user,
        ...customClaim,
    };
}
exports.addCustomClaims = addCustomClaims;
/**
 *
 * @param user
 * @returns true⇒要更新 false⇒OK
 */
const chkBillEmail = (targetEmail) => {
    const { required, max100, email } = __1.ROBOT_PAYMENT_RULES;
    // user.email/メールアドレス/max100,email,required
    if (required(targetEmail) !== true)
        return true;
    if (max100(targetEmail) !== true)
        return true;
    if (email(targetEmail) !== true)
        return true;
    return false;
};
exports.chkBillEmail = chkBillEmail;
