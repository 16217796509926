
import { defineComponent } from 'vue'

interface Data {}

export default defineComponent({
  components: {},
  data(): Data {
    return {
      dialog: false,
    }
  },
  computed: {
    isClient(): boolean {
      return this.$accessor.auth.isClient
    },
    isTalent(): boolean {
      return this.$accessor.auth.isTalent
    },
  },
  methods: {},
})
