import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import {
  likedTalentFactory,
  CommonTalentWithLiked,
  COLLECTION_NAME,
  deletedTalentFactory,
} from '@batteki/common'
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore'
import { Talent } from '../../domain/talents/talent.class'
import { TalentsRepository } from '@batteki/base/src/domain/talents/repository'
import { UserRepo } from '@batteki/base/src/domain/users/repository'
import 'firebase/functions'
import { userService } from '@batteki/base/src/domain/users/service'
import { LikedTalent } from '@batteki/base/src/domain/likedTalent/liked-talent.class'
import { auth, bindTalentRef, db } from '@batteki/base/src/plugins/firebase'
import type { TalentMedia } from '@batteki/base/src/domain/talentMedia/talent-media.class'
import { talentMediaConverter } from '@batteki/base/src/domain/talentMedia/talent-media.class'

export class TalentWithLiked extends CommonTalentWithLiked {
  static initDefault() {
    return new TalentWithLiked(Talent.initDefault(), undefined)
  }
}

export interface TalentDetailState {
  talent: TalentWithLiked | null
  media: TalentMedia[]
  unsubscribe: null | (() => void)
  step: number
  hasMore: boolean
  offerDialog: boolean
}

export const initState = (): TalentDetailState => ({
  talent: null,
  unsubscribe: null,
  media: [],
  step: 11,
  hasMore: false,
  offerDialog: false,
})

export const state = (): TalentDetailState => initState()

export const getters = getterTree(state, {
  talent: (state) => state.talent,
  updateKeywords(state) {
    const str: string[] = []
    const keywords = state.talent?.keywords ?? []
    const industry = state.talent?.industry ?? []
    str.push(...industry)
    str.push(...keywords)
    return str
  },
  isLiked: (state) => !!state.talent?.likedTalent?.id,
  hasMore: (state) => state.hasMore,
})

export const mutations = mutationTree(state, {
  setTalentMutation(state, talent: TalentWithLiked | null) {
    state.talent = talent
  },
  addMediaMutation(state, media: TalentMedia) {
    state.media = [...state.media, media]
  },
  setMediaMutation(state, media: TalentMedia[]) {
    state.media = media
  },
  updateHasMoreMutation(state, flag: boolean) {
    state.hasMore = flag
  },
  updateOfferDialogMutation(state, flag: boolean) {
    state.offerDialog = flag
  },
  resetStateMutation(state) {
    Object.assign(state, initState())
  },
  setUnsubscribeMutation(state, unsubscribe: null | (() => void)) {
    state.unsubscribe = unsubscribe
  },
  incrementLikeMutation(state) {
    if (state.talent) {
      state.talent.liked = state.talent.liked + 1
    }
  },
  decrementsLikeMutation(state) {
    if (state.talent) {
      state.talent.liked = state.talent.liked - 1
    }
  },
})
export const actions = actionTree(
  { state, getters, mutations },
  {
    resetTalentDetail({ commit }) {
      commit('resetStateMutation')
    },
    /**
     * キャスト取得
     * @param param0
     * @param id
     */
    async fetchTalent({ commit }, id: string) {
      const rep = new TalentsRepository()
      const { uid } = auth.currentUser!
      let talent = await rep.getWithoutDeleted(id)

      console.debug(id !== uid, talent)
      // 非公開の場合はダミーデータを返す
      if (id !== uid && talent.isSuspended) {
        talent = new Talent(
          { ...deletedTalentFactory(), isSuspended: true },
          id
        )
        const talentWithLiked = new TalentWithLiked(talent, undefined)
        commit('setTalentMutation', talentWithLiked)
        return talentWithLiked
      } else {
        const userRepo = new UserRepo()
        const likedTalent = await userRepo.findOneLIkedTalent(uid, talent.id)
        const talentWithLiked = new TalentWithLiked(talent, likedTalent)
        commit('setTalentMutation', talentWithLiked)
        return talentWithLiked
      }
    },
    clear({ commit }) {
      commit('setMediaMutation', [])
    },
    startMediaListener({ commit, state }, id: string) {
      commit('setMediaMutation', [])
      if (state.unsubscribe) {
        state.unsubscribe()
        commit('setUnsubscribeMutation', null)
      }
      const unsubscribe = onSnapshot(
        query(
          collection(
            db,
            `${COLLECTION_NAME.talents}/${id}/${COLLECTION_NAME.media}`
          ).withConverter(talentMediaConverter),
          limit(12),
          orderBy('orderIndex')
        ),
        (snapshot) => {
          const media = snapshot.docs.map((doc) => doc.data())

          const movies = media.filter(
            (item) => item.type && ['MOVIE', 'YOUTUBE'].includes(item.type)
          )
          const images = media.filter(
            (item) => item.type && ['IMAGE'].includes(item.type)
          )

          commit('setMediaMutation', [...movies, ...images])
          commit('setUnsubscribeMutation', unsubscribe)
        }
      )
    },
    stopMediaListener({ state, commit }) {
      if (state.unsubscribe) {
        state.unsubscribe()
        commit('setUnsubscribeMutation', null)
      }
    },
    async fetchMedia({ commit }, id: string) {
      const talentRepo = new TalentsRepository()
      const { hasMore, media } = await talentRepo.getMediaCollection(id)
      commit('updateHasMoreMutation', hasMore)
      commit('setMediaMutation', media)
    },
    async fetchMoreMedia({ commit, state }, id: string) {
      const lastVisible = state.media[state.media.length - 1]
      const talentRepo = new TalentsRepository()
      if (!lastVisible) return
      const { hasMore, media } = await talentRepo.getMediaCollection(
        id,
        lastVisible.orderIndex
      )
      commit('updateHasMoreMutation', hasMore)
      media.forEach((_media) => {
        commit('addMediaMutation', _media)
      })
    },

    /**
     * お気に入り追加
     */
    async toggleLiked({ state, commit }, talentId?: string): Promise<void> {
      const { uid } = auth.currentUser!
      const id = talentId || state.talent?.id
      if (!id) return
      const likedTalent = new LikedTalent(likedTalentFactory(), id)
      likedTalent.talent.ref = doc(bindTalentRef, id)
      const isLiked = await userService.toggleTalentLike(uid, likedTalent, id!)
      if (isLiked) {
        commit('incrementLikeMutation')
      } else {
        commit('decrementsLikeMutation')
      }

      if (state.talent) {
        if (isLiked) {
          commit(
            'setTalentMutation',
            new TalentWithLiked(state.talent, likedTalent)
          )
        } else {
          commit(
            'setTalentMutation',
            new TalentWithLiked(state.talent, undefined)
          )
        }
      }
      // likeの数がバックグラウンドでカウントされるため再取得しない。
    },
  }
)
