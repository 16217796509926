"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.destinationFactory = void 0;
const destinationFactory = () => ({
    createdAt: null,
    updatedAt: null,
    uid: '',
    email: '',
    oneTimePassword: '',
});
exports.destinationFactory = destinationFactory;
