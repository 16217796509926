
import { defineComponent } from 'vue'
import {
  isCastTab,
  isHomeTab,
  isJobTab,
  isMypageTab,
  isNewsTab,
  isSettingsTab,
} from '@batteki/base/src/constants/pages'
import { auth } from '@batteki/base/src/plugins/firebase'
export type NavPage = 'job' | 'cast' | 'settings' | 'mypage' | 'home' | 'news'
interface Data {
  unsubscribe: (() => void) | null
  chatRoomReferenceUnsubscribe: (() => void) | null
  selected: NavPage
}

export default defineComponent({
  components: {},

  data(): Data {
    return {
      unsubscribe: null,
      chatRoomReferenceUnsubscribe: null,
      selected: 'cast',
    }
  },
  computed: {
    isClient(): boolean {
      return this.$accessor.auth.isClient
    },
    isTalent(): boolean {
      return this.$accessor.auth.isTalent
    },
    hasUnreadNews(): boolean {
      return this.$accessor.auth.hasUnreadNews
    },
    isShowActionReq(): boolean {
      return (
        (this.isClient &&
          !!this.$accessor.clients.actionReqEntries.itemLength) ||
        (this.isClient &&
          !!this.$accessor.clients.actionReqOffers.itemLength) ||
        (this.isTalent &&
          !!this.$accessor.talents.actionReqOffers.itemLength) ||
        this.$accessor.chatRoomReference.hasUnreadMessage
      )
    },
  },
  mounted() {
    this.$accessor.auth.fetchLatestNews()
    this.$accessor.chatRoomReference.subscribeOneUnreadChatRoomReference(
      auth.currentUser!.uid
    )
    this.unsubscribe = this.$accessor.auth.newsSubscribe
    this.highLightNavBar()

    if (this.isClient) {
      // 対応が必要なエントリー数の取得
      this.$accessor.clients.actionReqEntries.bindItems({
        clientId: auth.currentUser!.uid,
      })
      // 対応が必要なオファー数の取得
      this.$accessor.clients.actionReqOffers.bindItems({
        clientId: auth.currentUser!.uid,
      })
    }
    if (this.isTalent) {
      // 対応が必要なオファー数の取得
      this.$accessor.talents.actionReqOffers.bindItems({
        talentId: auth.currentUser!.uid,
      })
    }
  },
  beforeUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
    this.$accessor.chatRoomReference.unsubscribeListener()
  },
  methods: {
    highLightNavBar() {
      if (isHomeTab(this.$route)) {
        this.selected = 'home'
      } else if (isSettingsTab(this.$route)) {
        this.selected = 'settings'
      } else if (isMypageTab(this.$route)) {
        this.selected = 'mypage'
      } else if (isNewsTab(this.$route)) {
        this.selected = 'news'
      } else if (isJobTab(this.$route)) {
        this.selected = 'job'
      } else if (isCastTab(this.$route)) {
        this.selected = 'cast'
      } else {
        this.selected = 'home'
      }
    },
    async signOut() {
      await this.$accessor.auth.signOut()
    },
    gotoNews() {
      // ニュースタブを開く様設定
      this.$accessor.tabs.setNotificationTab(1)
      if (this.isClient) this.$nuxt.$router.push('/client/notification')
      if (this.isTalent) this.$nuxt.$router.push('/talent/notification')
    },
  },
})
