
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    name: {
      type: String,
      default: '',
    },
    black: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
})
