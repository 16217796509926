"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.publishReview = exports.buildReview = exports.reviewFactory = void 0;
function reviewFactory() {
    return {
        score: null,
        job: {
            ref: null,
        },
        comment: '',
        createdAt: null,
        updatedAt: null,
        from: {
            ref: null,
        },
        to: {
            ref: null,
        },
        status: 'IS_WAITING',
    };
}
exports.reviewFactory = reviewFactory;
function buildReview(review, update) {
    return {
        ...review,
        job: {
            ref: update.jobRef,
        },
        from: {
            ref: update.fromRef,
        },
        to: {
            ref: update.toRef,
        },
    };
}
exports.buildReview = buildReview;
function publishReview(review) {
    return {
        ...review,
        status: 'IS_PUBLISHED',
    };
}
exports.publishReview = publishReview;
