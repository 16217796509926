import type { SelectItem } from '@batteki/base/src/domain/mypage/index'
export function generateFilters(
  items: SelectItem[],
  facetName: string
): string {
  if (items.length > 0) {
    let filters: string[] = []
    for (const item of items) {
      if (item.value) {
        filters = [...filters, `${facetName}: ${item.text}`]
      }
    }
    return filters.length > 0 ? `(${filters.join(' OR ')})` : ''
  } else {
    return ''
  }
}
