import type { ILikedTalent } from '@batteki/common'
import { CommonLikedTalent } from '@batteki/common'
import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
export class LikedTalent extends CommonLikedTalent {}

export const likedTalentConverter: FirestoreDataConverter<LikedTalent> = {
  toFirestore(data: LikedTalent): ILikedTalent {
    return LikedTalent.toFirestore(data)
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<ILikedTalent>): LikedTalent {
    const data = snapshot.data()
    return new LikedTalent(data, snapshot.id)
  },
}
