import type {
  IUser,
  INotification,
  CommonTimestamp,
  ILikedJobsWithJob,
} from '@batteki/common'
import { deletedTalentFactory, COLLECTION_NAME } from '@batteki/common'
import type {
  CollectionReference,
  QueryConstraint,
  WriteBatch,
} from 'firebase/firestore'
import {
  serverTimestamp,
  collection,
  limit,
  orderBy,
  query,
  startAfter,
  doc,
  where,
} from 'firebase/firestore'
import { apiAdapter } from '../adapter'
import type { Job } from '../jobs/job.class'
import { jobConverter } from '../jobs/job.class'
import type { LikedJob } from '../likedJob/liked-job.class'
import { likedJobConverter } from '../likedJob/liked-job.class'
import type { LikedTalent } from '../likedTalent/liked-talent.class'
import { likedTalentConverter } from '../likedTalent/liked-talent.class'
import type { Notification } from '../notification/notification.class'
import { notificationConverter } from '../notification/notification.class'
import { Talent, talentConverter } from '../talents/talent.class'
import { useDocumentData } from '../common/useDocument'
import { TalentListItem } from '../../store/likedTalents'
import type { User } from './user.class'
import { userConverter } from './user.class'
import { db } from '@batteki/base/src/plugins/firebase'

export class UserRepo {
  private colRef: CollectionReference
  constructor() {
    this.colRef = collection(db, COLLECTION_NAME.users)
  }

  get(id: string): Promise<User | undefined> {
    return apiAdapter.requestGetAPI<User>(this.colRef, id, userConverter)
  }

  getByField(
    fieldName: string,
    fieldValue: string | number
  ): Promise<User | undefined> {
    return apiAdapter.getByField<User>(
      this.colRef,
      fieldName,
      fieldValue,
      userConverter
    )
  }

  // create user
  create(uid: string, item: User): Promise<User | undefined> {
    return apiAdapter.create<User>(this.colRef, item, userConverter, uid)
  }

  // update existing user
  update(id: string, item: Partial<IUser>): Promise<User | undefined> {
    return apiAdapter.update(this.colRef, id, item, userConverter)
  }

  /**
   * Likeしたキャスト一覧
   * @param uid
   * @param limit
   */
  async getLikedTalents(
    uid: string,
    lastVisible: CommonTimestamp | undefined = undefined,
    limits = 12
  ): Promise<{ talents: TalentListItem[]; hasMore: boolean }> {
    const likedRef = apiAdapter.subCollection(
      this.colRef,
      uid,
      COLLECTION_NAME.likedTalents
    )
    const queryConstraints: QueryConstraint[] = [
      limit(limits + 1),
      orderBy('createdAt', 'desc'),
    ]
    if (lastVisible) {
      queryConstraints.push(startAfter(lastVisible))
    }
    const likedTalents = await apiAdapter.getByQuery<LikedTalent>(
      query(likedRef, ...queryConstraints),
      likedTalentConverter
    )
    let hasMore = false
    if (likedTalents.length === limits + 1) {
      hasMore = true
      likedTalents.pop()
    }
    const promises = likedTalents.map(async (likedTalent) => {
      // talentが削除済みの場合を考慮
      const ref = likedTalent.talent.ref
      const talent = ref
        ? await apiAdapter.getWithoutDeleted<Talent>(
            collection(db, COLLECTION_NAME.talents),
            ref.id,
            new Talent(deletedTalentFactory(), ref.id),
            talentConverter
          )
        : new Talent(deletedTalentFactory(), '')
      return new TalentListItem(likedTalent, talent, true)
    })
    const talents = await Promise.all(promises)
    return { talents, hasMore }
  }

  /**
   * キャストIDのlikedTalentを取得
   * @param clientId
   * @param talentId
   */
  findOneLIkedTalent(
    clientId: string,
    talentId: string
  ): Promise<LikedTalent | undefined> {
    const likedRef = apiAdapter.subCollection(
      this.colRef,
      clientId,
      COLLECTION_NAME.likedTalents
    )
    return apiAdapter.requestGetAPI<LikedTalent>(
      likedRef,
      talentId,
      likedTalentConverter
    )
  }

  /**
   * Create user's LikedTalent
   * @param clientId
   * @param talentId
   * @param likedTalent
   */
  createLikedTalent(
    clientId: string,
    likedTalent: LikedTalent,
    talentId: string
  ): Promise<LikedTalent | undefined> {
    const colRef = apiAdapter.subCollection(
      this.colRef,
      clientId,
      COLLECTION_NAME.likedTalents
    )
    return apiAdapter.create(
      colRef,
      likedTalent,
      likedTalentConverter,
      talentId
    )
  }

  /**
   * Delete user's LikedTalent
   * @param clientId
   * @param talentId
   */
  deleteLikedTalent(clientId: string, talentId: string): Promise<void> {
    const colRef = apiAdapter.subCollection(
      this.colRef,
      clientId,
      COLLECTION_NAME.likedTalents
    )
    return apiAdapter.delete(colRef, talentId)
  }

  /**
   * likeしたキャストをbatch set
   * @param batch
   * @param clientId
   * @param item
   * @param id
   */
  batchSetLikedTalent(
    batch: WriteBatch,
    clientId: string,
    item: LikedTalent,
    id: string
  ) {
    const likedRef = apiAdapter.subCollection(
      this.colRef,
      clientId,
      COLLECTION_NAME.likedTalents
    )
    console.log(likedRef)
    const docRef = doc(likedRef, id)
    apiAdapter.batchCreate(batch, docRef, item, likedTalentConverter)
  }

  /**
   * likeしたキャストをbatch delete
   * @param batch
   * @param uid
   * @param id
   */
  batchDeleteLikedTalent(batch: WriteBatch, uid: string, id: string) {
    const likedRef = apiAdapter.subCollection(
      this.colRef,
      uid,
      COLLECTION_NAME.likedTalents
    )
    const docRef = doc(likedRef, id)
    apiAdapter.batchDelete(batch, docRef)
  }

  /**
   * 案件リスト
   * @param uid
   */
  async getLikedJobs(
    uid: string,
    lastVisible: CommonTimestamp | undefined = undefined,
    limits = 12
  ): Promise<{ likedJobs: ILikedJobsWithJob[]; hasMore: boolean }> {
    const ref = apiAdapter.subCollection(
      this.colRef,
      uid,
      COLLECTION_NAME.likedJobs
    )

    const queryConstraints: QueryConstraint[] = [
      where('deletedAt', '==', null),
      orderBy('createdAt', 'desc'),
      limit(limits + 1),
    ]
    if (lastVisible) {
      queryConstraints.push(startAfter(lastVisible))
    }
    const liked = await apiAdapter.getByQuery<LikedJob>(
      query(ref, ...queryConstraints),
      likedJobConverter
    )
    let hasMore = false
    if (liked.length === limits + 1) {
      hasMore = true
      liked.pop()
    }
    const arr = liked.reduce(
      async (
        _acc: Promise<ILikedJobsWithJob[]>,
        like
      ): Promise<ILikedJobsWithJob[]> => {
        let acc = await _acc
        const ref = like.job.ref
        const job = await apiAdapter.getByRef<Job>(ref!, jobConverter)
        if (job) {
          acc = [
            ...acc,
            {
              ...like,
              job: job as Job,
            },
          ]
        }
        return acc
      },
      Promise.resolve([])
    )
    const likedJobs = await arr
    return { likedJobs, hasMore }
  }

  /**
   * 案件IDのlikedJobを取得
   * @param talentId
   * @param jobId
   */
  findOneLIkedJob(
    talentId: string,
    jobId: string
  ): Promise<LikedJob | undefined> {
    const likedRef = apiAdapter.subCollection(
      this.colRef,
      talentId,
      COLLECTION_NAME.likedJobs
    )
    return apiAdapter.requestGetAPI<LikedJob>(
      likedRef,
      jobId,
      likedJobConverter
    )
  }

  /**
   * Create user's likedJob
   * @param talentId
   * @param jobId
   * @param likedJob
   */
  createLikedJob(
    talentId: string,
    likedJob: LikedJob,
    jobId: string
  ): Promise<LikedJob | undefined> {
    const colRef = apiAdapter.subCollection(
      this.colRef,
      talentId,
      COLLECTION_NAME.likedJobs
    )
    return apiAdapter.create(colRef, likedJob, likedJobConverter, jobId)
  }

  /**
   * Delete user's likedJob
   * @param talentId
   * @param jobId
   */
  deleteLikedJob(talentId: string, jobId: string) {
    const colRef = apiAdapter.subCollection(
      this.colRef,
      talentId,
      COLLECTION_NAME.likedJobs
    )
    return apiAdapter.delete(colRef, jobId)
  }

  /**
   * likeした案件をbatch set
   * @param batch
   * @param talentId
   * @param item
   * @param jobId
   */
  batchSetLikedJob(
    batch: WriteBatch,
    talentId: string,
    item: LikedJob,
    jobId: string
  ) {
    const likedRef = apiAdapter.subCollection(
      this.colRef,
      talentId,
      COLLECTION_NAME.likedJobs
    )
    const docRef = doc(likedRef, jobId)
    apiAdapter.batchCreate(batch, docRef, item, likedJobConverter)
  }

  /**
   * likeした案件をbatch delete
   * @param batch
   * @param talentId
   * @param jobId
   */
  batchDeleteLikedJob(batch: WriteBatch, talentId: string, jobId: string) {
    const likedRef = apiAdapter.subCollection(
      this.colRef,
      talentId,
      COLLECTION_NAME.likedJobs
    )
    const docRef = doc(likedRef, jobId)
    apiAdapter.batchDelete(batch, docRef)
  }

  /**
   * 通知一覧
   * @param uid
   * @param limit
   */
  async getNotifications(
    uid: string,
    lastVisible: CommonTimestamp | undefined = undefined,
    limits = 12
  ): Promise<{ notifications: Notification[]; hasMore: boolean }> {
    let hasMore = false
    const notificationRef = apiAdapter.subCollection(
      this.colRef,
      uid,
      COLLECTION_NAME.notifications
    )
    const queryConstraints: QueryConstraint[] = [
      orderBy('createdAt', 'desc'),
      limit(limits + 1),
    ]
    if (lastVisible) {
      queryConstraints.push(startAfter(lastVisible))
    }
    const notifications = await apiAdapter.getByQuery<Notification>(
      query(notificationRef, ...queryConstraints),
      notificationConverter
    )
    if (notifications.length === limits + 1) {
      hasMore = true
      notifications.pop()
    }
    return { notifications, hasMore }
  }

  /**
   * 通知更新
   * @param uid
   * @param docId
   * @param notification
   */
  updateNotification(
    uid: string,
    docId: string,
    notification: Partial<INotification>
  ): Promise<Notification | undefined> {
    const notificationRef = apiAdapter.subCollection(
      this.colRef,
      uid,
      COLLECTION_NAME.notifications
    )
    return apiAdapter.update(
      notificationRef,
      docId,
      notification,
      notificationConverter
    )
  }

  /**
   * お知らせ既読更新
   * @param uid
   */
  updateLastNewsOpenedAt(uid: string): Promise<User | undefined> {
    return apiAdapter.update<IUser, User>(
      this.colRef,
      uid,
      {
        lastNewsOpenedAt: serverTimestamp() as CommonTimestamp,
      },
      userConverter
    )
  }

  useData(inputId: string | null) {
    const dataState = useDocumentData(
      () => (inputId ? doc(this.colRef, inputId) : null),
      {
        dataConverter: userConverter,
      }
    )

    return {
      loading: dataState.loading,
      value: dataState.value,
    }
  }
}
