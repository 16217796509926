import type { Middleware, Context } from '@nuxt/types'
import type { User as AuthUser } from 'firebase/auth'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '@batteki/base/src/plugins/firebase'

// import {accessorType} from '../store/index';

const middlewareAuth: Middleware = async (ctx: Context): Promise<void> => {
  await authUpdate(ctx)
}

/**
 *
 * @param $accessor
 */
export function authUpdate(ctx: Context): Promise<AuthUser | null> {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      ctx.app.$accessor.auth.setAuth(user)
      if (user) {
        console.debug(user)
        if (!ctx.app.$accessor.auth.userInfo) {
          ctx.app.$accessor.auth.fetchUser(user.uid)
        }
        if (!process.env.SENTRY_DISABLED) {
          ctx.$sentry.configureScope((scope) => {
            scope.setUser({ id: user.uid, email: user.email || '' })
          })
        }
      } else {
        ctx.$accessor.auth.setUser(null)
      }
      resolve(user)
    })
  })
}

//   const middlewareAuth: Middleware = async ({app, $auth}: Context): Promise<void> => {
//     await new Promise((resolve) => {
//       $auth.onAuthStateChanged(async (user) => {
//         if (user) {
//           if (!user.emailVerified) {
//             setLogInStatus(false, undefined);
//           } else {
//             const repo = new UserRepo();
//             const userData = await repo.get(user.uid);
//             if (userData) {
//               setLogInStatus(true, userData);
//             } else {
//               const repo = new UserRepo();
//               const newUser = await repo.create(user.uid, {...initUserDetail(), email: user.email!});
//               newUser ? setLogInStatus(true, newUser) : setLogInStatus(false, undefined);
//             }
//           }
//         } else {
//           setLogInStatus(false, undefined);
//         }
//         resolve();
//       });
//     });

//   function setLogInStatus(status: boolean, userData: User | undefined) {
//     if (status) {
//       app.$accessor.auth.setUser(userData);
//       app.$accessor.auth.setAuth(true);
//     } else {
//       app.$accessor.auth.setUser(userData);
//       app.$accessor.auth.setAuth(false);
//     }
//   }
// };

export default middlewareAuth
