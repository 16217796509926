
import { defineComponent } from 'vue'
import Logo from '@batteki/base/src/components/atoms/logo.vue'
export default defineComponent({
  components: {
    Logo,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
})
