import type { IJob, IJobChangelog } from '@batteki/common'
import {
  CommonJobWithClientAndLiked,
  CommonJobWithClient,
  CommonJob,
  jobFactory,
} from '@batteki/common'
import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
export class Job extends CommonJob {}
export class JobWithClient extends CommonJobWithClient {}
export class JobWithClientAndLiked extends CommonJobWithClientAndLiked {}

export const jobConverter: FirestoreDataConverter<Job> = {
  toFirestore(data: Job): Omit<IJob, 'entries' | 'offers'> {
    return Job.toFirestore(data)
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Job {
    const data = snapshot.data()
    return new Job({ ...jobFactory(), ...data }, snapshot.id)
  },
}

export class JobWithChangelog extends Job {
  public jobChangelog!: IJobChangelog | undefined

  constructor(job: Job, jobChangelog: IJobChangelog | undefined) {
    super(job, job.id)
    this.jobChangelog = jobChangelog
  }
}
