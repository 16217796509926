import type { CommonTimestamp, INews } from '@batteki/common'
import { newsFactory } from '@batteki/common'
import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
export class News implements INews {
  public target!: 'CLIENT' | 'TALENT' | 'ALL'
  public title!: string
  public content!: string
  public deletedAt!: CommonTimestamp | null
  public isPublished!: boolean
  public publishedAt!: CommonTimestamp | null
  public createdAt!: CommonTimestamp | null
  public updatedAt!: CommonTimestamp | null
  public id!: string

  constructor(init: INews, id: string) {
    Object.assign(this, { id, ...init })
  }

  changePublished(isPublished: boolean, serverTimestamp: CommonTimestamp) {
    this.isPublished = isPublished
    if (isPublished && !this.publishedAt) this.publishedAt = serverTimestamp
  }

  static initDefault() {
    return new News(newsFactory(), '')
  }
}

export const newsConverter: FirestoreDataConverter<News> = {
  toFirestore(data: News): INews {
    return {
      target: data.target,
      title: data.title,
      content: data.content,
      deletedAt: data.deletedAt,
      isPublished: data.isPublished,
      publishedAt: data.publishedAt,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): News {
    const data = snapshot.data() as INews
    return new News(data, snapshot.id)
  },
}
