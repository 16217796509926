
import { defineComponent } from 'vue'
export default defineComponent({
  data: () => ({
    timeout: 5000,
  }),
  computed: {
    message() {
      if (this.$message.message) {
        console.log('snackMassage:', this.$message.message)
      }
      return this.$message.message
    },
    color() {
      return this.$message.type ? this.$message.type : 'success'
    },
    snackbar: {
      get() {
        return !!this.$message.message
      },
      set() {
        this.resetMessage()
      },
    },
  },
  methods: {
    resetMessage() {
      this.$message.message = ''
      this.$message.type = ''
      this.$message.centered = false
    },
  },
})
