import type * as VueRouter from 'vue-router'
import { PAGES } from '@batteki/base/src/constants/pages'
export function setTitle(route: VueRouter.Route): string {
  const find = PAGES.find((page) => page.name === route.name)
  if (find) {
    return find.title
  } else {
    return ''
  }
}
