
import { defineComponent } from 'vue'
import BaseIcon from '@batteki/base/src/components/atoms/ui/BaseIcon.vue'
import TheSearchTalents from '~/components/organisms/layout/TheSearchTalents.vue'

export default defineComponent({
  components: {
    BaseIcon,
    TheSearchTalents,
  },
  data() {
    return {
      dialog: false,
      pageAttribute: 'isTalents',
      clipped: false,
      drawer: false,
      fixed: false,
      items: [
        {
          icon: 'mdi-home',
          title: 'Home',
          to: '/',
        },
        // {
        //   icon: 'mdi-briefcase',
        //   title: '案件一覧',
        //   to: '/jobs',
        // },
      ],
      miniVariant: false,
    }
  },
  methods: {
    async signOut() {
      await this.$accessor.auth.signOut()
    },
  },
})
