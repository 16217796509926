import { getterTree, mutationTree, actionTree } from 'typed-vuex'

interface State {
  pcBanner: boolean
}

const state = (): State => ({
  pcBanner: true,
})

export const getters = getterTree(state, {
  getPcBanner: (state) => state.pcBanner,
})

const mutations = mutationTree(state, {
  setBanner(state, { target, value }: { target: string; value: boolean }) {
    state[target as keyof typeof state] = value
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    setPcBanner({ commit }, value: boolean) {
      commit('setBanner', { target: 'pcBanner', value })
    },
  }
)
