"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jobChangelogFactory = void 0;
const _1 = require(".");
const jobChangelogFactory = () => {
    const { 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    entries, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    offers, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    refedForDispIdAt, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isOfferReady, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isInquiryReady, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isEntryReady, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isEnded, ...jobChangelogFactory } = (0, _1.jobFactory)();
    return jobChangelogFactory;
};
exports.jobChangelogFactory = jobChangelogFactory;
