"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonOfferWithJobWithClientAndTalent = exports.CommonOfferWithTalent = exports.CommonOfferWithJobAndTalent = exports.CommonOfferWithJob = exports.CommonOffer = void 0;
const models_1 = require("../../models");
class CommonOffer {
    constructor(init, id) {
        Object.assign(this, { ...init, id });
    }
    static toFirestore(data) {
        return {
            job: data.job,
            talent: data.talent,
            chatRoom: data.chatRoom,
            status: data.status,
            canceledAt: data.canceledAt,
            refusedAt: data.refusedAt,
            expiredAt: data.expiredAt,
            acceptedAt: data.acceptedAt,
            approvedAt: data.approvedAt,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            remindedAcceptAt: data.remindedAcceptAt,
            remindedApproveAt: data.remindedApproveAt,
            fee: data.fee,
            feeChengeLog: data.feeChengeLog,
            scheduleToClose: data.scheduleToClose,
            closedAt: data.closedAt,
            billStatus: data.billStatus,
            billApplyedAt: data.billApplyedAt,
            billIgnoredAt: data.billIgnoredAt,
            isBill: data.isBill,
            billId: data.billId,
            adminApprovedAt: data.adminApprovedAt,
            adminDisapprovedAt: data.adminDisapprovedAt,
            inquiryRefusedAt: data.inquiryRefusedAt,
            offeredAt: data.offeredAt,
            inquiryType: data.inquiryType,
            adminCancelAt: data.adminCancelAt,
            adminCancelReason: data.adminCancelReason,
            adminCancelBy: data.adminCancelBy,
        };
    }
    static initDefault() {
        return new CommonOffer((0, models_1.offerFactory)(), '');
    }
    static commonBuildOffer(offer, inquiryType, job, billId, jobRef, clientRef, talentRef) {
        return new CommonOffer({
            ...(0, models_1.offerFactory)(),
            ...offer,
            inquiryType,
            job: {
                ref: jobRef,
                client: {
                    ref: clientRef,
                },
            },
            talent: {
                ref: talentRef,
            },
            fee: job.fee,
            scheduleToClose: job.scheduleToClose,
            billId,
        }, talentRef.id);
    }
    makeOffer(serverCommonTimestamp) {
        this.status = models_1.OFFER_STATUS.IN_PROGRESS;
        this.offeredAt = serverCommonTimestamp;
        this.expiredAt = this.scheduleToClose;
    }
    approve(serverCommonTimestamp) {
        this.status = models_1.OFFER_STATUS.IS_APPROVED;
        this.approvedAt = serverCommonTimestamp;
    }
    cancel(serverCommonTimestamp) {
        this.status = models_1.OFFER_STATUS.IS_CANCELED;
        this.canceledAt = serverCommonTimestamp;
    }
    refuse(serverCommonTimestamp) {
        this.status = models_1.OFFER_STATUS.IS_REFUSED;
        this.refusedAt = serverCommonTimestamp;
    }
    inquiryRefuse(serverCommonTimestamp) {
        this.status = models_1.OFFER_STATUS.IS_INQUIRY_REFUSED;
        this.inquiryRefusedAt = serverCommonTimestamp;
    }
    expire() {
        this.status = models_1.OFFER_STATUS.IS_EXPIRED;
    }
    accept(serverCommonTimestamp) {
        this.status = models_1.OFFER_STATUS.IS_ACCEPTED;
        this.acceptedAt = serverCommonTimestamp;
    }
    adminApprove(serverCommonTimestamp) {
        this.status = models_1.OFFER_STATUS.IS_ADMIN_APPROVED;
        this.adminApprovedAt = serverCommonTimestamp;
        this.adminDisapprovedAt = null;
    }
    adminDisapprove(serverCommonTimestamp) {
        this.status = models_1.OFFER_STATUS.IS_ADMIN_DISAPPROVED;
        this.adminDisapprovedAt = serverCommonTimestamp;
        this.adminApprovedAt = null;
    }
    remindForAccept(serverCommonTimestamp) {
        this.remindedAcceptAt = serverCommonTimestamp;
    }
    remindForApprove(serverCommonTimestamp) {
        this.remindedApproveAt = serverCommonTimestamp;
    }
    setNewFee(newFee) {
        this.fee = newFee;
        this.feeChengeLog.ref = null;
    }
    // 正常に案件が終了した場合
    close(serverCommonTimestamp, isAdmin, author, reason) {
        // 案件成立中で費用が発生する場合に請求対象とする
        if (this.status === models_1.OFFER_STATUS.IS_APPROVED && this.fee > 0)
            this.isBill = true;
        if (isAdmin) {
            this.status = models_1.OFFER_STATUS.IS_ADMIN_CLOSED;
            this.adminCancelAt = serverCommonTimestamp;
            this.adminCancelBy = author;
            this.adminCancelReason = reason || '';
        }
        else {
            this.status = models_1.OFFER_STATUS.IS_CLOSED;
        }
        this.closedAt = serverCommonTimestamp;
    }
    // 問い合わせ辞退（キャスト操作）の戻し
    reopenFromInquiryRefused() {
        if (this.status !== models_1.OFFER_STATUS.IS_INQUIRY_REFUSED)
            return;
        this.status = models_1.OFFER_STATUS.IN_INQUIRY;
        this.inquiryRefusedAt = null;
    }
    // オファー辞退（キャスト操作）の戻し
    reopenFromRefused() {
        if (this.status !== models_1.OFFER_STATUS.IS_REFUSED)
            return;
        this.status = models_1.OFFER_STATUS.IN_PROGRESS;
        this.refusedAt = null;
    }
    // オファー回答拒否（クライアント操作）時の戻し
    reopenFromCanceled() {
        if (this.status !== models_1.OFFER_STATUS.IS_CANCELED)
            return;
        this.status = models_1.OFFER_STATUS.IS_ADMIN_APPROVED;
        this.canceledAt = null;
    }
    // オファー回答採用（クライアント操作）時の戻し
    rollbackFromApproved() {
        if (this.status !== models_1.OFFER_STATUS.IS_APPROVED)
            return;
        this.status = models_1.OFFER_STATUS.IS_ADMIN_APPROVED;
        this.approvedAt = null;
    }
    setChatRoom(chatRoomRef) {
        this.chatRoom = {
            ref: chatRoomRef,
        };
    }
}
exports.CommonOffer = CommonOffer;
class CommonOfferWithJob extends CommonOffer {
    constructor(init, job) {
        super(init, init.id);
        this.jobClass = job;
    }
}
exports.CommonOfferWithJob = CommonOfferWithJob;
class CommonOfferWithJobAndTalent extends CommonOfferWithJob {
    constructor(init, job, talent) {
        super(init, job);
        this.talentClass = talent;
    }
}
exports.CommonOfferWithJobAndTalent = CommonOfferWithJobAndTalent;
class CommonOfferWithTalent extends CommonOffer {
    constructor(init, talent) {
        super(init, init.id);
        this.talentClass = talent;
    }
}
exports.CommonOfferWithTalent = CommonOfferWithTalent;
class CommonOfferWithJobWithClientAndTalent extends CommonOfferWithTalent {
    constructor(init, job, talent) {
        super(init, talent);
        this.jobClass = job;
    }
}
exports.CommonOfferWithJobWithClientAndTalent = CommonOfferWithJobWithClientAndTalent;
