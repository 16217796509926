import type { Ages } from './talents'
import { initGender } from './talents'
import type { SelectItem } from './index'
// import {Role} from '~/constants/role';
export interface JobsCondition {
  media: SelectItem[]
  categories: SelectItem[]
  gender: SelectItem[]
  ages: Ages
  keywords: string
  excludeExpired: boolean
  // area: string;
  // individuality: SelectItem[];
  // industry: SelectItem[];
  // characteristics: SelectItem[];
  // roles: SelectItem[];
}

export const initJobsCondition = (): JobsCondition => ({
  media: [],
  categories: [],
  gender: initGender(),
  ages: {
    from: null,
    to: null,
  },
  excludeExpired: false,
  keywords: '',
  // area: '',
  // individuality: [],
  // industry: initIndustry(),
  // characteristics: initCharacteristics(),
  // roles: initRoles(),
})

// export const initIndustry = (): SelectItem[] => {
//   return [
//     {text: 'スーツアクター', value: false},
//     {text: 'YouTuber', value: false},
//     {text: '声優', value: false},
//     {text: 'インフルエンサー', value: false},
//   ];
// };

// export const initCharacteristics = (): SelectItem[] => {
//   return [
//     {text: 'いじめっ子', value: false},
//     {text: 'ギャル', value: false},
//     {text: 'チャラい', value: false},
//   ];
// };

// export const initRoles = (): SelectItem[] => {
//   return Object.values(Role).map((_role) => ({
//     text: _role,
//     value: false,
//   }));
// };
