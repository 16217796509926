import Vue from 'vue';
var state = Vue.observable({
  message: '',
  type: '',
  centered: false
});
var messagePlugin = function messagePlugin(ctx, inject) {
  inject('message', state);
  ctx.$message = state;
};
export default messagePlugin;