import type { IJobCategoryMaster, CommonTimestamp } from '@batteki/common'

import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
export class JobCategoryMaster implements IJobCategoryMaster {
  public name!: string
  public orderIndex!: number
  public createdAt!: CommonTimestamp
  public updatedAt!: CommonTimestamp
  public id!: string

  constructor(init: IJobCategoryMaster, id: string) {
    Object.assign(this, { id, ...init })
  }
}

export const jobCategoryMasterConverter: FirestoreDataConverter<JobCategoryMaster> =
  {
    toFirestore(data: JobCategoryMaster): IJobCategoryMaster {
      return {
        name: data.name,
        orderIndex: data.orderIndex,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
      }
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): JobCategoryMaster {
      const data = snapshot.data() as IJobCategoryMaster
      return new JobCategoryMaster(data, snapshot.id)
    },
  }
