"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEmbedYoutubeUrl = void 0;
function getYoutubeId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
        return match[2];
    }
    // youtube short/通常
    const regExp2 = /^.*(youtube.com\/shorts\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match2 = url.match(regExp2);
    if (match2 && match2[2].length === 11) {
        return match2[2];
    }
    return null;
}
function getEmbedYoutubeUrl(url) {
    const youtubeId = getYoutubeId(url);
    if (!youtubeId)
        throw new Error('URLの形式が不正');
    return `https://www.youtube.com/embed/${youtubeId}`;
}
exports.getEmbedYoutubeUrl = getEmbedYoutubeUrl;
