import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import type { IJob } from '@batteki/common'
import { likedJobFactory, COLLECTION_NAME } from '@batteki/common'
import type { DocumentReference } from 'firebase/firestore'
import { doc } from 'firebase/firestore'
import { UserRepo } from '@batteki/base/src/domain/users/repository'
import { userService } from '@batteki/base/src/domain/users/service'
import { JobRepo } from '@batteki/base/src/domain/jobs/repository'
import { LikedJob } from '@batteki/base/src/domain/likedJob/liked-job.class'
import { auth, db } from '@batteki/base/src/plugins/firebase'
import { JobWithClientAndLiked } from '@batteki/base/src/domain/jobs/job.class'

export interface JobDetailState {
  job: JobWithClientAndLiked | null
}

export const state = (): JobDetailState => ({
  job: null,
})

export const getters = getterTree(state, {
  job: (state) => state.job,
  liked: (state) => (state.job ? !!state.job.likedJob : false),
})

export const mutations = mutationTree(state, {
  setJobMutation(state, job: JobWithClientAndLiked | null) {
    state.job = job
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    async fetchJob(
      { commit },
      id: string
    ): Promise<JobWithClientAndLiked | null> {
      const { uid } = auth.currentUser!
      const jobRepo = new JobRepo()
      const job = (await jobRepo.getWithClient(id)) || null
      if (job) {
        const userRepo = new UserRepo()
        const likedJob = await userRepo.findOneLIkedJob(uid, job.id)
        const jobWithClientAndLiked = new JobWithClientAndLiked(
          job,
          job.clientClass,
          likedJob
        )

        commit('setJobMutation', jobWithClientAndLiked)
        return jobWithClientAndLiked
      } else {
        commit('setJobMutation', null)
        return null
      }
    },
    // 案件詳細
    async toggleLiked({ state, dispatch }): Promise<void> {
      const { uid } = auth.currentUser!
      const likedJob = new LikedJob(likedJobFactory())
      likedJob.job.ref = doc(
        db,
        `${COLLECTION_NAME.jobs}/${state.job!.id}`
      ) as DocumentReference<IJob>
      await userService.toggleJobLike(uid, likedJob, state.job!.id)
      await dispatch('fetchJob', state.job!.id)
    },
  }
)
