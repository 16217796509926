"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonClient = void 0;
const models_1 = require("../../models");
class CommonClient {
    constructor(init, id) {
        Object.assign(this, { ...init, id });
    }
    static initDefault() {
        return new CommonClient((0, models_1.clientFactory)(), '');
    }
    static toFirestore(data) {
        return {
            name: data.name,
            kana: data.kana,
            clientType: data.clientType,
            thumbnail: data.thumbnail,
            department: data.department,
            position: data.position,
            company: data.company,
            reviews: data.reviews,
            latestReviews: data.latestReviews,
            billClientId: data.billClientId,
            paymentMethod: data.paymentMethod,
            worksUrl: data.worksUrl,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            deletedAt: data.deletedAt,
        };
    }
    chkIsReqUpdateBillInfo() {
        return (0, models_1.chkIsReqUpdateBillInfo)(this);
    }
    chkBillProfile() {
        return (0, models_1.chkBillProfile)(this);
    }
}
exports.CommonClient = CommonClient;
