import { COLLECTION_NAME } from '@batteki/common'
import type { CollectionReference } from 'firebase/firestore'
import { collection, orderBy, query } from 'firebase/firestore'
import { apiAdapter } from '../adapter'
import type { IndustryMaster } from './industry-master.class'
import { industryMasterConverter } from './industry-master.class'
import { db } from '@batteki/base/src/plugins/firebase'

export class IndustryMasterRepo {
  private ref: CollectionReference
  constructor() {
    this.ref = collection(db, COLLECTION_NAME.industryMaster)
  }

  get(id: string): Promise<IndustryMaster | undefined> {
    return apiAdapter.requestGetAPI<IndustryMaster>(
      this.ref,
      id,
      industryMasterConverter
    )
  }

  getAll(): Promise<IndustryMaster[]> {
    return apiAdapter.getByQuery<IndustryMaster>(
      query(this.ref, orderBy('orderIndex', 'asc')),
      industryMasterConverter
    )
  }
}
