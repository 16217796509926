"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chatRoomFactory = exports.chatRoomReferenceFactory = void 0;
const message_interface_1 = require("./message.interface");
function chatRoomReferenceFactory() {
    return {
        job: {
            ref: null,
        },
        members: [],
        unreadMessages: {
            count: 0,
        },
        lastMessage: (0, message_interface_1.messageFactory)(),
        sendUnreadMailAt: null,
        process: null,
        createdAt: null,
        updatedAt: null,
    };
}
exports.chatRoomReferenceFactory = chatRoomReferenceFactory;
function chatRoomFactory() {
    return {
        members: [],
        createdAt: null,
        closedAt: null,
        updatedAt: null,
        readStatus: [],
        job: { ref: null },
        processType: 'entry',
        process: {
            ref: null,
        },
        lastMessage: null,
    };
}
exports.chatRoomFactory = chatRoomFactory;
