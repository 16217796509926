
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  computed: {
    isClient(): boolean {
      return this.$accessor.auth.isClient
    },
    isTalent(): boolean {
      return this.$accessor.auth.isTalent
    },
  },
  methods: {},
})
