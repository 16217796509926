import type { ITalent } from '@batteki/common'

export function chkIsNeedSNSAgree(talent: ITalent) {
  if (
    !talent.agreeSnsTermAt &&
    (!!talent.sns.instagram.screenName ||
      !!talent.sns.twitter.screenName ||
      !!talent.sns.youtube.channelId ||
      !!talent.sns.tiktok.screenName)
  )
    return true
  return false
}
