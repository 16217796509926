"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.likedTalentFactory = void 0;
function likedTalentFactory() {
    return {
        talent: {
            ref: null,
        },
        createdAt: null,
        updatedAt: null,
    };
}
exports.likedTalentFactory = likedTalentFactory;
