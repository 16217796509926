"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.likedJobFactory = void 0;
function likedJobFactory() {
    return {
        job: {
            ref: null,
        },
        deletedAt: null,
        createdAt: null,
        updatedAt: null,
    };
}
exports.likedJobFactory = likedJobFactory;
