import type {
  IReview,
  ReviewStatus,
  Score,
  IReviewWithFromProfile,
  IReviewWithToProfile,
  IJob,
  IClient,
  ITalent,
  CommonTimestamp,
  IUserCommon,
} from '@batteki/common'
import type {
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { Job } from '../jobs/job.class'
import type { UserCommon } from '../users/userCommon.class'
import type { JobRef } from '@batteki/base/src/types/firestore'
export class Review implements IReview {
  public score!: Score
  public comment!: string
  public createdAt!: CommonTimestamp | null
  public updatedAt!: CommonTimestamp | null
  public job!: JobRef

  public from!: {
    ref: (DocumentReference<ITalent> | DocumentReference<IClient>) | null
  }

  public to!: {
    ref: (DocumentReference<ITalent> | DocumentReference<IClient>) | null
  }

  public status!: ReviewStatus
  public id!: string

  constructor(init: IReview, id: string) {
    Object.assign(this, { id, ...init })
  }

  public buildReview(update: {
    jobRef: DocumentReference<IJob>
    fromRef: DocumentReference<ITalent> | DocumentReference<IClient>
    toRef: DocumentReference<ITalent> | DocumentReference<IClient>
  }) {
    this.job.ref = update.jobRef
    this.from.ref = update.fromRef
    this.to.ref = update.toRef
    this.status = 'IS_WAITING'
  }

  public convertToReviewWithFromProfile(
    from: UserCommon | undefined,
    job: Job | undefined
  ): IReviewWithFromProfile {
    return {
      ...this,
      from,
      job,
    }
  }

  public convertToReviewWithToProfile(
    to: IUserCommon | undefined,
    job: Job | undefined
  ): IReviewWithToProfile {
    return {
      ...this,
      to,
      job,
    }
  }
}

export const reviewConverter: FirestoreDataConverter<Review> = {
  toFirestore(data: Review): IReview {
    return {
      score: data.score,
      comment: data.comment,
      job: data.job,
      from: data.from,
      to: data.to,
      status: data.status,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    }
  },

  fromFirestore(snapshot: QueryDocumentSnapshot): Review {
    const data = snapshot.data() as IReview
    return new Review(data, snapshot.id)
  },
}
