import type { IIndustryMaster, CommonTimestamp } from '@batteki/common'

import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
export class IndustryMaster implements IIndustryMaster {
  public name!: string
  public orderIndex!: number
  public createdAt!: CommonTimestamp
  public updatedAt!: CommonTimestamp
  public id!: string

  constructor(init: IIndustryMaster, id: string) {
    Object.assign(this, { id, ...init })
  }
}

export const industryMasterConverter: FirestoreDataConverter<IndustryMaster> = {
  toFirestore(data: IndustryMaster): IIndustryMaster {
    return {
      name: data.name,
      orderIndex: data.orderIndex,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): IndustryMaster {
    const data = snapshot.data() as IIndustryMaster
    return new IndustryMaster(data, snapshot.id)
  },
}
