import type { StorageReference } from 'firebase/storage'
import { uploadString, getDownloadURL } from 'firebase/storage'

export async function uploadDataURL(
  storageRef: StorageReference,
  dataUrl: string
): Promise<string> {
  await uploadString(storageRef, dataUrl, 'data_url')
  return await getDownloadURL(storageRef)
}

export function isDataURLFormat(val: string): boolean {
  return val.startsWith('data:')
}

type CompressedImageSize = '300x300' | '500x500' | '800x800'

export function compressedURL(url: string, size: CompressedImageSize) {
  const [last, ...some] = url.split('%2F').reverse()
  console.log(some)
  const name = last.split('.')[0]
  console.log(name)
  if (name) {
    const reObj = new RegExp(`${name}`)
    const replaced = url.replace(reObj, `${name}_${size}`)
    return replaced
  } else {
    return url
  }
}
