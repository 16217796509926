"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deletedTalentFactory = exports.talentSnsFactory = exports.talentReviewsFactory = exports.getTalentReviewScore = exports.getTalentViewOrder = exports.talentFactory = exports.talentSizeFactory = void 0;
const firestore_1 = require("firebase/firestore");
const constants_1 = require("../constants");
const ORDERMIN = 100;
const ORDERMAX = 10000;
const THREVIEWCOUNT = 3;
function talentSizeFactory() {
    return {
        shoeSize: null,
        bust: null,
        waist: null,
        weight: null,
        headSize: null,
        height: null,
        hip: null,
    };
}
exports.talentSizeFactory = talentSizeFactory;
const talentFactory = () => ({
    // attribute: Attributes.未選択,
    birthday: null,
    discography: '',
    gender: constants_1.Gender.未選択,
    industry: [],
    introduction: '',
    name: '',
    kana: '',
    prefecture: constants_1.Prefectures.未選択,
    profileImages: [],
    speciality: [],
    thumbnail: '',
    keywords: [],
    singingRange: '',
    sizes: talentSizeFactory(),
    sns: talentSnsFactory(),
    liked: 0,
    pr: '',
    lastId: 0,
    isPublished: false,
    createdAt: null,
    updatedAt: null,
    taCode: '',
    deletedAt: null,
    reviews: talentReviewsFactory(),
    latestReviews: [],
    belongingTo: '',
    isSuspended: true,
    viewOrder: (0, exports.getTalentViewOrder)(0, false),
    replicatedAt: null,
    isFixedOrder: false,
    agreeSnsTermAt: null,
});
exports.talentFactory = talentFactory;
// キャストの表示順序をランダムに設定
// ※強制的に表示順序を上げたい場合ORDERMIN以下に設定し、
const getTalentViewOrder = (viewOrder, isFixedOrder) => {
    if (isFixedOrder)
        return viewOrder;
    return Math.floor(Math.random() * (ORDERMAX + 1 - ORDERMIN)) + ORDERMIN;
};
exports.getTalentViewOrder = getTalentViewOrder;
// レビュースコア算出関数
const getTalentReviewScore = (reviews) => {
    // 閾値以下は0とする
    if (reviews.count <= THREVIEWCOUNT)
        return 0;
    // 小数点第二位まで（切り捨て）とし、詳細度を設定する
    return Math.floor((reviews.sum / reviews.count) * 100) / 100;
};
exports.getTalentReviewScore = getTalentReviewScore;
function talentReviewsFactory() {
    return {
        sum: 0,
        count: 0,
        aggregation: {
            '1': 0,
            '3': 0,
            '5': 0,
        },
    };
}
exports.talentReviewsFactory = talentReviewsFactory;
function talentSnsFactory() {
    return {
        twitter: {
            screenName: '',
            followersCount: null,
            updatedAt: null,
        },
        instagram: {
            screenName: '',
            updatedAt: null,
            followersCount: null,
        },
        youtube: {
            channelId: '',
            updatedAt: null,
            subscriberCount: null,
        },
        tiktok: {
            screenName: '',
            updatedAt: null,
            followersCount: null,
        },
    };
}
exports.talentSnsFactory = talentSnsFactory;
const deletedTalentFactory = () => {
    return {
        ...(0, exports.talentFactory)(),
        name: 'ユーザが存在しません',
        thumbnail: '/img/no_image/talent_thumb.png',
        deletedAt: firestore_1.Timestamp.now(),
    };
};
exports.deletedTalentFactory = deletedTalentFactory;
