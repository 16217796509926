"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.feeChangelogFactory = void 0;
function feeChangelogFactory() {
    return {
        newFee: 0,
        confirmedAt: null,
        createdAt: null,
        updatedAt: null,
    };
}
exports.feeChangelogFactory = feeChangelogFactory;
