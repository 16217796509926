import { getterTree, mutationTree, actionTree } from 'typed-vuex'

export interface TabState {
  clientEntryTab: number
  clientOfferTab: number
  talentEntryTab: number
  talentOfferTab: number
  clientSendOfferTab: number
  notificationTab: number
  jobTab: number
}

export const state = (): TabState => ({
  clientEntryTab: 0,
  clientOfferTab: 0,
  talentEntryTab: 0,
  talentOfferTab: 0,
  clientSendOfferTab: 0,
  notificationTab: 0,
  jobTab: 0,
})

export const getters = getterTree(state, {
  clientEntryTab: (state) => state.clientEntryTab,
  clientOfferTab: (state) => state.clientOfferTab,
  talentEntryTab: (state) => state.talentEntryTab,
  talentOfferTab: (state) => state.talentOfferTab,
  clientSendOfferTab: (state) => state.clientSendOfferTab,
  notificationTab: (state) => state.notificationTab,
  jobTab: (state) => state.jobTab,
})

export const mutations = mutationTree(state, {
  setTabMutation(state, { target, value }: { target: string; value: number }) {
    state[target as keyof typeof state] = value
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    setClientEntryTab({ commit }, value: number) {
      commit('setTabMutation', { target: 'clientEntryTab', value })
    },
    setClientOfferTab({ commit }, value: number) {
      commit('setTabMutation', { target: 'clientOfferTab', value })
    },
    setTalentEntryTab({ commit }, value: number) {
      commit('setTabMutation', { target: 'talentEntryTab', value })
    },
    setTalentOfferTab({ commit }, value: number) {
      commit('setTabMutation', { target: 'talentOfferTab', value })
    },
    setClientSendOfferTab({ commit }, value: number) {
      commit('setTabMutation', { target: 'clientSendOfferTab', value })
    },
    setNotificationTab({ commit }, value: number) {
      commit('setTabMutation', { target: 'notificationTab', value })
    },
    setJobTab({ commit }, value: number) {
      commit('setTabMutation', { target: 'jobTab', value })
    },
  }
)
