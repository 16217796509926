import type { INotification, CommonTimestamp } from '@batteki/common'
import { notificationFactory } from '@batteki/common'
import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
export class Notification implements INotification {
  public title!: string
  public isRead!: boolean
  public thumbnail!: string
  public url!: string
  public createdAt!: CommonTimestamp | null
  public updatedAt!: CommonTimestamp | null
  public id!: string

  constructor(init: INotification, id: string) {
    Object.assign(this, { id, ...init })
  }

  static initDefault() {
    return new Notification(notificationFactory(), '')
  }
}

export const notificationConverter: FirestoreDataConverter<Notification> = {
  toFirestore(data: Notification): INotification {
    return {
      title: data.title,
      isRead: data.isRead,
      thumbnail: data.thumbnail,
      url: data.url,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Notification {
    const data = snapshot.data() as INotification
    return new Notification(data, snapshot.id)
  },
}
