import type { Middleware, Context } from '@nuxt/types'
import type { CustomClaims } from '@batteki/common'
import type { Claim } from '../store/auth'
import { auth } from '@batteki/base/src/plugins/firebase'

const middleware: Middleware = async (ctx: Context): Promise<void> => {
  const { currentUser } = auth
  if (currentUser) {
    const { claims } = await currentUser.getIdTokenResult(true)
    if (claims) {
      setClaim(ctx, claims as Claim)
      if (claims.role === 'talent' && !ctx.$accessor.auth.talentProfile) {
        await ctx.$accessor.auth.fetchTalentProfile(currentUser.uid)
      } else if (
        claims.role === 'client' &&
        !ctx.$accessor.auth.clientProfile
      ) {
        await ctx.$accessor.auth.fetchClientProfile(currentUser.uid)
      }
    }
  } else {
    setClaim(ctx, null)
  }
}
export default middleware

function setClaim({ app }: Context, customClaims: CustomClaims | null) {
  if (customClaims) {
    app.$accessor.auth.setClaim(customClaims)
  } else {
    app.$accessor.auth.setClaim(null)
  }
}
