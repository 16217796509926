"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_ERROR_MESSAGE = exports.SECURITY_RULE_ERROR = exports.ENTRY_ERRORS = exports.STORAGE_ERRORS = exports.AUTH_ERRORS = void 0;
const auth_1 = require("firebase/auth");
exports.AUTH_ERRORS = {
    [auth_1.AuthErrorCodes.ADMIN_ONLY_OPERATION]: '操作権限がありません。',
    [auth_1.AuthErrorCodes.EMAIL_EXISTS]: 'ご利用のメールアドレスは既に登録されています。',
    'auth/invalid-email': 'メールアドレスが不正な形式です。',
    'auth/user-disabled': 'ユーザーが無効化されています。',
    'auth/user-not-found': '指定されたメールアドレスに対応するユーザが存在しません。',
    'auth/wrong-password': '指定されたメールアドレスとパスワードが一致しません。',
    'auth/too-many-requests': 'しばらく経ってから再度お試しください。',
    'auth/account-exists-with-different-credential': 'すでに同一のメールアドレスで別のベンダーにより登録されています。',
    'auth/invalid-action-code': 'メールアドレスの確認のリクエストの期限が切れたか、リンクがすでに使用されています。',
    'auth/requires-recent-login': '再ログインしてください。',
    'auth/user-token-expired': '再ログインしてください。',
    'auth/invalid-user-token': '再ログインしてください。',
};
exports.STORAGE_ERRORS = {
    'storage/unknown': '不明なエラーが発生しました。',
};
exports.ENTRY_ERRORS = {
    'entry-exist': '既にエントリーした案件にはエントリーできません。',
    'offer-exist': '1度オファーされた案件にはエントリーできません。',
    'job-ended': 'この案件は募集を終了しているため、エントリーできません。',
};
exports.SECURITY_RULE_ERROR = {
    'permission-denied': '入力値に不備または、アクセス権限がございません。',
};
exports.DEFAULT_ERROR_MESSAGE = 'エラーが発生しました。しばらくたってから再度お試しください。';
