"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chkBillProfile = exports.chkIsReqUpdateBillInfo = exports.deletedClientFactory = exports.clientReviewsFactory = exports.addressFactory = exports.companyFactory = exports.clientFactory = exports.CLIENT_TYPE = exports.ClientTypes = void 0;
const __1 = require("..");
const constants_1 = require("../constants");
exports.ClientTypes = {
    CORPORATION: '法人',
    INDIVIDUAL: '個人',
};
exports.CLIENT_TYPE = Object.keys(exports.ClientTypes).reduce((result, current) => {
    result[current] = current;
    return result;
}, {});
function clientFactory() {
    return {
        name: '',
        kana: '',
        clientType: exports.CLIENT_TYPE.CORPORATION,
        thumbnail: '',
        department: '',
        position: '',
        company: companyFactory(),
        reviews: clientReviewsFactory(),
        latestReviews: [],
        billClientId: '',
        paymentMethod: 2,
        worksUrl: '',
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
    };
}
exports.clientFactory = clientFactory;
function companyFactory() {
    return {
        name: '',
        kana: '',
        phone: '',
        address: addressFactory(),
    };
}
exports.companyFactory = companyFactory;
function addressFactory() {
    return {
        postalCode: '',
        prefecture: '',
        city: '',
        street: '',
        buildingName: '',
    };
}
exports.addressFactory = addressFactory;
function clientReviewsFactory() {
    return {
        sum: 0,
        count: 0,
        aggregation: {
            '1': 0,
            '3': 0,
            '5': 0,
        },
    };
}
exports.clientReviewsFactory = clientReviewsFactory;
const deletedClientFactory = () => {
    const baseClientFactory = clientFactory();
    baseClientFactory.name = 'ユーザが存在しません';
    baseClientFactory.thumbnail = '/img/no_image/client_thumb.png';
    return baseClientFactory;
};
exports.deletedClientFactory = deletedClientFactory;
const chkIsReqUpdateBillInfo = (client) => {
    if (client.paymentMethod == null ||
        !Object.values(constants_1.PaymentMethodDisp).includes(client.paymentMethod))
        return true;
    return false;
};
exports.chkIsReqUpdateBillInfo = chkIsReqUpdateBillInfo;
/**
 *
 * @param client
 * @returns true⇒要更新 false⇒OK
 */
const chkBillProfile = (client) => {
    const { required, max100, max60, postalCode, prefecture, cityStreetSize, phone, } = __1.ROBOT_PAYMENT_RULES;
    // client.company.name/請求先名/max100,required
    if (required(client.company.name) !== true)
        return true;
    if (max100(client.company.name) !== true)
        return true;
    // client.department/請求先部署名/max100,required
    if (required(client.department) !== true)
        return true;
    if (max100(client.department) !== true)
        return true;
    // client.name/宛名１/max60,required
    if (required(client.name) !== true)
        return true;
    if (max60(client.name) !== true)
        return true;
    // client.company.address.postalCode/郵便番号/postalCode,required
    if (required(client.company.address.postalCode) !== true)
        return true;
    if (postalCode(client.company.address.postalCode) !== true)
        return true;
    // client.company.address.prefecture/都道府県/prefecture,required
    if (required(client.company.address.prefecture) !== true)
        return true;
    if (prefecture(client.company.address.prefecture) !== true)
        return true;
    // client.company.address.city+street/市区町村番地/cityStreetSize,required
    if (required(client.company.address.city) !== true)
        return true;
    if (required(client.company.address.street) !== true)
        return true;
    if (cityStreetSize(client.company.address.city, client.company.address.street) !== true)
        return true;
    // client.company.address.buildingName/建物名/max60
    if (max60(client.company.address.buildingName) !== true)
        return true;
    // client.company.address.prefecture/都道府県/prefecture,required
    if (required(client.company.address.prefecture) !== true)
        return true;
    if (max60(client.company.address.prefecture) !== true)
        return true;
    // client.company.phone/電話番号/phone,(required)
    if (required(client.company.phone) !== true)
        return true;
    if (phone(client.company.phone) !== true)
        return true;
    return false;
};
exports.chkBillProfile = chkBillProfile;
