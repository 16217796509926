import Vue from 'vue'

export default () => {
  Vue.directive('video', {
    async update(video, binding) {
      console.log(video)
      video as HTMLVideoElement
      // console.log({el}, {binding}, {vnode});
      if (binding.value) {
        await (video as HTMLVideoElement).play()
      } else {
        ;(video as HTMLVideoElement).pause()
      }
    },
  })
}
