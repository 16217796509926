"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assertIsString = exports.assertIsNumber = exports.assertIsDefined = exports.assert = void 0;
function assert(condition, msg) {
    if (!condition) {
        throw new Error(msg);
    }
}
exports.assert = assert;
function assertIsDefined(value) {
    if (value === undefined || value === null) {
        throw new Error(`Expected 'value' to be defined, but received ${value}`);
    }
}
exports.assertIsDefined = assertIsDefined;
function assertIsNumber(value) {
    if (typeof value !== 'number') {
        throw new TypeError(`Expected value to be Number type, but received ${typeof value} type`);
    }
}
exports.assertIsNumber = assertIsNumber;
function assertIsString(value) {
    if (typeof value !== 'string') {
        throw new TypeError(`Expected value to be String type, but received ${typeof value} type`);
    }
}
exports.assertIsString = assertIsString;
