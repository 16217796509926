
import { defineComponent } from 'vue'
import type { Ages } from '@batteki/base/src/domain/search/talents'
import type { JobsCondition } from '@batteki/base/src/domain/search/jobs'
import type { SelectItem } from '@batteki/base/src/domain/search'
import SearchFormItemList from '~/components/molecules/SearchFormItemList.vue'
interface Data {
  ageFromRule: string | boolean
  ageToRule: string | boolean
  selected: any
}
export default defineComponent({
  components: {
    SearchFormItemList,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'close'],
  data: (): Data => ({
    ageFromRule: true,
    ageToRule: true,
    selected: '',
  }),
  computed: {
    dialog: {
      get(): boolean {
        return this.value
      },
      set(value: boolean) {
        this.$emit('input', value)
      },
    },
    media(): SelectItem[] {
      return this.$accessor.search.jobs.media
    },
    categories(): SelectItem[] {
      return this.$accessor.search.jobs.jobs.categories
    },
    selectedCat: {
      get(): SelectItem | undefined {
        return this.$accessor.search.jobs.jobs.categories.find(
          (cat) => cat.value === true
        )
      },
      set(item: SelectItem | undefined) {
        if (item) {
          this.onUpdateCategories(item)
        } else {
          this.$accessor.search.jobs.clearCategory()
        }
      },
    },
    gender(): SelectItem[] {
      return this.$accessor.search.jobs.jobs.gender
    },
    yearSelect(): number[] {
      return this.$accessor.search.jobs.yearSelection
    },
    age(): Ages {
      return this.$accessor.search.jobs.jobs.ages
    },
    jobs(): JobsCondition {
      return this.$accessor.search.jobs.jobs
    },
    keywords(): string {
      return this.$accessor.search.jobs.jobs.keywords
    },
  },
  async mounted() {
    await Promise.all([
      this.$accessor.search.jobs.getMediaFacets(),
      this.$accessor.search.jobs.getCategoryFacets(),
    ])
  },
  methods: {
    async search() {
      try {
        this.$loadingState.isActive = true
        await this.$accessor.search.jobs.search(0)
        if (this.$route.name === 'client-jobs') {
          this.$router.push('/client/jobs')
        } else {
          this.$router.push('/')
        }
        this.$emit('close')
      } finally {
        this.$loadingState.isActive = false
      }
    },
    clear() {
      this.$accessor.search.jobs.clear()
    },
    onUpdateMedia(key: string, $event: boolean): void {
      const item: SelectItem = { text: key, value: !!$event }
      this.$accessor.search.jobs.updateMedia(item)
    },
    onUpdateCategories($event: SelectItem): void {
      console.log($event)
      this.$accessor.search.jobs.updateCategories($event)
    },
    onUpdateGender(key: string, $event: boolean) {
      const item: SelectItem = { text: key, value: !!$event }
      console.debug('onUpdateGender', item)
      this.$accessor.search.jobs.updateGender(item)
    },
    onUpdateKeywords($event: string): void {
      this.$accessor.search.jobs.updateKeywords($event)
    },
    onUpdateAge(key: keyof Ages, val: number): void {
      this.$accessor.search.jobs.updateAge({ key, val })
      this.validAges(key)
    },
    validAges(key: keyof Ages) {
      if (this.age.from && this.age.to) {
        const valid = this.age.from - this.age.to < 0
        if (valid) {
          this.ageFromRule = true
          this.ageToRule = true
          return
        }
        if (key === 'from') {
          this.ageFromRule = '右値より小さい値を入力ください'
        } else {
          this.ageToRule = '左値より大きい値を入力ください。'
        }
      }
    },
  },
})
