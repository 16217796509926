"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.offerFactory = exports.InquiryTypeList = exports.getInquiryTypeDisp = exports.INQUIRY_TYPE = exports.InquiryTypes = exports.OfferTalentMessages = exports.OfferClientMessages = exports.OfferJobLabels = exports.OfferListLabels = exports.ClientOfferListLabels = exports.ForceEndableOffers = exports.DeactivatedOffers = exports.ActiveInquiry = exports.ActiveOffers = exports.WholeOffers = exports.OFFER_STATUS = exports.OfferStatuses = void 0;
const billStatus_1 = require("../constants/billStatus");
exports.OfferStatuses = {
    IN_INQUIRY: '問い合わせ中',
    IS_INQUIRY_REFUSED: '問い合わせ拒否',
    IN_PROGRESS: 'オファー中',
    IS_ACCEPTED: 'リクエスト承認待ち',
    IS_REFUSED: 'オファー回答済み（不成立）',
    IS_ADMIN_APPROVED: 'オファー回答済み',
    IS_ADMIN_DISAPPROVED: '運営事務局否認',
    IS_APPROVED: '案件成立中',
    IS_CANCELED: '不成立',
    IS_EXPIRED: '期限切れ（不成立）',
    IS_CLOSED: '案件終了',
    IS_ADMIN_CLOSED: '案件終了（強制）', // 管理側による案件終了
};
exports.OFFER_STATUS = Object.keys(exports.OfferStatuses).reduce((result, current) => {
    result[current] = current;
    return result;
}, {});
exports.WholeOffers = [
    exports.OFFER_STATUS.IN_INQUIRY,
    exports.OFFER_STATUS.IS_INQUIRY_REFUSED,
    exports.OFFER_STATUS.IN_PROGRESS,
    exports.OFFER_STATUS.IS_REFUSED,
    exports.OFFER_STATUS.IS_ACCEPTED,
    exports.OFFER_STATUS.IS_ADMIN_APPROVED,
    exports.OFFER_STATUS.IS_ADMIN_DISAPPROVED,
    exports.OFFER_STATUS.IS_EXPIRED,
    exports.OFFER_STATUS.IS_APPROVED,
    exports.OFFER_STATUS.IS_CANCELED,
    exports.OFFER_STATUS.IS_CLOSED,
    exports.OFFER_STATUS.IS_ADMIN_CLOSED,
];
exports.ActiveOffers = [
    exports.OFFER_STATUS.IN_INQUIRY,
    exports.OFFER_STATUS.IN_PROGRESS,
    exports.OFFER_STATUS.IS_ACCEPTED,
    exports.OFFER_STATUS.IS_ADMIN_APPROVED,
    exports.OFFER_STATUS.IS_APPROVED,
];
exports.ActiveInquiry = [
    exports.OFFER_STATUS.IN_INQUIRY,
    exports.OFFER_STATUS.IS_INQUIRY_REFUSED,
];
exports.DeactivatedOffers = exports.WholeOffers.filter((el) => !exports.ActiveOffers.includes(el));
exports.ForceEndableOffers = exports.ActiveOffers.filter((el) => ![exports.OFFER_STATUS.IS_APPROVED].includes(el));
/**
 * ステータス遷移
 * IN_INQUIRY → IN_PROGRESS       → IS_ACCEPTED → IS_ADMIN_APPROVED  → IS_APPROVED → IS_CLOSED
 *           ↑↓                  ↑↓            ↑↓                   ↑↓             ↓
 *            IS_INQUIRY_REFUSED  IS_REFUSED    IS_ADMIN_DISAPPROVED IS_CANCELED    IS_ADMIN_CLOSED
 */
// リスト表示ラベル
exports.ClientOfferListLabels = {
    IN_INQUIRY: '問い合わせ中',
    IS_INQUIRY_REFUSED: '不成立',
    IN_PROGRESS: '回答待ち',
    IS_ACCEPTED: '運営承認中',
    IS_ADMIN_APPROVED: '未受諾',
    IS_ADMIN_DISAPPROVED: '不成立',
    IS_REFUSED: '不成立',
    IS_EXPIRED: '不成立',
    IS_CANCELED: '不成立',
    IS_APPROVED: '成立',
    IS_CLOSED: '終了',
    IS_ADMIN_CLOSED: '終了',
};
// 一覧表示時ラベル
exports.OfferListLabels = {
    IN_INQUIRY: '',
    IS_INQUIRY_REFUSED: '不成立',
    IN_PROGRESS: '',
    IS_ACCEPTED: '',
    IS_ADMIN_APPROVED: '',
    IS_ADMIN_DISAPPROVED: '不成立',
    IS_REFUSED: '不成立',
    IS_EXPIRED: '不成立',
    IS_CANCELED: '不成立',
    IS_APPROVED: '確定済み',
    IS_CLOSED: '成立済み',
    IS_ADMIN_CLOSED: '成立済み',
};
// キャスト向け案件表示時ラベル
exports.OfferJobLabels = {
    IN_INQUIRY: '問い合わせを確認する',
    IS_INQUIRY_REFUSED: '不成立',
    IN_PROGRESS: '回答する',
    IS_ACCEPTED: 'オファー回答済み',
    IS_ADMIN_APPROVED: 'オファー回答済み',
    IS_ADMIN_DISAPPROVED: '不成立',
    IS_REFUSED: '不成立',
    IS_EXPIRED: '不成立',
    IS_CANCELED: '不成立',
    IS_APPROVED: '確定済み',
    IS_CLOSED: '成立済み',
    IS_ADMIN_CLOSED: '成立済み',
};
// クライアント向け詳細メッセージ
exports.OfferClientMessages = {
    IN_INQUIRY: 'このキャストへ問い合わせ中です。',
    IS_INQUIRY_REFUSED: 'このキャストは問い合わせを辞退しました。',
    IN_PROGRESS: 'このキャストへのオファーは回答まちです。',
    IS_ACCEPTED: 'このキャストへのオファーを運営事務局で承認中です。',
    IS_REFUSED: 'このキャストへのオファーは受諾されませんでした。',
    IS_ADMIN_APPROVED: 'このキャストへのオファーが受諾されました。',
    IS_ADMIN_DISAPPROVED: 'このキャストへオファーは事務所都合によりお受けできませんでした。',
    IS_CANCELED: 'このキャストへのオファーはキャンセルされました。',
    IS_EXPIRED: '回答期限が過ぎたためキャンセルされました。',
    IS_APPROVED: 'このキャストへのオファーが成立しました。',
    IS_CLOSED: 'この取引は終了しました。',
    IS_ADMIN_CLOSED: 'この取引は終了しました。',
};
// キャスト向け詳細メッセージ
exports.OfferTalentMessages = {
    IN_INQUIRY: 'この案件に関する問い合わせがありました。',
    IS_INQUIRY_REFUSED: 'この案件は見送りました。',
    IN_PROGRESS: 'この案件への回答をしてください',
    IS_ACCEPTED: 'オファーの回答が完了しました。運営事務局の承認待ちです',
    IS_REFUSED: 'この案件は見送りました。',
    IS_ADMIN_APPROVED: 'オファーの回答が完了しました。クライアントからの承諾待ちです',
    IS_ADMIN_DISAPPROVED: 'このオファーは事務所判断により許可されませんでした。',
    IS_CANCELED: 'この案件はクライアントにより、キャンセルされました。',
    IS_EXPIRED: '回答期限が過ぎたためキャンセルされました。',
    IS_APPROVED: 'オファーが成立しました。',
    IS_CLOSED: 'この取引は終了しました。',
    IS_ADMIN_CLOSED: 'この取引は終了しました。',
};
// お問い合わせ種別
exports.InquiryTypes = {
    APPEARANCE: '出演依頼',
    OTHERS: 'その他',
};
exports.INQUIRY_TYPE = Object.keys(exports.InquiryTypes).reduce((result, current) => {
    result[current] = current;
    return result;
}, {});
function getInquiryTypeDisp(val) {
    if (val) {
        return exports.InquiryTypes[val];
    }
    return '';
}
exports.getInquiryTypeDisp = getInquiryTypeDisp;
exports.InquiryTypeList = Object.keys(exports.InquiryTypes).reduce((result, current) => {
    result.push({
        text: getInquiryTypeDisp(current),
        value: current,
    });
    return result;
}, []);
function offerFactory() {
    return {
        job: {
            ref: null,
            client: {
                ref: null,
            },
        },
        talent: {
            ref: null,
        },
        chatRoom: {
            ref: null,
        },
        status: exports.OFFER_STATUS.IN_INQUIRY,
        canceledAt: null,
        refusedAt: null,
        expiredAt: null,
        acceptedAt: null,
        approvedAt: null,
        createdAt: null,
        updatedAt: null,
        remindedAcceptAt: null,
        remindedApproveAt: null,
        fee: 0,
        feeChengeLog: { ref: null },
        scheduleToClose: null,
        closedAt: null,
        billStatus: billStatus_1.BILL_STATUS.UNCLAIMED,
        billApplyedAt: null,
        billIgnoredAt: null,
        isBill: false,
        billId: '',
        adminApprovedAt: null,
        adminDisapprovedAt: null,
        inquiryRefusedAt: null,
        offeredAt: null,
        inquiryType: exports.INQUIRY_TYPE.APPEARANCE,
        adminCancelAt: null,
        adminCancelReason: '',
        adminCancelBy: null,
    };
}
exports.offerFactory = offerFactory;
