import type { ITalent } from '@batteki/common'
import { CommonTalent, talentFactory } from '@batteki/common'
import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
export class Talent extends CommonTalent {
  static initDefault() {
    return new Talent(talentFactory(), '')
  }
}

export const talentConverter: FirestoreDataConverter<Talent> = {
  toFirestore(data: Talent): ITalent {
    return Talent.toFirestore(data)
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Talent {
    const data = snapshot.data() as ITalent
    return new Talent(
      {
        ...talentFactory(),
        ...data,
        sns: {
          ...talentFactory().sns,
          ...data.sns,
        },
      },
      snapshot.id
    )
  },
}
