import ja from 'vuetify/src/locale/ja'
import colors from 'vuetify/es5/util/colors'
export default {
  breakpoint: {},
  icons: {},
  lang: {
    locales: { ja },
    current: 'ja',
  },
  // rtl: true,
  theme: {
    themes: {
      light: {
        primary: {
          base: '#5EB9E6',
          lighten3: '#ADDCF2',
          lighten1: '#8CCFED',
        },
        secondary: '#0077CB',
        black: {
          base: colors.shades.black,
        },
        white: {
          base: colors.shades.white,
        },
        info: '#7F7F7F',
        cyan: '#0aa9b7',
        grey: {
          base: '#666666',
          lighten4: '#f0f0f0',
          lighten3: '#e6e6e6',
          lighten2: 'B7B7B7', // 20%
          lighten1: '#b7b7b7',
          darken1: colors.grey.darken1,
          darken2: colors.grey.darken2,
          darkne3: colors.grey.darken3,
          darken4: colors.grey.darken4,
        },
        accent: {
          base: colors.lightBlue.darken1,
        },
        red: colors.red.darken1,
      },
    },
    options: {
      customProperties: true,
    },
  },
}
