import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import AsyncLoading from '@batteki/base/src/components/organisms/layout/AsyncLoading.vue';
import BaseMessage from '@batteki/base/src/components/molecules/BaseMessage.vue';
import ErrorMessage from '@batteki/base/src/components/molecules/BaseErrorMessage.vue';
import TheHeaderDone from '~/components/organisms/layout/TheHeaderDone.vue';
export default defineComponent({
  components: {
    TheHeaderDone: TheHeaderDone,
    ErrorMessage: ErrorMessage,
    BaseMessage: BaseMessage,
    AsyncLoading: AsyncLoading,
    PCLinkBanner: function PCLinkBanner() {
      return import('~/components/organisms/layout/PCLinkBanner.vue');
    }
  },
  setup: function setup() {
    var _useNuxtApp = useNuxtApp(),
      nuxt2Context = _useNuxtApp.nuxt2Context;
    var title = computed(function () {
      return nuxt2Context.$headerTitle.title;
    });
    watch(function () {
      return title.value;
    }, function (title) {
      useHead({
        title: title
      });
    }, {
      immediate: true
    });
    return {
      title: title
    };
  }
});