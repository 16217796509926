import type { CustomClaims } from '@batteki/common'
import { COLLECTION_NAME } from '@batteki/common'
import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import type { User as AuthUser, UserCredential } from 'firebase/auth'
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  TwitterAuthProvider,
} from 'firebase/auth'
import { logEvent } from 'firebase/analytics'
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { ClientRepo } from '@batteki/base/src/domain/clients/repository'
import type { User } from '@batteki/base/src/domain/users/user.class'
import { userConverter } from '@batteki/base/src/domain/users/user.class'
import type { Client } from '@batteki/base/src/domain/clients/client.class'
import {
  talentConverter,
  type Talent,
} from '@batteki/base/src/domain/talents/talent.class'
import type { News } from '@batteki/base/src/domain/news/news.class'
import { analytics, auth, db } from '@batteki/base/src/plugins/firebase'

type Auth = AuthUser | null
export interface Claim extends CustomClaims {
  [key: string]: any
}
export interface AuthState {
  user: User | null
  auth: Auth
  claim: Claim | null
  clientProfile: Client | null
  talentProfile: Talent | null
  latestNews: News | null
  hasUnreadNews: boolean
  userSubscribe: (() => void) | null
  newsSubscribe: (() => void) | null
  talentSubscribe: (() => void) | null
}

export const state = (): AuthState => ({
  user: null,
  auth: null,
  claim: null,
  clientProfile: null,
  talentProfile: null,
  latestNews: null,
  hasUnreadNews: false,
  userSubscribe: null,
  newsSubscribe: null,
  talentSubscribe: null,
})

export const getters = getterTree(state, {
  userInfo: (state) => state.user,
  isLogin: (state) => !!state.auth,
  isTalent: (state) => {
    return state.claim ? state.claim.role === 'talent' : false
  },
  isClient: (state) => {
    return state.claim ? state.claim.role === 'client' : false
  },
  isClientCertificated(state) {
    return state.claim ? state.claim.isClientCertificated : false
  },
})

export const mutations = mutationTree(state, {
  setAuthMutation(state, auth: Auth) {
    state.auth = auth
  },
  setUserMutation(state, newUser: User | null) {
    state.user = newUser
  },
  setUserSubscribeMutation(state, subscribe: (() => void) | null) {
    state.userSubscribe = subscribe
  },
  setClaimMutation(state, claim: Claim | null) {
    state.claim = claim
  },

  setClientProfileMutation(state, client: Client | null) {
    state.clientProfile = client
  },

  setTalentProfileMutation(state, talent: Talent | null) {
    state.talentProfile = talent
  },
  setTalentSubscribeMutation(state, subscribe: (() => void) | null) {
    state.talentSubscribe = subscribe
  },
  setLatestNewsMutation(state, news: News | null) {
    state.latestNews = news
  },
  setHasUnreadNewsMutation(state) {
    // onSnapshotで読み込むとtimestampが初回nullで取得されるので、createdAt, updatedAtにnullが設定されていないことも確認
    if (!state.user || !state.user.createdAt || !state.user.updatedAt) return

    if (!state.latestNews || !state.latestNews.publishedAt) return

    // News既読日時が存在しない場合、未読有りとする
    if (!state.user.lastNewsOpenedAt) {
      state.hasUnreadNews = true
      return
    }

    // lastNewsOpenedAtが news.publishedAtより古い場合未読有り
    state.hasUnreadNews =
      state.user.lastNewsOpenedAt.toMillis() <
      state.latestNews.publishedAt.toMillis()
  },
  setNewsSubscribeMutation(state, subscribe: (() => void) | null) {
    state.newsSubscribe = subscribe
  },

  clearAllMutation(state) {
    state.auth = null
    state.claim = null
    state.clientProfile = null
    state.talentProfile = null
    state.user = null
    state.latestNews = null
    state.hasUnreadNews = false
    state.userSubscribe = null
    state.newsSubscribe = null
  },
})

function loginCommon(user: UserCredential) {
  if (user && user.providerId) {
    const method = user.providerId
    const operationType = user.operationType
    logEvent(analytics, 'sign_up', { method, operationType })
  }
}

export const actions = actionTree(
  { state, getters, mutations },
  {
    // Google ログイン
    async signInGoogle() {
      const provider = new GoogleAuthProvider()
      const user = await signInWithPopup(auth, provider)
      loginCommon(user)
    },
    // Twitter ログイン
    async twitterSignIn(): Promise<void> {
      const provider = new TwitterAuthProvider()
      const user = await signInWithPopup(auth, provider)
      loginCommon(user)
    },
    // Facebook ログイン
    async facebookSignIn(): Promise<void> {
      const provider = new FacebookAuthProvider()
      const user = await signInWithPopup(auth, provider)
      loginCommon(user)
    },
    // メールパスワードログイン
    async signIn(_, payload: { email: string; password: string }) {
      const user = await signInWithEmailAndPassword(
        auth,
        payload.email,
        payload.password
      )

      loginCommon(user)
    },
    // メールアドレスサインアップ
    async signup(_, payload: { email: string; password: string }) {
      const user = await createUserWithEmailAndPassword(
        auth,
        payload.email,
        payload.password
      )
      // Analyticsにイベント登録
      if (user && user.providerId) {
        const method = user.providerId
        const operationType = user.operationType
        logEvent(analytics, 'sign_up', { method, operationType })
      }
      const actionCodeSettings = { url: `${process.env.BASE_URL}` }
      await sendEmailVerification(auth.currentUser!, actionCodeSettings)
      return { success: true }
    },
    // ログアウト
    async signOut({ commit, state }) {
      try {
        if (state.userSubscribe) {
          state.userSubscribe()
        }
        await auth.signOut()
        commit('clearAllMutation')
      } catch (err) {
        console.error(err)
      }
    },
    // User ドキュメント取得
    fetchUser({ state, commit }, uid: string) {
      // 多重呼び出し防止
      if (state.userSubscribe) return

      const unsubscribe = onSnapshot(
        doc(collection(db, COLLECTION_NAME.users), uid).withConverter(
          userConverter
        ),
        (snapshot) => {
          // const user = snapshot.data()
          commit('setUserMutation', snapshot.exists() ? snapshot.data() : null)
          commit('setHasUnreadNewsMutation')
        }
      )
      commit('setUserSubscribeMutation', unsubscribe)
    },
    // Clientドキュメント取得
    async fetchClientProfile({ commit }, uid: string) {
      const repo = new ClientRepo()
      const client = (await repo.get(uid)) || null
      commit('setClientProfileMutation', client)
    },
    // Talentドキュメント取得

    fetchTalentProfile({ state, commit }, uid: string) {
      // 多重呼び出し防止
      if (state.talentSubscribe) return

      return new Promise<void>((resolve) => {
        const unsubscribe = onSnapshot(
          doc(collection(db, COLLECTION_NAME.talents), uid).withConverter(
            talentConverter
          ),
          (snapshot) => {
            // const user = snapshot.data()
            commit(
              'setTalentProfileMutation',
              snapshot.exists() ? snapshot.data() : null
            )
            resolve()
          }
        )
        commit('setTalentSubscribeMutation', unsubscribe)
      })
    },
    fetchLatestNews({ state, commit }) {
      // 多重呼び出し防止
      if (state.newsSubscribe) return

      const unsubscribe = onSnapshot(
        query(
          collection(db, COLLECTION_NAME.news),
          where('deletedAt', '==', null),
          where('isPublished', '==', true),
          limit(1),
          orderBy('createdAt', 'desc')
        ),
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const news = change.doc.data() as News
            if (change.doc.exists()) news.id = change.doc.id
            if (change.type === 'added') {
              commit('setLatestNewsMutation', news || null)
              commit('setHasUnreadNewsMutation')
            }
            if (change.type === 'modified') {
              commit('setLatestNewsMutation', news || null)
              commit('setHasUnreadNewsMutation')
            }
            if (change.type === 'removed') {
              commit('setLatestNewsMutation', null)
              commit('setHasUnreadNewsMutation')
            }
          })
        }
      )

      commit('setNewsSubscribeMutation', unsubscribe)
    },

    setClaim({ commit }, customClaims: CustomClaims | null) {
      commit('setClaimMutation', customClaims)
    },

    setUser({ commit }, newUser: User | null) {
      commit('setUserMutation', newUser)
    },

    setAuth({ commit }, auth: Auth) {
      commit('setAuthMutation', auth)
    },
  }
)
