import ErrorMessage from '@batteki/base/src/components/molecules/BaseErrorMessage.vue';
import AsyncLoading from '@batteki/base/src/components/organisms/layout/AsyncLoading.vue';
import BaseMessage from '@batteki/base/src/components/molecules/BaseMessage.vue';
export default defineComponent({
  components: {
    ErrorMessage: ErrorMessage,
    BaseMessage: BaseMessage,
    AsyncLoading: AsyncLoading
  },
  setup: function setup() {
    var _useNuxtApp = useNuxtApp(),
      nuxt2Context = _useNuxtApp.nuxt2Context;
    var title = computed(function () {
      return nuxt2Context.$headerTitle.title;
    });
    watch(function () {
      return title.value;
    }, function (title) {
      useHead({
        title: title
      });
    }, {
      immediate: true
    });
    return {
      title: title
    };
  },
  head: {
    bodyAttrs: {
      class: 'body-print-background'
    },
    meta: [{
      name: 'viewport',
      content: 'width=device-width'
    }]
  }
});