"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonChatRoom = void 0;
class CommonChatRoom {
    constructor(init, id) {
        Object.assign(this, { ...init, id });
    }
    static toFirestore(data) {
        return {
            members: data.members,
            closedAt: data.closedAt,
            createdAt: data.createdAt,
            readStatus: data.readStatus,
            updatedAt: data.updatedAt,
            job: data.job,
            processType: data.processType,
            process: data.process,
            lastMessage: data.lastMessage,
        };
    }
}
exports.CommonChatRoom = CommonChatRoom;
