import { COLLECTION_NAME } from '@batteki/common'
import type { CollectionReference } from 'firebase/firestore'
import { collection, query, where } from 'firebase/firestore'
import { apiAdapter } from '../adapter'
import type { Notification } from './notification.class'
import { notificationConverter } from './notification.class'
import { db } from '@batteki/base/src/plugins/firebase'

export class NotificationRepo {
  private userRef: CollectionReference
  constructor() {
    this.userRef = collection(db, COLLECTION_NAME.users)
  }

  /**
   * 未読のNotificationを１つ取得
   * @param {string} uid
   */
  findOneUnreadNotification(uid: string): Promise<Notification | undefined> {
    const ref = apiAdapter.subCollection(
      this.userRef,
      uid,
      COLLECTION_NAME.notifications
    )
    return apiAdapter.getByQueryOne(
      query(ref, where('isRead', '==', false)),
      notificationConverter
    )
  }
}
