import type { SearchIndex } from 'algoliasearch'
import algoliasearch from 'algoliasearch'

export type TalentSort = 'DESC_LIKED' | 'DESC_REVIEW' | 'DESC_CREATE'

export const TalentSortList: { text: string; value: TalentSort }[] = [
  { text: 'レビューの評価が高い順', value: 'DESC_REVIEW' },
  { text: 'お気に入りの多い順', value: 'DESC_LIKED' },
  { text: '新着順', value: 'DESC_CREATE' },
]

export const TalentSortListWithDefault: {
  text: string
  value: TalentSort | null
}[] = [{ text: 'おすすめ順', value: null }, ...TalentSortList]

export type JobSort = 'DESC_ENTRIES' | 'DESC_FEE' | 'DESC_PUBLISH'

export const JobSortList: { text: string; value: JobSort }[] = [
  { text: 'エントリー数の多い順', value: 'DESC_ENTRIES' },
  { text: '出演料金額の高い順', value: 'DESC_FEE' },
  { text: '新着順', value: 'DESC_PUBLISH' },
]

const algoliaId = process.env.ALGOLIA_ID || ''
const algoliaKey = process.env.ALGOLIA_KEY || ''
const indexNameTalents = process.env.ALGOLIA_INDEX_TALENTS || ''
const indexNameJobs = process.env.ALGOLIA_INDEX_JOBS || ''

const client = algoliasearch(algoliaId, algoliaKey)
const indexTalents = client.initIndex(indexNameTalents)
const indexTalentsDescLiked = client.initIndex(`${indexNameTalents}_desc_liked`)
const indexTalentsDescReview = client.initIndex(
  `${indexNameTalents}_desc_reviewScore`
)
const indexTalentsDescCreate = client.initIndex(
  `${indexNameTalents}_desc_createdAt`
)

function algoliaTalentSearchIndex(sort: TalentSort | null): SearchIndex {
  switch (sort) {
    case 'DESC_LIKED':
      return indexTalentsDescLiked
    case 'DESC_REVIEW':
      return indexTalentsDescReview
    case 'DESC_CREATE':
      return indexTalentsDescCreate
    default:
      return indexTalents
  }
}

const indexJobs = client.initIndex(indexNameJobs)

const indexJobsDescEntries = client.initIndex(`${indexNameJobs}_desc_entries`)
const indexJobsDescFee = client.initIndex(`${indexNameJobs}_desc_fee`)
const indexJobsDescPublishedAt = client.initIndex(
  `${indexNameJobs}_desc_publishedAt`
)

function algoliaJobSearchIndex(sort: JobSort | null): SearchIndex {
  switch (sort) {
    case 'DESC_ENTRIES':
      return indexJobsDescEntries
    case 'DESC_FEE':
      return indexJobsDescFee
    case 'DESC_PUBLISH':
      return indexJobsDescPublishedAt
    default:
      return indexJobs
  }
}

const algoliasearchApiClient = client
const algoliasearchTalents = algoliaTalentSearchIndex
const algoliasearchJobs = algoliaJobSearchIndex

export { algoliasearchApiClient, algoliasearchTalents, algoliasearchJobs }
