import type { CommonTimestamp } from '@batteki/common'
import { COLLECTION_NAME } from '@batteki/common'
import type { CollectionReference, QueryConstraint } from 'firebase/firestore'
import {
  collection,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore'
import { apiAdapter } from '../adapter'
import type { News } from './news.class'
import { newsConverter } from './news.class'
import { db } from '@batteki/base/src/plugins/firebase'
export class NewsRepo {
  private ref: CollectionReference
  constructor() {
    this.ref = collection(db, COLLECTION_NAME.news)
  }

  async getTalentNews(
    lastVisible: CommonTimestamp | undefined | null = undefined,
    directionStr: 'desc' | 'asc' = 'desc',
    limits = 12
  ): Promise<{ news: News[]; hasMore: boolean }> {
    let hasMore = false

    const queryConstraints: QueryConstraint[] = [
      limit(limits + 1),
      where('deletedAt', '==', null),
      where('isPublished', '==', true),
      where('target', 'in', ['TALENT', 'ALL']),
      orderBy('publishedAt', directionStr),
    ]
    if (lastVisible) {
      queryConstraints.push(startAfter(lastVisible))
    }
    const news = await apiAdapter.getByQuery<News>(
      query(this.ref, ...queryConstraints),
      newsConverter
    )
    if (news.length === limits + 1) {
      hasMore = true
      news.pop()
    }
    return { news, hasMore }
  }

  async getClientNews(
    lastVisible: CommonTimestamp | undefined | null = undefined,
    directionStr: 'desc' | 'asc' = 'desc',
    limits = 12
  ): Promise<{ news: News[]; hasMore: boolean }> {
    let hasMore = false

    const queryConstraints: QueryConstraint[] = [
      limit(limits + 1),
      where('isPublished', '==', true),
      where('deletedAt', '==', null),
      where('target', 'in', ['CLIENT', 'ALL']),
      orderBy('publishedAt', directionStr),
    ]
    if (lastVisible) {
      queryConstraints.push(startAfter(lastVisible))
    }
    const news = await apiAdapter.getByQuery<News>(
      query(this.ref, ...queryConstraints),
      newsConverter
    )
    if (news.length === limits + 1) {
      hasMore = true
      news.pop()
    }
    return { news, hasMore }
  }

  get(id: string): Promise<News | undefined> {
    return apiAdapter.requestGetAPI<News>(this.ref, id, newsConverter)
  }
}
