"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.studentFactory = void 0;
const studentFactory = () => ({
    secureCode: '',
    name: '',
    kana: '',
    talent: {
        ref: null,
    },
    belongingTo: '',
    isSuspended: true,
    createdAt: null,
    updatedAt: null,
});
exports.studentFactory = studentFactory;
