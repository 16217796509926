import type { IFeeChangelog, CommonTimestamp } from '@batteki/common'

import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
export class FeeChangelog implements IFeeChangelog {
  newFee!: number
  confirmedAt!: CommonTimestamp | null
  createdAt!: CommonTimestamp | null
  updatedAt!: CommonTimestamp | null

  public id!: string

  constructor(init: IFeeChangelog, id: string) {
    Object.assign(this, { id, ...init })
  }

  setId(id: string): void {
    this.id = id
  }
}

export const feeChangelogConverter: FirestoreDataConverter<FeeChangelog> = {
  toFirestore(data: FeeChangelog): IFeeChangelog {
    return {
      newFee: data.newFee,
      confirmedAt: data.confirmedAt,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): FeeChangelog {
    const data = snapshot.data() as IFeeChangelog
    return new FeeChangelog(data, snapshot.id)
  },
}
