import jwtDecode from 'jwt-decode'
import type {
  AuthProvider,
  User as AuthUser,
  UserCredential,
} from 'firebase/auth'
import {
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  TwitterAuthProvider,
} from 'firebase/auth'
export type Provider = 'twitter' | 'facebook' | 'google' | 'mail' | null
/**
 * Authentication のProviderDataから Providerを取得
 * @param providerData
 */
export async function getProvider(user: AuthUser): Promise<Provider> {
  const jwt = await user.getIdToken()
  const decoded = jwtDecode<any>(jwt)
  const signInProvider = decoded.firebase.sign_in_provider
  switch (signInProvider) {
    case 'twitter.com':
      return 'twitter'
    case 'facebook.com':
      return 'facebook'
    case 'google.com':
      return 'google'
    case 'password':
      return 'mail'
    default:
      return 'mail'
  }
}

/**
 * メールアドレス再認証
 */
export function reauthenticateWithEmail(
  currentUser: AuthUser,
  password: string
): Promise<UserCredential> {
  console.log(`start to reauthenticate by email and password`)
  const credential = EmailAuthProvider.credential(currentUser.email!, password)
  return reauthenticateWithCredential(currentUser, credential)
}

/**
 * プロバイダの再認証ハンドラー
 */
export async function reAuthenticationHandler(
  currentUser: AuthUser,
  provider: Provider,
  password = ''
) {
  switch (provider) {
    case 'twitter': {
      const providerIns = new TwitterAuthProvider()
      const { user } = await reauthenticateWithProvider(
        providerIns,
        currentUser
      )
      return user
    }
    case 'facebook': {
      const providerIns = new FacebookAuthProvider()
      const { user } = await reauthenticateWithProvider(
        providerIns,
        currentUser
      )
      return user
    }
    case 'google': {
      const providerIns = new GoogleAuthProvider()
      const { user } = await reauthenticateWithProvider(
        providerIns,
        currentUser
      )
      return user
    }
    case 'mail': {
      const { user } = await reauthenticateWithEmail(currentUser, password)
      return user
    }
    default: {
      return null
    }
  }
}

/**
 * プロバイダー再認証
 */
function reauthenticateWithProvider(
  provider: AuthProvider,
  currentUser: AuthUser
) {
  console.log(`start to reauthenticate by  ${provider.providerId} provider`)
  return reauthenticateWithPopup(currentUser, provider)
}
