"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMediaMaster = exports.mediaMasterFactory = void 0;
function mediaMasterFactory() {
    return {
        name: '',
        orderIndex: 0,
        createdAt: null,
        updatedAt: null,
    };
}
exports.mediaMasterFactory = mediaMasterFactory;
function createMediaMaster(media, mediaMasterValues) {
    return {
        ...media,
        ...mediaMasterValues,
    };
}
exports.createMediaMaster = createMediaMaster;
