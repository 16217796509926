import type { CommonTimestamp, IJob, ILikedJob } from '@batteki/common'
import type {
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
export class LikedJob implements ILikedJob {
  public job!: {
    ref: DocumentReference<IJob> | null
  }

  public createdAt!: CommonTimestamp
  public updatedAt!: CommonTimestamp
  public deletedAt!: CommonTimestamp | null
  public id!: string

  constructor(init: ILikedJob, id?: string) {
    Object.assign(this, { id, ...init })
  }
}

export const likedJobConverter: FirestoreDataConverter<LikedJob> = {
  toFirestore(data: LikedJob): ILikedJob {
    return {
      job: data.job,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      deletedAt: data.deletedAt,
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): LikedJob {
    const data = snapshot.data() as ILikedJob
    return new LikedJob(data, snapshot.id)
  },
}
