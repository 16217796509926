"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonChatRoomWithJobAndPartner = exports.CommonChatRoomWithJob = exports.CommonChatRoomReference = void 0;
class CommonChatRoomReference {
    constructor(init, id) {
        Object.assign(this, { ...init, id });
    }
    static toFirestore(data) {
        return {
            job: data.job,
            members: data.members,
            unreadMessages: data.unreadMessages,
            lastMessage: data.lastMessage,
            sendUnreadMailAt: data.sendUnreadMailAt,
            createdAt: data.createdAt,
            process: data.process,
            updatedAt: data.updatedAt,
        };
    }
}
exports.CommonChatRoomReference = CommonChatRoomReference;
class CommonChatRoomWithJob extends CommonChatRoomReference {
    constructor(init, job) {
        super(init, init.id);
        this.jobClass = job;
    }
}
exports.CommonChatRoomWithJob = CommonChatRoomWithJob;
class CommonChatRoomWithJobAndPartner extends CommonChatRoomWithJob {
    constructor(init, job, partner) {
        super(init, job);
        this.partner = partner;
    }
}
exports.CommonChatRoomWithJobAndPartner = CommonChatRoomWithJobAndPartner;
