import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import { COLLECTION_NAME, OFFER_STATUS } from '@batteki/common'
import {
  collectionGroup,
  doc,
  limit,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'
import { offerConverter } from '@batteki/base/src/domain/offers/offer.class'
import { db } from '@batteki/base/src/plugins/firebase'

const LIMIT = 10

interface State {
  itemLength: number
  itemLimit: number
  unsubscribeItems: (() => void) | null
}

export const state = (): State => ({
  itemLength: 0,
  itemLimit: LIMIT,
  unsubscribeItems: null,
})

export const getters = getterTree(state, {
  itemLength: (state: State) => state.itemLength,
  itemLimit: (state: State) => state.itemLimit,
})

export const mutations = mutationTree(state, {
  setSubscribeItemsMutation(state: State, { unsubscribeItems }) {
    state.unsubscribeItems = unsubscribeItems
  },

  setItemLengthMutation(state: State, { itemLength }) {
    state.itemLength = itemLength
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    bindItems({ commit, state }, { clientId }: { clientId: string }) {
      return new Promise<void>((resolve): void => {
        // 呼び出し済みチェック
        if (state.unsubscribeItems) return resolve()

        console.log('bindItems@Offers')

        const unsubscribeItems = onSnapshot(
          query(
            collectionGroup(db, COLLECTION_NAME.offers).withConverter(
              offerConverter
            ),
            where(
              'job.client.ref',
              '==',
              doc(db, `${COLLECTION_NAME.clients}/${clientId}`)
            ),
            where('status', 'in', [OFFER_STATUS.IS_ADMIN_APPROVED]),
            limit(LIMIT + 1)
          ),
          (querySnapshot) => {
            commit('setItemLengthMutation', {
              itemLength: querySnapshot.size,
            })

            return resolve()
          }
        )
        commit('setSubscribeItemsMutation', { unsubscribeItems })
      })
    },
  }
)
