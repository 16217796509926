"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.newsFactory = void 0;
function newsFactory() {
    return {
        target: 'ALL',
        title: '',
        content: '',
        deletedAt: null,
        isPublished: false,
        publishedAt: null,
        createdAt: null,
        updatedAt: null,
    };
}
exports.newsFactory = newsFactory;
