import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { COLLECTION_NAME } from '@batteki/common';
import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { auth, db } from '@batteki/base/src/plugins/firebase';
export default defineComponent({
  components: {},
  props: {
    role: {
      type: String,
      required: true
    },
    isPC: {
      type: Boolean,
      required: false
    }
  },
  data: function data() {
    return {
      unsubscribe: null,
      notifications: []
    };
  },
  computed: {
    alertNotificationNum: function alertNotificationNum() {
      if (this.notifications.length === 11) return '10+';
      return this.notifications.length.toString();
    },
    notificationLink: function notificationLink() {
      if (!this.role) return '';
      return "/".concat(this.role, "/notification");
    }
  },
  watch: {},
  mounted: function mounted() {
    var _this = this;
    var _ref = auth.currentUser,
      uid = _ref.uid;
    this.unsubscribe = onSnapshot(query(collection(db, "".concat(COLLECTION_NAME.users, "/").concat(uid, "/").concat(COLLECTION_NAME.notifications)), where('isRead', '==', false), limit(11), orderBy('createdAt', 'desc')), function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        var notification = change.doc.data();
        notification.id = change.doc.id;
        if (change.type === 'added') {
          _this.notifications.push(notification);
        }
        if (change.type === 'modified') {
          var idx = _this.notifications.findIndex(function (v) {
            return v.id === change.doc.id;
          });
          console.log(idx);
        }
        if (change.type === 'removed') {
          _this.notifications = _this.notifications.filter(function (n) {
            return n.id !== change.doc.id;
          });
        }
      });
    });
  },
  beforeUnmount: function beforeUnmount() {
    if (!this.unsubscribe) return;
    this.unsubscribe();
  },
  methods: {
    gotoNotificationList: function gotoNotificationList() {
      // お知らせタブを開く様設定
      this.$accessor.tabs.setNotificationTab(0);
      this.$nuxt.$router.push(this.notificationLink);
    }
  }
});