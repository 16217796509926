"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationFactory = void 0;
function notificationFactory() {
    return {
        title: '',
        thumbnail: '',
        isRead: false,
        url: '',
        createdAt: null,
        updatedAt: null,
    };
}
exports.notificationFactory = notificationFactory;
