import type {
  CommonJob,
  CommonTimestamp,
  IEntry,
  ProcessReplica,
} from '@batteki/common'
import {
  CommonEntryWithJob,
  CommonEntryWithJobAndTalent,
  ENTRY_STATUS,
  CommonEntry,
  entryFactory,
} from '@batteki/common'

import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
import { doc } from 'firebase/firestore'
import { Talent } from '../talents/talent.class'
import { bindEntryRef } from '../../plugins/firebase'

export class Entry extends CommonEntry {}
export const entryConverter: FirestoreDataConverter<Entry> = {
  toFirestore(data: Entry): IEntry {
    return Entry.toFirestore(data)
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Entry {
    const data = snapshot.data()
    return new Entry({ ...entryFactory(), ...data }, snapshot.id)
  },
}

export class EntryWithProcessType extends Entry {
  type: 'entry'
  constructor(init: Entry) {
    super(init, init.id)
    this.type = 'entry'
  }
}

export class EntryWithTalentAndProcessType extends EntryWithProcessType {
  talentData: Talent
  constructor(init: EntryWithProcessType, talent?: Talent | undefined) {
    super(init)
    this.talentData = talent ?? Talent.initDefault()
  }

  getProcessReplica(): ProcessReplica {
    return {
      type: this.type,
      ref: doc(bindEntryRef(this.job.ref.id), this.id),
      status: this.status,
    }
  }
}

export class EntryWithJobAndTalent extends CommonEntryWithJobAndTalent {}

export class EntryWithJobAndTalentWithExitAt extends EntryWithJobAndTalent {
  exitAt: CommonTimestamp | null

  static getExitAt(item: IEntry) {
    if (item.status === ENTRY_STATUS.IS_REFUSED) {
      return item.refusedAt
    }
    if (item.status === ENTRY_STATUS.IS_EXPIRED) {
      return item.expiredAt
    }
    if (item.status === ENTRY_STATUS.IS_ADMIN_CLOSED) {
      return item.closedAt
    }
    if (item.status === ENTRY_STATUS.IS_CLOSED) {
      return item.closedAt
    }
    return null
  }

  constructor(init: Entry, job: CommonJob, talent: Talent) {
    super(init, job, talent)
    this.exitAt = EntryWithJobAndTalentWithExitAt.getExitAt(init)
  }
}

export class EntryWithJob extends CommonEntryWithJob {}
