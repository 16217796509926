"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOfferTypeDisp = exports.OFFER_TYPE = exports.OfferTypes = void 0;
exports.OfferTypes = {
    INQUIRY: '問い合わせ',
    OFFER: 'オファー',
};
exports.OFFER_TYPE = Object.keys(exports.OfferTypes).reduce((result, current) => {
    result[current] = current;
    return result;
}, {});
function getOfferTypeDisp(val) {
    if (val) {
        return exports.OfferTypes[val];
    }
    return '';
}
exports.getOfferTypeDisp = getOfferTypeDisp;
