import type {
  IJobChangeHistory,
  JobChangeHistoryType,
  CommonTimestamp,
} from '@batteki/common'
import { jobChangeHistoryFactory } from '@batteki/common'
import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'

export class JobChangeHistory implements IJobChangeHistory {
  type!: JobChangeHistoryType
  isByAdmin!: boolean
  reason!: string
  createdAt!: CommonTimestamp | null
  updatedAt!: CommonTimestamp | null

  public id!: string

  constructor(init: IJobChangeHistory, id: string) {
    Object.assign(this, { id, ...init })
  }

  static initDefault(type: JobChangeHistoryType) {
    return new JobChangeHistory(
      {
        ...jobChangeHistoryFactory(),
        isByAdmin: false,
        type,
      },
      ''
    )
  }

  setId(id: string): void {
    this.id = id
  }
}

export const jobChangeHistoryConverter: FirestoreDataConverter<JobChangeHistory> =
  {
    toFirestore(data: JobChangeHistory): IJobChangeHistory {
      return {
        type: data.type,
        isByAdmin: data.isByAdmin,
        reason: data.reason,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
      }
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): JobChangeHistory {
      const data = snapshot.data() as IJobChangeHistory
      return new JobChangeHistory(
        { ...jobChangeHistoryFactory(), ...data },
        snapshot.id
      )
    },
  }
