import type { IClient } from '@batteki/common'
import {
  clientFactory,
  deletedClientFactory,
  CommonClient,
} from '@batteki/common'

import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
export class Client extends CommonClient {
  static initDeleted(id: string) {
    return new Client(deletedClientFactory(), id)
  }
}

export const clientConverter: FirestoreDataConverter<Client> = {
  toFirestore(data: Client): IClient {
    return Client.toFirestore(data)
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Client {
    const data = snapshot.data() as IClient
    return new Client(
      {
        ...clientFactory(),
        ...data,
        paymentMethod: 2, // ロボットペイメント解約に伴い銀行振込に固定する
      },
      snapshot.id
    )
  },
}
