"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonEntryWithJobWithClientAndTalent = exports.CommonEntryWithTalent = exports.CommonEntryWithJobAndTalent = exports.CommonEntryWithJob = exports.CommonEntry = void 0;
const models_1 = require("../../models");
class CommonEntry {
    setId(id) {
        this.id = id;
    }
    constructor(init, id) {
        Object.assign(this, { ...init, id });
    }
    static toFirestore(data) {
        return {
            status: data.status,
            reason: data.reason,
            pr: data.pr,
            job: data.job,
            talent: data.talent,
            chatRoom: data.chatRoom,
            refusedAt: data.refusedAt,
            expiredAt: data.expiredAt,
            approvedAt: data.approvedAt,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            remindedApproveAt: data.remindedApproveAt,
            fee: data.fee,
            feeChengeLog: data.feeChengeLog,
            scheduleToClose: data.scheduleToClose,
            closedAt: data.closedAt,
            billStatus: data.billStatus,
            billApplyedAt: data.billApplyedAt,
            billIgnoredAt: data.billIgnoredAt,
            isBill: data.isBill,
            billId: data.billId,
            adminApprovedAt: data.adminApprovedAt,
            adminDisapprovedAt: data.adminDisapprovedAt,
            startDecidingAt: data.startDecidingAt,
            schedule: data.schedule,
            adminCancelAt: data.adminCancelAt,
            adminCancelReason: data.adminCancelReason,
            adminCancelBy: data.adminCancelBy,
        };
    }
    static initDefault() {
        return new CommonEntry((0, models_1.entryFactory)(), '');
    }
    adminApprove(serverCommonTimestamp) {
        this.status = models_1.ENTRY_STATUS.IS_ADMIN_APPROVED;
        this.adminApprovedAt = serverCommonTimestamp;
        this.adminDisapprovedAt = null;
    }
    adminDisapprove(serverCommonTimestamp) {
        this.status = models_1.ENTRY_STATUS.IS_ADMIN_DISAPPROVED;
        this.adminDisapprovedAt = serverCommonTimestamp;
        this.adminApprovedAt = null;
    }
    startDeciding(serverCommonTimestamp) {
        this.status = models_1.ENTRY_STATUS.IS_DECIDING;
        this.startDecidingAt = serverCommonTimestamp;
    }
    approve(serverCommonTimestamp, chatRoomRef) {
        this.status = models_1.ENTRY_STATUS.IS_APPROVED;
        this.approvedAt = serverCommonTimestamp;
        if (chatRoomRef) {
            this.setChatRoom(chatRoomRef);
        }
    }
    refuse(serverCommonTimestamp) {
        this.status = models_1.ENTRY_STATUS.IS_REFUSED;
        this.refusedAt = serverCommonTimestamp;
    }
    expire() {
        this.status = models_1.ENTRY_STATUS.IS_EXPIRED;
    }
    remindForApprove(serverCommonTimestamp) {
        this.remindedApproveAt = serverCommonTimestamp;
    }
    setNewFee(newFee) {
        this.fee = newFee;
        this.feeChengeLog.ref = null;
    }
    setChatRoom(chatRoomRef) {
        this.chatRoom = {
            ref: chatRoomRef,
        };
    }
    // 正常に案件が終了した場合
    close(serverCommonTimestamp, isAdmin, author, reason) {
        // 案件成立中で費用が発生する場合に請求対象とする
        if (this.status === models_1.ENTRY_STATUS.IS_APPROVED && this.fee > 0)
            this.isBill = true;
        if (isAdmin) {
            this.status = models_1.ENTRY_STATUS.IS_ADMIN_CLOSED;
            this.adminCancelAt = serverCommonTimestamp;
            this.adminCancelBy = author;
            this.adminCancelReason = reason || '';
        }
        else {
            this.status = models_1.ENTRY_STATUS.IS_CLOSED;
        }
        this.closedAt = serverCommonTimestamp;
    }
    // 拒否（クライアント操作）の戻し
    reopenFromRefused() {
        if (this.status !== models_1.ENTRY_STATUS.IS_REFUSED)
            return;
        if (this.startDecidingAt) {
            this.status = models_1.ENTRY_STATUS.IS_DECIDING;
        }
        else {
            this.status = models_1.ENTRY_STATUS.IS_ADMIN_APPROVED;
        }
        this.refusedAt = null;
    }
    // 採用（クライアント操作）の戻し
    rollbackFromApproved() {
        if (this.status !== models_1.ENTRY_STATUS.IS_APPROVED)
            return;
        if (this.startDecidingAt) {
            this.status = models_1.ENTRY_STATUS.IS_DECIDING;
        }
        else {
            this.status = models_1.ENTRY_STATUS.IS_ADMIN_APPROVED;
        }
        this.approvedAt = null;
    }
}
exports.CommonEntry = CommonEntry;
class CommonEntryWithJob extends CommonEntry {
    constructor(init, job) {
        super(init, init.id);
        this.jobClass = job;
    }
}
exports.CommonEntryWithJob = CommonEntryWithJob;
class CommonEntryWithJobAndTalent extends CommonEntryWithJob {
    constructor(init, job, talent) {
        super(init, job);
        this.talentClass = talent;
    }
}
exports.CommonEntryWithJobAndTalent = CommonEntryWithJobAndTalent;
class CommonEntryWithTalent extends CommonEntry {
    constructor(init, talent) {
        super(init, init.id);
        this.talentClass = talent;
    }
}
exports.CommonEntryWithTalent = CommonEntryWithTalent;
class CommonEntryWithJobWithClientAndTalent extends CommonEntryWithTalent {
    constructor(init, job, talent) {
        super(init, talent);
        this.jobClass = job;
    }
}
exports.CommonEntryWithJobWithClientAndTalent = CommonEntryWithJobWithClientAndTalent;
